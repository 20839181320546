import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { forgotpassword } from "../../../store/actions/authActions";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { clearNotifications } from "../../../store/actions/notificationsActions";

function ForgotPassword() {
  const [email, setEmail] = useState("");

  const dispatch = useDispatch();
  const notification = useSelector((state) => state.notification);

  useEffect(() => {
    if (notification.success) {
      const { message } = notification.success;
      toast.success(message);
      dispatch(clearNotifications());
    }
    if (notification.errors) {
      const { message } = notification.errors;
      toast.error(message);
      dispatch(clearNotifications());
    }
  }, [notification, dispatch]);
  const handleSubmit = (evt) => {
    evt.preventDefault();
    dispatch(forgotpassword({ email: email }));
  };
  return (
    <div className="hold-transition  login-page">
      <div className="login-box">
        <div className="card">
          <div className="card-body login-card-body">
            <div className="callout callout-danger">
              <h5>
                Change your password in three easy steps. This will help you to
                secure your password!
              </h5>

              <ol>
                <li>Enter your email address below.</li>
                <li>Our system will send you a temporary link</li>
                <li>Use the link to reset your password</li>
              </ol>
            </div>

            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInpu34"
              >
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>
            </Form>
            <p>
              Enter the email address you used during the registration on
              lmcsnigltd.org.ng. Then we'll email a link to this address.
            </p>
          </div>

          <ToastContainer position="top-center" />
          <div className="row">
            <div className="col-12">
              <button
                type="submit"
                className="btn btn-primary btn-block"
                value={email}
                onClick={handleSubmit}
              >
                Submit
              </button>
              <p className="mt-3 mb-1">
                <Link to="/signin">Login</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
