import React from "react";
import ContentWrapper from "../utilities/ContentWrapper";

import GuarantorContract from "./GuarantorContract";

function GuarantorPreview(props) {
	const { guarantors, } = props.data;

	const [count, setCount] = React.useState(0);

	const handleNext = () => {
		setCount((prevCount) => prevCount + 1);
		if (count >= 1) setCount(0);
	};

	return (
		<ContentWrapper>
			<div className='col-12'>
				<div className='invoice p-3 mb-3 col-12'>
					<GuarantorContract {...props} guarantor={guarantors[count]} />
				</div>
				<br />

				{/* <!-- this row will not appear when printing --> */}
				<div className='row no-print' style={{ marginTop: "100px" }}>
					<div className='col-12'>
						{props.return && <button
							type='button'
							className='btn btn-danger '
							style={{ marginRight: "5px" }}
							onClick={() => props.showLoanPage()}>
							<i className='fas fa-eject'></i> {""}back
						</button>}

						<button
							type='button'
							className='btn btn-success float-right'
							style={{ marginRight: "5px" }}
							onClick={handleNext}>
							<i className='fas fa-download'></i> Next Guarantor
						</button>
					</div>
				</div>
			</div>
		</ContentWrapper>
	);
}

export default GuarantorPreview;
