import React from "react";

function Treasurer() {
  return (
    <>
      <div className="card card-widget widget-user-2">
        <div className="widget-user-header bg-warning">
          <div className="widget-user-image">
            <img
              className="img-circle elevation-2"
              src="img/team/treasurer.jpg"
              alt="User Avatar"
            />
          </div>

          <h3 className="widget-user-username">LUCKY A. UGBOKO</h3>
          <h5 className="widget-user-desc">M.Sc, B.Sc, FCA</h5>
          <h5 className="widget-user-desc">Treasurer</h5>
        </div>
        <div className="card-footer p-0">
          <div className="card-header">
            <h3 className="card-title">
              <i className="fas fa-bullhorn"></i>
              Professional/Academic Qualifications:
            </h3>
          </div>
          Ugboko Lucky Azuka is a Fellow of the prestigious Institute of
          Chartered Accountants of Nigeria (ICAN). He possesses B.Sc. and M.Sc.
          in Finance & Banking and Finance respectively from the University of
          Port Harcourt, Choba, Port Harcourt, Rivers State.
          <div className="card-header">
            <h3 className="card-title">
              <i className="fas fa-bullhorn"></i>
              Work Experience:
            </h3>
          </div>
          He has over twenty-four (24) years work experience both in the Private
          and Public Sectors of the economy. He started his working career in
          January 1997 with Akintola Williams Deloitte, Port Harcourt Office - a
          firm of Chartered Accountants with engagements in Auditing and
          Financial Advisory Services to various Clients in both private and
          public sectors of the economy. He left the firm in April, 2006, as an
          Audit Senior. He subsequently got another appointment with Sundry
          Foods Limited, Port Harcourt - a company engaged in Bakery and Food
          Processing & Services-as Financial Controller, a position he held
          until he left in July, 2009. Due to his desire for a fresh challenge,
          he joined Equitorial Trust Bank Ltd in late July, 2009, as Senior
          Assistant Manager/Port Harcourt Hub Team Lead - Internal Control Unit,
          a position he held till November, 2011, when the bank merged with
          Sterling Bank Plc. Subsequently, Sterling Bank Plc. engaged him as an
          Assistant Manager II/Regional Head, South-South 1 – Internal Control
          Unit. He left Sterling Bank Plc. in September, 2015, as Deputy
          Manager/Regional Coordinator, South-South & South-East – Internal
          Control Unit. In late 2015, he took up another challenge with Prince
          and Princewill, a firm of Chartered Accountants, as Clients’
          Engagement Partner till February, 2017, before joining the services of
          the University of Nigeria, Nsukka, on February 22, 2017, as Principal
          Internal Auditor/Head, Internal Audit Unit. He is currently a Chief
          Internal Auditor/Head, Investigation Division in the Internal Audit
          Unit of the University. He has attended numerous local and
          International Trainings, Conferences, Seminars and Workshops on
          several aspects of Accounting & Auditing, Finance & Investments,
          Banking, Internal Controls & Compliances, and Management.
          <div className="card-header">
            <h3 className="card-title">
              <i className="fas fa-bullhorn"></i>
              Professional/Community Service:
            </h3>
          </div>
          Secretary, Finances Management Committee, Living Faith Church
          (Winners’ Chapel) Nsukka (2019 Till Date)
          <br />
          Chairman, ICAN Nsukka District & Society Electoral Committee (2018 to
          2020) President, Ika Association, Nsukka Branch (2019 Till Date)
          <br />
          University of Nigeria, Nsukka Tenders Board (2017 to 2020)
          <br />
          Committee on Biometric Capturing of Staff of University of Nigeria,
          Nsukka for Tax Remittances (2019)
          <br />
          Committee to Review Financial Regulation of the University of Nigeria,
          Nsukka (2019) ICAN Examination Assessor (2018)
          <br />
          University of Nigeria, Nsukka Board of Survey for Scrap &
          Unserviceable Assets (2018)
          <br />
          University of Nigeria, Nsukka Committee on Digitalization of Students
          Clearance (2017 to 2018)
          <br />
          University of Nigeria, Nsukka Committee on Staff Audit & Verification
          (2018)
          <br />
          Mr Ugboko Lucky Azuka is happily married and blessed with children.
        </div>
      </div>
    </>
  );
}

export default Treasurer;
