import React from "react";
import { Form, Row, Button } from "react-bootstrap";
import InputField from "../../utilities/InputField";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { clearNotifications } from "../../../store/actions/notificationsActions";
import { editprofile } from "../../../store/actions/userActions";

function AddBankInfo(props) {
	const [error, setError] = React.useState({});
	const [inputValue, setInputValue] = React.useState({
		bankName: "",
		accountNumber: "",
		accountName: "",
	});

	const dispatch = useDispatch();
	const handleChange = (e) => {
		setInputValue({ ...inputValue, [e.target.name]: e.target.value });
		if (error[e.target.name]) {
			setError({ ...error, [e.target.name]: null });
		}
	};

	const notification = useSelector((state) => state.notification);

	React.useEffect(() => {
		if (notification?.success?.message) {
			toast.success(notification?.success?.message);

			setTimeout(() => props.hide(), 1000);
		}
		clearNotifications();
	}, [dispatch, notification, props]);

	const handleSubmit = (e) => {
		e.preventDefault();

		inputValue.id = props.id;
		inputValue.type = props.type;
		dispatch(editprofile(inputValue));
	};
	return (
		<Form onSubmit={handleSubmit}>
			<InputField
				label='Account Name'
				type='text'
				name='accountName'
				value={inputValue.accountName}
				onChange={handleChange}
				placeholder='enter account name'
				error={error.accountName}
				className='col-lg-12'
				require
			/>
			<InputField
				label='Account Number'
				type='text'
				name='accountNumber'
				value={inputValue.accountNumber}
				onChange={handleChange}
				placeholder='enter account number'
				error={error.accountNumber}
				className='col-lg-12'
				require
			/>

			<InputField
				label='Bank Name'
				type='text'
				name='bankName'
				value={inputValue.bankName}
				onChange={handleChange}
				placeholder='enter bank name'
				error={error.bankName}
				className='col-lg-12'
				require
			/>
			<Button className='btn-block' type='submit'>
				submit
			</Button>
		</Form>
	);
}

export default AddBankInfo;
