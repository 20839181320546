import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { signIn } from "../../../store/actions/authActions";
import { clearNotifications } from "../../../store/actions/notificationsActions";
import Swal from "sweetalert2";

function SignIn() {
	const dispatch = useDispatch();
	const history = useHistory();
	const notification = useSelector((state) => state.notification);

	const [formData, setFormData] = useState({
		email: "",
		password: "",
		userType: "admin",  // Default to admin since member login is external
	});

	const { email, password, userType } = formData;

	const handleChange = (event) => {
		const { name, value } = event.target;
		setFormData({ ...formData, [name]: value });
	};

	const toggleUser = (e) => {
		const newUserType = e.target.value;
		setFormData({ ...formData, userType: newUserType });

		if (newUserType === "member") {
			Swal.fire({
				icon: "info",
				title: "Member Login",
				text: "Members, please log in through the external portal.",
				confirmButtonText: "Go to External Portal",
			}).then(() => {
				window.location.href = "https://dashboard.lmcsnigltd.org.ng";
			});
		}
	};

	useEffect(() => {
		const successMessage = notification?.success?.message;
		const errorMessage = notification?.errors?.message;

		if (successMessage) {
			Swal.fire({
				icon: "success",
				text: successMessage,
				showConfirmButton: false,
				timer: 3000,
			}).then(() => {
				history.push("/dashboard");
			});
		} else if (errorMessage) {
			Swal.fire({
				icon: "error",
				text: errorMessage,
				showConfirmButton: true,
			});
		}
		dispatch(clearNotifications());
	}, [dispatch, history, notification]);

	const handleSubmit = (event) => {
		event.preventDefault();
		if (userType === "admin") {
			dispatch(signIn(formData));
		}
	};

	return (
		<div className='container register p-4 shadow'>
			<Row>
				<div className='col-md-4 col-sm-2 register-left'>
					<h3>Welcome</h3>
					<p>
						Don't have an account?
						<Link to='/signup' className='mx-4 text-primary'>
							Register
						</Link>
					</p>
				</div>
				<div className='col-md-8 col-sm-10 register-right'>
					<ul className='nav nav-tabs nav-justified'>
						<li className='nav-item'>
							<button
								className={userType === "member" ? "nav-link active p-1" : "nav-link p-1"}
								value='member'
								onClick={toggleUser}
							>
								Member
							</button>
						</li>
						<li className='nav-item'>
							<button
								className={userType === "admin" ? "nav-link active p-1" : "nav-link p-1"}
								value='admin'
								onClick={toggleUser}
							>
								Admin
							</button>
						</li>
					</ul>

					<div className='form-container' id='form-content'>
						<h2>
							<strong>Sign In As {userType === "admin" ? "Admin" : "Member"}</strong>
						</h2>
						{userType === "admin" && (
							<Form onSubmit={handleSubmit}>
								<Form.Group as={Col} className='mb-3' controlId='formloginemail'>
									<Form.Label>Email</Form.Label>
									<Form.Control
										type='email'
										name='email'
										value={email}
										onChange={handleChange}
										required
									/>
								</Form.Group>

								<Form.Group as={Col} className='mb-3' controlId='formloginpass'>
									<Form.Label>Password</Form.Label>
									<Form.Control
										type='password'
										name='password'
										value={password}
										onChange={handleChange}
										required
									/>
								</Form.Group>

								<div className='col'>
									<Link to='/forgotpassword'> Forgot password? </Link>
								</div>

								<Button
									type='submit'
									className='action-button'
									disabled={userType === "member"}  // Button disabled for 'member'
								>
									Sign In
								</Button>
							</Form>
						)}
					</div>
				</div>
			</Row>
		</div>
	);
}

export default SignIn;
