import React from "react";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";

function ResetPassword(props) {
  const auth = useSelector((state) => state.auth);

  React.useEffect(() => {
    if (auth.success) {
      const { message } = auth.success;
      toast.success(message);
    }
    if (auth.errors) {
      const { message } = auth.errors;
      toast.error(message);
    }
  });
  return (
    <div className="hold-transition  login-page">
      <div className="login-box">
        <div className="login-logo">
          <b>Reset Password</b>
        </div>
        <div className="card">
          <div className="card-body login-card-body">
            <p className="login-box-msg">
              You are only one step a way from your new password, recover your
              password now.
            </p>

            <Form.Group
              className="input-group mb-3"
              controlId="exampleForm.ControlInpu34"
            >
              <Form.Control
                type="password"
                className="form-control"
                placeholder="Password"
                onChange={(e) => props.setPassword(e.target.value)}
              />
              <div className="input-group-append">
                <div className="input-group-text">
                  <span className="fas fa-lock"></span>
                </div>
              </div>
            </Form.Group>
            <Form.Group
              className="input-group mb-3"
              controlId="exampleForm.ControlInpu34"
            >
              <Form.Control
                type="password"
                className="form-control"
                placeholder="Confirm Password"
                onChange={(e) => props.setConfirmPassword(e.target.value)}
              />
              <div className="input-group-append">
                <div className="input-group-text">
                  <span className="fas fa-lock"></span>
                </div>
              </div>
            </Form.Group>

            <div className="row">
              <div className="col-12">
                <button
                  className="btn btn-primary btn-block"
                  onClick={(e) => {
                    props.handleSubmit(e);
                  }}
                >
                  Change password
                </button>
              </div>
            </div>
            <ToastContainer position="top-center" />
            <p className="mt-3 mb-1">
              <Link to="/signin">Login</Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
