import React from "react";
import { Modal } from "react-bootstrap";

function CustomModal({ size, show, hide, title, children }) {
	return (
		<Modal
			size={size}
			show={show}
			onHide={hide}
			aria-labelledby='example-modal-sizes-title-lg'>
			<Modal.Header closeButton>
				<Modal.Title
					id='example-modal-sizes-title-lg'
					style={{ textAlign: "center" }}>
					{title}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className='mx-auto'>{children}</div>
			</Modal.Body>
		</Modal>
	);
}

export default CustomModal;
