import ImageGallery from "react-image-gallery";
// import "react-image-gallery/styles/scss/image-gallery.scss";
import "react-image-gallery/styles/css/image-gallery.css";

export const Gallery = (props) => {
  const images = [
    {
      original: "img/gallery/gallery (1).JPG",
      thumbnail: "img/gallery/gallery (1).JPG",
    },
    {
      original: "img/gallery/gallery (2).jpg",
      thumbnail: "img/gallery/gallery (2).jpg",
    },
    {
      original: "img/gallery/gallery (3).jpg",
      thumbnail: "img/gallery/gallery (3).jpg",
    },
    {
      original: "img/gallery/gallery (4).jpg",
      thumbnail: "img/gallery/gallery (4).jpg",
    },
    {
      original: "img/gallery/gallery (5).jpg",
      thumbnail: "img/gallery/gallery (5).jpg",
    },
    {
      original: "img/gallery/gallery (6).jpg",
      thumbnail: "img/gallery/gallery (6).jpg",
    },
    {
      original: "img/gallery/gallery (7).jpg",
      thumbnail: "img/gallery/gallery (7).jpg",
    },
    {
      original: "img/gallery/gallery (8).jpg",
      thumbnail: "img/gallery/gallery (8).jpg",
    },
    {
      original: "img/gallery/gallery (9).jpg",
      thumbnail: "img/gallery/gallery (9).jpg",
    },
    {
      original: "img/gallery/gallery (10).jpg",
      thumbnail: "img/gallery/gallery (10).jpg",
    },
    {
      original: "img/gallery/gallery (11).jpg",
      thumbnail: "img/gallery/gallery (11).jpg",
    },
    {
      original: "img/gallery/gallery (12).jpg",
      thumbnail: "img/gallery/gallery (12).jpg",
    },
    {
      original: "img/gallery/gallery (13).jpg",
      thumbnail: "img/gallery/gallery (13).jpg",
    },
    {
      original: "img/gallery/gallery (14).jpg",
      thumbnail: "img/gallery/gallery (14).jpg",
    },
    {
      original: "img/gallery/gallery (15).jpg",
      thumbnail: "img/gallery/gallery (15).jpg",
    },
    {
      original: "img/gallery/gallery (16).jpg",
      thumbnail: "img/gallery/gallery (16).jpg",
    },
    {
      original: "img/gallery/gallery (17).jpg",
      thumbnail: "img/gallery/gallery (17).jpg",
    },
    {
      original: "img/gallery/gallery (18).jpg",
      thumbnail: "img/gallery/gallery (18).jpg",
    },
    {
      original: "img/gallery/gallery (19).jpg",
      thumbnail: "img/gallery/gallery (19).jpg",
    },
    {
      original: "img/gallery/gallery (20).jpg",
      thumbnail: "img/gallery/gallery (20).jpg",
    },
  ];
  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Gallery</h2>
        </div>
        <div className=" justify-content-center ">
          <ImageGallery items={images} />;
        </div>
      </div>
    </div>
  );
};
