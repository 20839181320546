import React from "react";

function BrandOfficerInfo() {
  return (
    <>
      <div className="card card-widget widget-user-2">
        <div className="widget-user-header bg-warning">
          <div className="widget-user-image">
            <img
              className="img-circle elevation-2"
              src="img/team/brand_marketing_officer.jpg"
              alt="User Avatar"
            />
          </div>

          <h3 className="widget-user-username">ANTHONY C. ANEKE</h3>
          <h5 className="widget-user-desc">B.Sc, MBA, CNA, MNIM</h5>
          <h5 className="widget-user-desc">Brand/Digital Marketing Officer</h5>
        </div>
        <div className="card-footer p-0">
          Born in Kaduna State, he attended the St. Saviours Nursery and Primary
          School, Katsina rd, Kaduna. From there he attended the prestigious
          Nigeria Military School, Zaria, Kaduna State (a Secondary Institution
          where students are trained in Military and Academic subjects). He sat
          for the Senior School Certificate Examination in 1998 and after an
          excellent performance he gained admission for tertiary education at
          the University of Jos, Jos. Plateau State where he later bagged a B.Sc
          (Hons) in Accounting.
          <br />
          He started his career as a banker at the Central Bank of Nigeria,
          Abuja as a Trainee Accountant in 2005 where he worked extensively in
          the Sundry payments Unit of the Finance Department, gaining very
          highly sought after experience before moving on to Diamond Bank Plc.
          in 2006. There he garnered cognate experience in the Operations and
          Business Banking service arms. He cut his teeth in the Cash,
          Funds-Transfer and Customer Service units and subsequently moved to
          Clearing where he eventually rose to become Head of Central Clearing
          Services Abuja region. Then he moved to the Business arm of Banking as
          a Business development Officer/MSME Team lead and together with a
          great team comprising other professionals opened the Maitama (Abuja)
          branch of Diamond Bank Plc. which they grew in risk-asset portfolio
          and Cash balances to become a major profit making hub of the entire
          Region.
          <br />
          After the stint in Banking, he left for the Public Sector (Bursary
          department, University of Nigeria, Nsukka) in 2012 and since then has
          applied his acquired experience and skill from the private sector
          experience. <br />
          He has since headed various units of the department which include the
          E-payments, Staff/Personnel Emoluments and MIS units. He has an MBA
          from the University of Nigeria, Nsukka. He is a Certified member of
          the Association of National Accountants of Nigeria (ANAN) and also a
          Member of the Nigerian Institute of Management (NIM). <br />
          He has well over 16 years cognate experience that cuts across the
          private and public sectors and is well versed in Database
          administration and the administration and use of several accounting
          and payment softwares which include Flexcube, Finacle, GIFMIS, Remita,
          Sage Erp, Structured Pay Manager (SPM) amongst others.
        </div>
      </div>
    </>
  );
}

export default BrandOfficerInfo;
