import React, { Component } from "react";
import { Form, Button, Col, Row, Container, Spinner } from "react-bootstrap";
import ImagePreview from "../../utilities/ImagePreview";

export class PersonalDetails extends Component {
  state = {
    active: false,
    clicked: false,
    buttonText: "Register",
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.submitError?.message) {
      this.setState({ clicked: false, buttonText: "Register" }, () => {
        console.log("loading");
      });
    }
  }
  handleToggle = (e) => {
    if (e.target.value === "Others") {
      this.setState({ active: true }, () => {
        console.log(this.state.active);
      });
    }
  };

  handleShow = (e) => {
    if (Object.keys(this.props?.error).length === 0) {
      this.setState({ clicked: true, buttonText: "Registering" }, () => {
        console.log("loading");
      });
    }
  };
  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  next = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };

  render() {
    const { error, signatureURL, passportURL, data, refererName } = this.props;

    console.log(refererName);

    return (
      <Container className="form-container">
        <Form>
          {refererName.length > 0 ? (
            <div class="form-group">
              <label for="exampleInputEmail1">Invited By</label>
              <input
                type="text"
                className="form-control"
                defaultValue={refererName}
                readOnly
              />
            </div>
          ) : null}

          <Form.Group
            xs={12}
            as={Col}
            className="col-md-6"
            controlId="formTitle"
          >
            <Form.Label>Title</Form.Label>
            <Form.Control
              as="select"
              name="title"
              defaultValue={data?.title}
              onClick={this.handleToggle}
              onChange={this.props.handleChange}
            >
              <option> select your title</option>
              <option value="Prof.">Prof </option>
              <option value="Dr.">Dr.</option>
              <option value="Sir">Sir</option>
              <option value="Rev.">Rev</option>
              <option value="Chief">Chief</option>
              <option value="Alhaji">Alhaji</option>
              <option value="Mr.">Mr</option>
              <option value="Mrs.">Mrs</option>
              <option value="Miss">Miss</option>
              <option value="Sir">Sir</option>
              <option value="Lady">Lady</option>
              <option value="Others">Others</option>
            </Form.Control>
          </Form.Group>
          {this.state.active ? (
            <Form.Group className="mb-3" controlId="exampleForm.ControlInpu34">
              <Form.Label>Specify title</Form.Label>
              <Form.Control
                type="text"
                name="title"
                defaultValue={data?.title}
                onChange={this.props.handleChange}
              />
            </Form.Group>
          ) : null}

          <Form.Group
            xs={12}
            as={Col}
            className="col-md-6"
            controlId="formTitle"
          >
            <Form.Label>Gender</Form.Label>
            <Form.Control
              as="select"
              name="gender"
              defaultValue={data?.gender}
              onChange={this.props.handleChange}
            >
              <option> select your Gender</option>
              <option value="Male">Male </option>
              <option value="Female">Female</option>
              <option value="Others">Others</option>
            </Form.Control>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formdepartment">
            <Form.Label>Full name</Form.Label>
            <Form.Control
              type="text"
              name="fullName"
              defaultValue={data?.fullName}
              onChange={this.props.handleChange}
              isInvalid={!!error.fullName}
            />
            <Form.Control.Feedback type="invalid">
              {error.fullName}
            </Form.Control.Feedback>
          </Form.Group>
          <Row>
            <Form.Group as={Col} xs={12} controlId="formbirthdate">
              <Form.Label>Date of birth</Form.Label>
              <Form.Control
                type="date"
                name="birthDate"
                defaultValue={data?.birthDate}
                onChange={this.props.handleChange}
                isInvalid={!!error.birthDate}
              />
              <Form.Control.Feedback type="invalid">
                {error.birthDate}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} xs={12} controlId="formdepartment">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                defaultValue={data?.email}
                onChange={this.props.handleChange}
                isInvalid={!!error.email}
              />
              <Form.Control.Feedback type="invalid">
                {error.email}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row>
            {" "}
            <Form.Group as={Col} controlId="formdepartment">
              <Form.Label>Home address</Form.Label>
              <Form.Control
                type="text"
                name="homeAddress"
                defaultValue={data?.homeAddress}
                onChange={this.props.handleChange}
                isInvalid={!!error.homeAddress}
              />
              <Form.Control.Feedback type="invalid">
                {error.homeAddress}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} className="mb-3" controlId="formdepartment">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="text"
                name="phone"
                defaultValue={data?.phone}
                onChange={this.props.handleChange}
                isInvalid={!!error.phone}
              />
              <Form.Control.Feedback type="invalid">
                {error.phone}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row>
            {" "}
            {passportURL.length > 0 ? <ImagePreview url={passportURL} /> : null}
            <Form.Group
              xs={12}
              md={8}
              as={Col}
              controlId="formFile"
              className="mb-3"
            >
              <Form.Label>Passport</Form.Label>
              <Form.Control
                type="file"
                name="passport"
                onChange={this.props.handleChange}
                isInvalid={!!error.passport}
              />
              <Form.Control.Feedback type="invalid">
                {error.passport}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              xs={12}
              md={8}
              as={Col}
              controlId="formFile1"
              className="mb-3"
            >
              {signatureURL.length > 0 ? (
                <ImagePreview url={signatureURL} />
              ) : null}
              <Form.Label>Signature</Form.Label>
              <Form.Control
                type="file"
                name="signature"
                onChange={this.props.handleChange}
                isInvalid={!!error.signature}
              />
              <Form.Control.Feedback type="invalid">
                {error.signature}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row>
            {" "}
            <Form.Group as={Col} className="mb-3" controlId="formdepartment">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                name="password"
                defaultValue={data?.password}
                onChange={this.props.handleChange}
                isInvalid={!!error.password}
              />
              <Form.Control.Feedback type="invalid">
                {error.password}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} className="mb-3" controlId="formdepartment">
              <Form.Label>Confirm password</Form.Label>
              <Form.Control
                type="password"
                name="confirmPassword"
                defaultValue={data?.confirmPassword}
                onChange={this.props.handleChange}
                isInvalid={!!error.confirmPassword}
              />
              <Form.Control.Feedback type="invalid">
                {error.confirmPassword}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <div className="form-group mb-0">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                name="terms"
                className="custom-control-input"
                id="exampleCheck1"
                onChange={this.props.handleChange}
              />
              <label className="custom-control-label" for="exampleCheck1">
                I agree and confirm that all the information provided are
                correct and true, any false information can disqualify me from
                membership and the membership fee paid will not be refunded.
              </label>
            </div>
          </div>
          <Form.Control.Feedback type="invalid">
            {error.terms}
          </Form.Control.Feedback>

          <Button
            className="previous action-button-previous"
            onClick={this.back}
          >
            Previous
          </Button>
          <Button
            onClick={(e) => {
              this.handleShow(e);
              this.props.handleSubmit(e);
            }}
            className="action-button"
          >
            {this.state.clicked ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : null}
            {this.state.buttonText}
          </Button>
        </Form>
      </Container>
    );
  }
}

export default PersonalDetails;
