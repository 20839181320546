import React from "react";
import { Modal } from "react-bootstrap";
import FinancialSecretaryInfo from "./FinancialSecretaryInfo";
import PresidentInfo from "./PresidentInfo";
import VicePresidentInfo from "./VicePresidentInfo";
import Treasurer from "./Treasurer";
import Auditor from "./Auditor";
import BrandOfficerInfo from "./BrandOfficerInfo";
import LegalAdviserInfo from "./LegalAdviserInfo";
import AssistantSecretaryInfo from "./AssistantSecretaryInfo";
import GeneralSecInfo from "./GeneralSecInfo";

function InfoModal(props) {
  const renderInfo = (title) => {
    switch (title) {
      case "President":
        return <PresidentInfo />;

      case "Vice President":
        return <VicePresidentInfo />;
      case "Financial Secretary":
        return <FinancialSecretaryInfo />;

      case "Treasurer":
        return <Treasurer />;
      case "Auditor":
        return <Auditor />;
      case "Brand/Digital Marketing Officer":
        return <BrandOfficerInfo />;
      case "Legal Adviser":
        return <LegalAdviserInfo />;
      case "Assistant Secretary General/Financial Strategist":
        return <AssistantSecretaryInfo />;
      case "General Secretary":
        return <GeneralSecInfo />;
      default:
        return null;
    }
  };
  return (
    <Modal
      size="lg"
      show={props.showModal}
      onHide={props.handleShowModal}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>{renderInfo(props.jobtitle)}</Modal.Body>
    </Modal>
  );
}

export default InfoModal;
