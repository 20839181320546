import React, { useState, useEffect, useCallback } from "react";
import ImagePreview from "../utilities/ImagePreview";
import { Form, Col, Spinner, Row, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { clearNotifications } from "../../store/actions/notificationsActions";
import { updateUserProfile } from "../../store/actions/userProfileActions";
import InputField from "../utilities/InputField";
import Swal from "sweetalert2";

const EditUserProfile = ({ data, handleHideForm }) => {
	const dispatch = useDispatch();
	const notification = useSelector((state) => state.notification);

	const [state, setState] = useState({
		passportURL: "",
		signatureURL: "",
		error: {},
		disableButton: true,
		active: false,
		activeOrg: false,
		clicked: false,
		buttonText: "Update",
	});

	const { error } = state;

	const flattenObj = useCallback((obj) => {
		let result = {};
		for (const i in obj) {
			if (typeof obj[i] === "object" && !Array.isArray(obj[i])) {
				const temp = flattenObj(obj[i]);
				for (const j in temp) {
					result[j] = temp[j];
				}
			} else {
				result[i] = obj[i];
			}
		}
		return result;
	}, []);

	useEffect(() => {
		// Get the flattened data object
		const flattenedData = flattenObj(data);
		console.log(flattenedData);

		// Merge it with the existing state
		setState((prevState) => ({
			...prevState,
			...flattenedData,
		}));
	}, [data, flattenObj]);

	const findError = () => {
		const {
			organisationName,
			rank,
			gradeLevel,
			retirementDate,
			step,
			campusName,
			assumptionOfdutyDate,
			ippisNum,
			salaryStructure,
			faculty,
			department,
			staffNum,
			name,
			email,
			homeAddress,
			signature,
			passport,
			birthDate,
			category,
			phone,
			title,
			gender,
		} = state;
		const newErrors = {};

		if (!organisationName || organisationName === "")
			newErrors.organisationName = "cannot be blank!";
		if (!rank || rank === "") newErrors.rank = "cannot be blank!";
		if (!gradeLevel || gradeLevel === "")
			newErrors.gradeLevel = "cannot be blank!";
		if (!retirementDate || retirementDate === "")
			newErrors.retirementDate = "cannot be blank!";
		if (!step || step === "") newErrors.step = "cannot be blank!";
		if (!campusName || campusName === "")
			newErrors.campusName = "cannot be blank!";
		if (!assumptionOfdutyDate || assumptionOfdutyDate === "")
			newErrors.assumptionOfdutyDate = "cannot be blank!";
		if (!ippisNum || ippisNum === "") newErrors.ippisNum = "cannot be blank!";
		if (!salaryStructure || salaryStructure === "")
			newErrors.salaryStructure = "cannot be blank!";
		if (!faculty || faculty === "") newErrors.faculty = "cannot be blank!";
		if (!department || department === "")
			newErrors.department = "cannot be blank!";
		if (!staffNum || staffNum === "") newErrors.staffNum = "cannot be blank!";
		if (!name || name === "") newErrors.name = "cannot be blank!";
		if (!email || email === "") newErrors.email = "cannot be blank!";
		if (!homeAddress || homeAddress === "")
			newErrors.homeAddress = "cannot be blank!";
		if (!signature || signature === "")
			newErrors.signature = "cannot be blank!";
		if (!passport || passport === "") newErrors.passport = "cannot be blank!";
		if (!birthDate || birthDate === "")
			newErrors.birthDate = "cannot be blank!";
		if (!category || category === "") newErrors.category = "cannot be blank!";
		if (!phone || phone === "") newErrors.phone = "cannot be blank!";
		if (!title || title === "") newErrors.title = "cannot be blank!";
		if (!gender || gender === "") newErrors.gender = "cannot be blank!";

		return newErrors;
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		const newErrors = findError();
		if (Object.keys(newErrors).length > 0) {
			setState({ ...state, error: newErrors });
			Swal.fire({
				icon: "error",
				text: "Some required fields are missing",
				showConfirmButton: true,
			});
		} else {
			const formData = new FormData();

			formData.append("name", state?.name);
			formData.append("email", state?.email);
			formData.append("organisationName", state?.organisationName);
			formData.append("rank", state?.rank);
			formData.append("gradeLevel", state?.gradeLevel);
			formData.append("retirementDate", state?.retirementDate);
			formData.append("step", state?.step);
			formData.append("campusName", state?.campusName);
			formData.append("assumptionOfdutyDate", state?.assumptionOfdutyDate);
			formData.append("ippisNum", state?.ippisNum);
			formData.append("salaryStructure", state?.salaryStructure);
			formData.append("faculty", state?.faculty);
			formData.append("department", state?.department);
			formData.append("staffNum", state?.staffNum);
			formData.append("homeAddress", state?.homeAddress);
			formData.append("signature", state?.signature);
			formData.append("passport", state?.passport);
			formData.append("birthDate", state?.birthDate);
			formData.append("category", state?.category);
			formData.append("phone", state?.phone);
			formData.append("gender", state?.gender);
			formData.append("title", state?.title);
			formData.append("nok1", state?.nok1 || "");
			formData.append("nokAddress1", state?.nokAddress1 || "");
			formData.append("nokEmail1", state?.nokEmail1 || "");
			formData.append("nokPhone1", state?.nokPhone1 || "");
			formData.append("relationship1", state?.relationship1 || "");
			formData.append("nok2", state?.nok2 || "");
			formData.append("nokAddress2", state?.nokAddress2 || "");
			formData.append("nokEmail2", state?.nokEmail2 || "");
			formData.append("nokPhone2", state?.nokPhone2 || "");
			formData.append("relationship2", state?.relationship2 || "");

			formData.append("id", data._id);

			dispatch(updateUserProfile(formData));
		}
	};

	const handleToggle = (e) => {
		if (e.target.value === "Others")
			setState((prevState) => ({ ...prevState, active: true }));
	};
	const ToggleNameofOrg = (e) => {
		if (e.target.value === "Others")
			setState((prevState) => ({ ...prevState, activeOrg: true }));
	};

	const handleShow = (e) => {
		if (Object.keys(state?.error).length === 0) {
			setState((prevState) => ({
				...prevState,
				clicked: true,
				buttonText: "Updating",
			}));
		}
	};
	const handleChange = (event) => {
		const { name, value, files } = event.target;

		// Check if the event target has files
		if (files) {
			const file = files[0];

			setState((prevState) => ({
				...prevState,
				[name]: file,
				[`${name}URL`]: URL.createObjectURL(file),
				error: {
					...prevState.error,
					[name]: null,
				},
			}));
		} else {
			setState((prevState) => ({
				...prevState,
				[name]: value,
				error: {
					...prevState.error,
					[name]: null,
				},
			}));
		}
	};

	React.useEffect(() => {
		const successMessage = notification?.success?.message;
		const errorMessage = notification?.errors?.message;

		if (successMessage) {
			Swal.fire({
				icon: "success",
				text: successMessage,
				showConfirmButton: false,
				timer: 3000,
			}).then(() => handleHideForm());
		} else if (errorMessage) {
			Swal.fire({
				icon: "error",
				text: errorMessage,
				showConfirmButton: true,
			});
		}

		setState((prevState) => ({
			...prevState,
			clicked: false,
			buttonText: "Update",
		}));

		dispatch(clearNotifications());
	}, [dispatch, notification, handleHideForm]);

	return (
		<>
			<div className='active tab-pane' id='activity'>
				<div className='card'>
					<div className='card-body'>
						<Form>
							<Row>
								<Form.Group
									xs={12}
									as={Col}
									className='col-md-6'
									controlId='formTitle'>
									<Form.Label>Title</Form.Label>
									<Form.Control
										as='select'
										name='title'
										defaultValue={data?.title}
										onClick={handleToggle}
										onChange={handleChange}>
										<option> select your title</option>
										<option value='Prof.'>Prof </option>
										<option value='Dr.'>Dr.</option>
										<option value='Sir'>Sir</option>
										<option value='Rev.'>Rev</option>
										<option value='Chief'>Chief</option>
										<option value='Alhaji'>Alhaji</option>
										<option value='Mr.'>Mr</option>
										<option value='Mrs.'>Mrs</option>
										<option value='Miss'>Miss</option>
										<option value='Sir'>Sir</option>
										<option value='Lady'>Lady</option>
										<option value='Others'>Others</option>
									</Form.Control>
								</Form.Group>
								{state.active && (
									<Form.Group
										className='mb-3'
										controlId='exampleForm.ControlInpu34'>
										<Form.Label>Specify title</Form.Label>
										<Form.Control
											type='text'
											name='title'
											value={data?.title}
											onChange={handleChange}
										/>
									</Form.Group>
								)}

								<Form.Group
									xs={12}
									as={Col}
									className='col-md-6'
									controlId='formTitle'>
									<Form.Label>Gender</Form.Label>
									<Form.Control
										as='select'
										name='gender'
										defaultValue={data?.gender}
										onChange={handleChange}>
										<option> select your Gender</option>
										<option value='Male'>Male </option>
										<option value='Female'>Female</option>
										<option value='Others'>Others</option>
									</Form.Control>
								</Form.Group>
							</Row>
							<Form.Group className='mb-3' controlId='formdepartment'>
								<Form.Label>Full name</Form.Label>
								<Form.Control
									type='text'
									name='name'
									defaultValue={data?.name}
									onChange={handleChange}
									isInvalid={!!error?.name}
								/>
								<Form.Control.Feedback type='invalid'>
									{error?.name}
								</Form.Control.Feedback>
							</Form.Group>
							<Row>
								<Form.Group as={Col} xs={12} controlId='formbirthdate'>
									<Form.Label>Date of birth</Form.Label>
									<Form.Control
										type='date'
										name='birthDate'
										defaultValue={data?.birthDate}
										onChange={handleChange}
										isInvalid={!!error?.birthDate}
									/>
									<Form.Control.Feedback type='invalid'>
										{error?.birthDate}
									</Form.Control.Feedback>
								</Form.Group>
								<Form.Group as={Col} xs={12} controlId='formdepartment'>
									<Form.Label>Email</Form.Label>
									<Form.Control
										type='email'
										name='email'
										defaultValue={data?.email}
										onChange={handleChange}
										isInvalid={!!error?.email}
									/>
									<Form.Control.Feedback type='invalid'>
										{error?.email}
									</Form.Control.Feedback>
								</Form.Group>
							</Row>

							<Row>
								{" "}
								<Form.Group as={Col} controlId='formdepartment'>
									<Form.Label>Home address</Form.Label>
									<Form.Control
										type='text'
										name='homeAddress'
										defaultValue={data?.homeAddress}
										onChange={handleChange}
										isInvalid={!!error?.homeAddress}
									/>
									<Form.Control.Feedback type='invalid'>
										{error?.homeAddress}
									</Form.Control.Feedback>
								</Form.Group>
								<Form.Group
									as={Col}
									className='mb-3'
									controlId='formdepartment'>
									<Form.Label>Phone Number</Form.Label>
									<Form.Control
										type='number'
										name='phone'
										defaultValue={data?.phone}
										onChange={handleChange}
										isInvalid={!!error?.phone}
									/>
									<Form.Control.Feedback type='invalid'>
										{error?.phone}
									</Form.Control.Feedback>
								</Form.Group>
							</Row>
							<Row>
								{" "}
								{state?.passportURL?.length > 0 && (
									<ImagePreview url={state.passportURL} />
								)}
								<Form.Group
									xs={12}
									md={8}
									as={Col}
									controlId='formFile'
									className='mb-3'>
									<Form.Label>Passport</Form.Label>
									<Form.Control
										type='file'
										name='passport'
										onChange={handleChange}
										isInvalid={!!error?.passport}
									/>
									<Form.Control.Feedback type='invalid'>
										{error?.passport}
									</Form.Control.Feedback>
								</Form.Group>
								<Form.Group
									xs={12}
									md={8}
									as={Col}
									controlId='formFile1'
									className='mb-3'>
									{state?.signatureURL?.length > 0 && (
										<ImagePreview url={state.signatureURL} />
									)}
									<Form.Label>Signature</Form.Label>
									<Form.Control
										type='file'
										name='signature'
										onChange={handleChange}
										isInvalid={!!error?.signature}
									/>
									<Form.Control.Feedback type='invalid'>
										{error?.signature}
									</Form.Control.Feedback>
								</Form.Group>
							</Row>
						</Form>
						<Button
							className='previous action-button-previous'
							onClick={handleHideForm}>
							Cancel
						</Button>
						<Button
							onClick={(e) => {
								handleShow(e);
								handleSubmit(e);
							}}
							className='action-button float-right'>
							{state?.clicked && (
								<Spinner
									as='span'
									animation='border'
									size='sm'
									role='status'
									aria-hidden='true'
								/>
							)}
							{state?.buttonText}
						</Button>
					</div>
				</div>
			</div>

			{/* personaldetails end */}
			<div className='tab-pane' id='timeline'>
				<div className='card'>
					<div className='card-body'>
						<Form>
							<Row>
								<Form.Group xs={12} as={Col} controlId='formOrganizationName'>
									<Form.Label>Name of organization</Form.Label>
									<Form.Control
										as='select'
										name='organisationName'
										value={state?.organisationName}
										onClick={ToggleNameofOrg}
										onChange={handleChange}
										isInvalid={!!error?.organisationName}>
										<option> select organisation name </option>
										<option value='University of Nigeria'>
											{" "}
											University of Nigeria
										</option>
										<option value='Others'>Others</option>
									</Form.Control>
									<Form.Control.Feedback type='invalid'>
										{error?.organisationName}
									</Form.Control.Feedback>
								</Form.Group>
							</Row>
							<Row>
								{state.activeOrg ? (
									<Form.Group as={Col} controlId='exampleForm.ControlInput1'>
										<Form.Label>Work place</Form.Label>
										<Form.Control
											type='text'
											name='organisationName'
											defaultValue={state?.organisationName}
											onChange={handleChange}
											isInvalid={!!error?.campusName}
											placeholder='Enter name of organisation'
										/>
										<Form.Control.Feedback type='invalid'>
											{error?.organisationName}
										</Form.Control.Feedback>
									</Form.Group>
								) : null}

								<Form.Group xs={12} as={Col} controlId='formcampusName'>
									<Form.Label>Name of campus</Form.Label>
									<Form.Control
										as='select'
										name='campusName'
										value={state?.campusName}
										onChange={handleChange}>
										<option> select campus name</option>
										<option value='NSUKKA'>Nsukka Campus </option>
										<option value='ENUGU'>Enugu Campus</option>
										<option value='COLLEGE OF MEDICINE'>
											College of Medicine
										</option>
										<option value='N/A'>Not applicable</option>
									</Form.Control>
								</Form.Group>
								<Form.Group as={Col} controlId='exampleForm.ControlInput1'>
									<Form.Label>Faculty</Form.Label>
									<Form.Control
										type='text'
										name='faculty'
										value={state?.faculty}
										onChange={handleChange}
										placeholder='Enter n/a if not applicable'
									/>
								</Form.Group>

								<Form.Group as={Col} controlId='formdepartment'>
									<Form.Label>Department</Form.Label>
									<Form.Control
										type='text'
										name='department'
										value={state?.department}
										onChange={handleChange}
										placeholder='Enter n/a if not applicable'
									/>
								</Form.Group>
							</Row>
							<Row>
								<Form.Group as={Col} controlId='formrank'>
									<Form.Label>Rank/Designation</Form.Label>
									<Form.Control
										required
										type='text'
										name='rank'
										value={state?.rank}
										onChange={handleChange}
										isInvalid={!!error?.rank}
									/>
									<Form.Control.Feedback type='invalid'>
										{error?.rank}
									</Form.Control.Feedback>
								</Form.Group>
								<Form.Group as={Col} controlId='formsalaryStructure'>
									<Form.Label>Staff number</Form.Label>
									<Form.Control
										required
										type='text'
										name='staffNum'
										defaultValue={state?.staffNum}
										onChange={handleChange}
										isInvalid={!!error?.staffNum}
									/>
									<Form.Control.Feedback type='invalid'>
										{error?.staffNum}
									</Form.Control.Feedback>
								</Form.Group>
							</Row>

							<Row>
								<Form.Group as={Col} controlId='exampleForm.ControlInput1'>
									<Form.Label>Grade Level</Form.Label>
									<Form.Control
										type='text'
										name='gradeLevel'
										defaultValue={state?.gradeLevel}
										onChange={handleChange}
										isInvalid={!!error?.gradeLevel}
									/>
									<Form.Control.Feedback type='invalid'>
										{error?.gradeLevel}
									</Form.Control.Feedback>
								</Form.Group>
								<Form.Group as={Col} controlId='formstep'>
									<Form.Label>Step</Form.Label>
									<Form.Control
										required
										type='text'
										name='step'
										defaultValue={state?.step}
										onChange={handleChange}
										isInvalid={!!error?.step}
									/>
									<Form.Control.Feedback type='invalid'>
										{error?.step}
									</Form.Control.Feedback>
								</Form.Group>
							</Row>
							<Row>
								<Form.Group as={Col} controlId='formsalaryStructure'>
									<Form.Label>Salary Structure</Form.Label>
									<Form.Control
										required
										type='text'
										name='salaryStructure'
										defaultValue={state?.salaryStructure}
										placeholder='CONTISS, CONUASS.....'
										onChange={handleChange}
										isInvalid={!!error?.salaryStructure}
									/>
									<Form.Control.Feedback type='invalid'>
										{error?.salaryStructure}
									</Form.Control.Feedback>
								</Form.Group>
								<Form.Group as={Col} controlId='formippisNum'>
									<Form.Label>IPPIS Number</Form.Label>
									<Form.Control
										required
										type='text'
										name='ippisNum'
										defaultValue={state?.ippisNum}
										onChange={handleChange}
										isInvalid={!!error?.ippisNum}
									/>
									<Form.Control.Feedback type='invalid'>
										{error?.ippisNum}
									</Form.Control.Feedback>
								</Form.Group>
							</Row>
							<Row>
								<Form.Group xs={12} as={Col} controlId='formcampusName'>
									<Form.Label>Category</Form.Label>
									<Form.Control
										as='select'
										name='category'
										defaultValue={state?.category}
										required
										onChange={handleChange}
										isInvalid={!!error?.category}>
										<option>select category</option>
										<option value='Senior'>Senior Category </option>
										<option value='Junior'>Junior Category</option>
									</Form.Control>
									<Form.Control.Feedback type='invalid'>
										{error?.category}
									</Form.Control.Feedback>
								</Form.Group>

								<Form.Group xs={12} as={Col} controlId='formsalaryStructure'>
									<Form.Label>Date of assumption of duty</Form.Label>
									<Form.Control
										required
										type='date'
										name='assumptionOfdutyDate'
										defaultValue={state?.assumptionOfdutyDate}
										onChange={handleChange}
										isInvalid={!!error?.assumptionOfdutyDate}
									/>
									<Form.Control.Feedback type='invalid'>
										{error?.assumptionOfdutyDate}
									</Form.Control.Feedback>
								</Form.Group>
							</Row>
							<Form.Group controlId='formstep'>
								<Form.Label>Date of retirement</Form.Label>
								<Form.Control
									required
									type='date'
									name='retirementDate'
									defaultValue={state?.retirementDate}
									onChange={handleChange}
									isInvalid={!!error?.retirementDate}
								/>
								<Form.Control.Feedback type='invalid'>
									{error?.retirementDate}
								</Form.Control.Feedback>
							</Form.Group>
						</Form>
						<Button
							className='previous action-button-previous'
							onClick={handleHideForm}>
							Cancel
						</Button>
						<Button
							onClick={(e) => {
								handleShow(e);
								handleSubmit(e);
							}}
							className='action-button float-right'>
							{state?.clicked && (
								<Spinner
									as='span'
									animation='border'
									size='sm'
									role='status'
									aria-hidden='true'
								/>
							)}
							{state?.buttonText}
						</Button>
					</div>
				</div>
			</div>
			<div className='tab-pane' id='nok'>
				<div className='card'>
					<div className='card-body'>
						<Form>
							<Row>
								<InputField
									label='Next Of Kin 1'
									type='text'
									name='nok1'
									value={state.nok1}
									onChange={handleChange}
									placeholder='enter name of next of kin'
									error={error?.nok1}
									className='col-lg-6'
									require={true}
								/>
								<InputField
									label='NOK 1 Address'
									type='text'
									name='nokAddress1'
									value={state.nokAddress1}
									onChange={handleChange}
									placeholder='enter work address'
									error={error?.nokAddress1}
									className='col-lg-6'
									require={true}
								/>
							</Row>

							<Row>
								<InputField
									label='NOK 1 Email'
									type='text'
									name='nokEmail1'
									value={state.nokEmail1}
									onChange={handleChange}
									placeholder='enter name of next of kin'
									error={error?.nokEmail1}
									className='col-lg-6'
									require={true}
								/>
								<InputField
									label='NOK 1 Phone Number'
									type='text'
									name='nokPhone1'
									value={state.nokPhone1}
									onChange={handleChange}
									placeholder='enter work address'
									error={error?.nokPhone1}
									className='col-lg-6'
									require={true}
								/>
							</Row>
							<Row>
								<InputField
									label='Relationship'
									type='text'
									name='relationship1'
									value={state.relationship1}
									onChange={handleChange}
									placeholder='enter relationship '
									error={error?.relationship1}
									className='col-lg-6'
									require={true}
								/>
							</Row>
							<hr />
							<Row>
								<InputField
									label='Next Of Kin 2'
									type='text'
									name='nok2'
									value={state.nok2}
									onChange={handleChange}
									placeholder='enter name of next of kin'
									error={error?.nok2}
									className='col-lg-6'
									require={true}
								/>
								<InputField
									label='NOK 2 Address'
									type='text'
									name='nokAddress2'
									value={state.nokAddress2}
									onChange={handleChange}
									placeholder='enter work address'
									error={error?.nokAddress2}
									className='col-lg-6'
									require={true}
								/>
							</Row>

							<Row>
								<InputField
									label='NOK 2 Email'
									type='text'
									name='nokEmail2'
									value={state.nokEmail2}
									onChange={handleChange}
									placeholder='enter name of next of kin'
									error={error?.nokEmail2}
									className='col-lg-6'
									require={true}
								/>
								<InputField
									label='NOK 2 Phone Number'
									type='text'
									name='nokPhone2'
									value={state.nokPhone2}
									onChange={handleChange}
									placeholder='enter work address'
									error={error?.nokPhone2}
									className='col-lg-6'
									require={true}
								/>
							</Row>
							<Row>
								<InputField
									label='Relationship'
									type='text'
									name='relationship2'
									value={state.relationship2}
									onChange={handleChange}
									placeholder='enter relationship '
									error={error?.relationship2}
									className='col-lg-6'
									require={true}
								/>
							</Row>
							<Button
								className='previous action-button-previous'
								onClick={handleHideForm}>
								Cancel
							</Button>
							<Button
								onClick={(e) => {
									handleShow(e);
									handleSubmit(e);
								}}
								className='action-button float-right'>
								{state?.clicked && (
									<Spinner
										as='span'
										animation='border'
										size='sm'
										role='status'
										aria-hidden='true'
									/>
								)}
								{state?.buttonText}
							</Button>
						</Form>
					</div>
				</div>
			</div>
		</>
	);
};

export default EditUserProfile;
