import React from "react";
import Moment from "react-moment";
import format from "format-number";
import "./historyitem.css";

function HistoryItem(props) {
	const { data } = props;

	return (
		<div className='transaction-card '>
			<div className='details scrolling-wrapper flex-row flex-nowrap'>
				<div className='narration'>
					{" "}
					<div>{data.narration}</div>
					<div>
						<b>
							{" "}
							<i className='fas fa-clock'></i>{" "}
							<Moment format='D MMM YYYY HH:mm a'>{data.dt}</Moment>
						</b>
					</div>
				</div>
				<div className='row balances'>
					<div className={data.Debit > 0 ? `text-danger col ` : "col"}>
						{" "}
						{format({ prefix: "₦" })(data?.Debit)}{" "}
					</div>{" "}
					<div className={data.Credit > 0 ? `text-success col ` : "col"}>
						{format({ prefix: "₦" })(data?.Credit)}{" "}
					</div>
					<div className='col'>{format({ prefix: "₦" })(data?.Balance)} </div>
				</div>
			</div>
		</div>
	);
}

export default HistoryItem;
