import React, { Component } from "react";
import { Form, Button, Col, Row, Container } from "react-bootstrap";

export class PaymentDetails extends Component {
  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  next = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };
  render() {
    const { error, disableButton, data } = this.props;
    // console.log(disableButton);

    return (
      <Container className="form-container">
        <Form>
          <Form.Group className="mb-3" controlId="formGridTellerName">
            <Form.Label>Teller Name</Form.Label>
            <Form.Control
              placeholder="John Doe"
              type="text"
              name="tellerName"
              defaultValue={data?.tellerName}
              required
              onChange={this.props.handleChange}
              isInvalid={!!error?.tellerName}
            />
            <Form.Control.Feedback type="invalid">
              {error.tellerName}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formGridTellerRefNum">
            <Form.Label>Transaction Id</Form.Label>
            <Form.Control
              type="text"
              name="tellerRefNum"
              defaultValue={data?.tellerRefNum}
              required
              onChange={this.props.handleChange}
              isInvalid={!!error.tellerRefNum}
            />
            <Form.Control.Feedback type="invalid">
              {error.tellerRefNum}
            </Form.Control.Feedback>
          </Form.Group>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridAmount">
              <Form.Label>Amount Paid</Form.Label>
              <Form.Control
                type="text"
                name="amountPaid"
                placeholder="2000"
                defaultValue={data?.amountPaid}
                required
                onChange={this.props.handleChange}
                isInvalid={!!error.amountPaid}
              />
              <Form.Control.Feedback type="invalid">
                {error.amountPaid}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} xs={12} controlId="formGridPaymentDate">
              <Form.Label>Date of payment</Form.Label>
              <Form.Control
                type="date"
                name="paymentDate"
                defaultValue={data?.paymentDate}
                required
                onChange={this.props.handleChange}
                isInvalid={!!error.paymentDate}
              />
              <Form.Control.Feedback type="invalid">
                {error.paymentDate}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Form.Group className="mb-3" controlId="formGridTellerName">
            <Form.Label>Name of Bank paid from</Form.Label>
            <Form.Control
              placeholder="John Doe"
              type="text"
              name="bankName"
              defaultValue={data?.bankName}
              required
              onChange={this.props.handleChange}
              isInvalid={!!error.bankName}
            />
            <Form.Control.Feedback type="invalid">
              {error.bankName}
            </Form.Control.Feedback>
          </Form.Group>
          <Button
            disabled={disableButton}
            className="action-button"
            onClick={this.next}
          >
            Next
          </Button>
        </Form>
      </Container>
    );
  }
}

export default PaymentDetails;
