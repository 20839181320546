import React from "react";
import "./cart.css";

function CartItem(props) {
  const { productName, variation, quantity, totalPrice, handleRemove } = props;
  return (
    <div className="row border-top border-bottom">
      <div className="row main align-items-center">
        <div className="col">
          <div className="row text-muted">{productName}</div>
          <div className="row">{variation}</div>
        </div>
        <div className="col">
          {" "}
          <span className="border">{quantity}</span>
        </div>
        <div className="col">
          {`\u{020A6}` + totalPrice}{" "}
          <span
            className="close text-danger"
            id={productName}
            onClick={handleRemove}
          >
            remove item
          </span>
        </div>
      </div>
    </div>
  );
}

export default CartItem;
