import React from "react";
import KanbanBoardContainer from "../utilities/KanbanBoardContainer";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { fetchMembers } from "../../store/actions/adminActions";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import { Button } from "react-bootstrap";

function SummaryReport() {
  const { members } = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  let data = [];

  const memberswithrequest = members?.filter((member) => {
    const { initialSavingsRequest } = member;
    return initialSavingsRequest?.acknowledged === "seen";
  });

  let flattenObj = (obj) => {
    let result = {};
    for (const i in obj) {
      if (typeof obj[i] === "object" && !Array.isArray(obj[i])) {
        const temp = flattenObj(obj[i]);
        for (const j in temp) {
          result[j] = temp[j];
        }
      } else {
        result[i] = obj[i];
      }
    }
    return result;
  };

  memberswithrequest.forEach((member, index) => {
    member.serial = index + 1;
    member.time = moment(member.initialSavingsRequest.requestedAt).format(
      "dddd, MMMM Do YYYY, h:mm:ss a"
    );

    data.push(flattenObj(member));
  });

  React.useEffect(() => {
    dispatch(fetchMembers());
  }, [dispatch]);

  const columns = [
    {
      dataField: "serial",
      text: "#",
      headerStyle: {
        backgroundColor: "#c8e6c9",
        width: "50px",
      },
    },
    {
      dataField: "name",
      text: "Name",

      sort: true,
      headerStyle: {
        backgroundColor: "#c8e6c9",
        width: "200px",
      },
    },
    {
      dataField: "ippisNum",
      text: "IPPIS number",
      headerStyle: {
        backgroundColor: "#c8e6c9",
        width: "200px",
      },
    },
    {
      dataField: "staffNum",
      text: "Staff Number",
      headerStyle: {
        backgroundColor: "#c8e6c9",
        width: "200px",
      },
    },
    {
      dataField: "memberId",
      text: "Member Id",
      headerStyle: {
        backgroundColor: "#c8e6c9",
        width: "200px",
      },
    },
    {
      dataField: "shareCapitalAmount",
      text: "Share Capital Amount",
      headerStyle: {
        backgroundColor: "#c8e6c9",
        width: "200px",
      },
    },
    {
      dataField: "shareCapitalMonths",
      text: "Share capital months",
      headerStyle: {
        backgroundColor: "#c8e6c9",
        width: "200px",
      },
    },
    {
      dataField: "ordinarySavingsAmount",
      text: "Ordinary savings amount",
      headerStyle: {
        backgroundColor: "#c8e6c9",
        width: "200px",
      },
    },
    {
      dataField: "ordinarySavingsMonths",
      text: "Ordinary savings months",
      headerStyle: {
        backgroundColor: "#c8e6c9",
        width: "200px",
      },
    },
    {
      dataField: "christmasSavingsAmount",
      text: "Christmas savings amount",
      headerStyle: {
        backgroundColor: "#c8e6c9",
        width: "200px",
      },
    },
    {
      dataField: "christmasSavingsMonths",
      text: "Christmas savings months",
      headerStyle: {
        backgroundColor: "#c8e6c9",
        width: "200px",
      },
    },
    {
      dataField: "educationSavingsAmount",
      text: "education savings amount",
      headerStyle: {
        backgroundColor: "#c8e6c9",
        width: "200px",
      },
    },
    {
      dataField: "educationSavingsMonths",
      text: "education savings months",
      headerStyle: {
        backgroundColor: "#c8e6c9",
        width: "200px",
      },
    },
    {
      dataField: "retirementSavingsAmount",
      text: "Retirement savings amount",
      headerStyle: {
        backgroundColor: "#c8e6c9",
        width: "200px",
      },
    },
    {
      dataField: "retirementSavingsMonths",
      text: "Retirement savings months",
      headerStyle: {
        backgroundColor: "#c8e6c9",
        width: "200px",
      },
    },
    {
      dataField: "landDevSavingsAmount",
      text: "Land Development savings amount",
      headerStyle: {
        backgroundColor: "#c8e6c9",
        width: "200px",
      },
    },
    {
      dataField: "landDevSavingsMonths",
      text: "Land Development Savings months",
      headerStyle: {
        backgroundColor: "#c8e6c9",
        width: "200px",
      },
    },
    {
      dataField: "time",
      text: "Submitted On",

      headerStyle: {
        backgroundColor: "#c8e6c9",
        width: "200px",
      },

      sort: true,
    },
  ];

  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <div style={{ textAlign: "right" }}>
        <Button variant="primary" size="lg" onClick={handleClick}>
          Export To CSV
        </Button>
      </div>
    );
  };
  return (
    <KanbanBoardContainer>
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">
            MEMBERS SUMMARY TABLE
          </h3>
        </div>
        <section className="content pb-3">
          <div className="card-body">
            <ToolkitProvider
              stripped
              hover
              keyField="_id"
              columns={columns}
              data={data}
              exportCSV
            >
              {(props) => (
                <div>
                  <MyExportCSV {...props.csvProps} />
                  <hr />
                  <BootstrapTable {...props.baseProps} />
                </div>
              )}
            </ToolkitProvider>
          </div>
        </section>
      </div>
    </KanbanBoardContainer>
  );
}

export default SummaryReport;
