import React, { useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { useDispatch, useSelector } from "react-redux";
import { fetchMembers } from "../../store/actions/adminActions";
import { Button } from "react-bootstrap";
import KanbanBoardContainer from "../utilities/KanbanBoardContainer";
import ContentWrapper from "../utilities/ContentWrapper";

function MemberRoster() {
	const dispatch = useDispatch();

	const { members } = useSelector((state) => state.admin);
	const data = [];
	useEffect(() => {
		dispatch(fetchMembers());
	}, [dispatch]);
	const sortedRef = members.sort((a, b) => {
		let first = a.memberId.slice(-6);
		let second = b.memberId.slice(-6);
		return parseInt(first) - parseInt(second);
	});

	console.log(sortedRef);
	let flattenObj = (obj) => {
		let result = {};
		for (const i in obj) {
			if (typeof obj[i] === "object" && !Array.isArray(obj[i])) {
				const temp = flattenObj(obj[i]);
				for (const j in temp) {
					result[j] = temp[j];
				}
			} else {
				result[i] = obj[i];
			}
		}
		return result;
	};

	members.forEach((member, index) => {
		if (member.memberId !== "pending approval") {
			member.serial = index + 1;
			member.salaryStr =
				member?.employmentDetails?.salaryStructure.toUpperCase();
			member.fullname = member?.name?.toUpperCase();

			data.push(flattenObj(member));
		}
	});

	const passport = (cell, row, rowIndex, formatExtraData) => {
		const rowObj = row;

		return (
			<span style={{ width: "100px", height: "100px", overflow: "hidden" }}>
				<img
					style={{ width: "100px", height: "100px", objectFit: "cover" }}
					src={rowObj.passport}
					alt='...'
				/>
			</span>
		);
	};
	const signature = (cell, row, rowIndex, formatExtraData) => {
		const rowObj = row;

		return (
			<span style={{ width: "100px", height: "100px", overflow: "hidden" }}>
				<img
					style={{ width: "100px", height: "100px", objectFit: "cover" }}
					src={rowObj.signature}
					alt='...'
				/>
			</span>
		);
	};

	const columns = [
		{
			dataField: "serial",
			text: "#",
			headerStyle: {
				backgroundColor: "#c8e6c9",
				width: "50px",
			},
		},
		{
			dataField: "fullname",
			text: "Name",

			sort: true,
			headerStyle: {
				backgroundColor: "#c8e6c9",
				width: "200px",
			},
		},
		{
			dataField: "email",
			text: "Email",

			sort: true,
			headerStyle: {
				backgroundColor: "#c8e6c9",
				width: "200px",
			},
		},
		{
			dataField: "memberId",
			text: "Member Id",

			sort: true,
			headerStyle: {
				backgroundColor: "#c8e6c9",
				width: "200px",
			},
		},
		{
			dataField: "homeAddress",
			text: "Home Address",

			sort: true,
			headerStyle: {
				backgroundColor: "#c8e6c9",
				width: "200px",
			},
		},
		{
			dataField: "phone",
			text: "Phone Number",

			sort: true,
			headerStyle: {
				backgroundColor: "#c8e6c9",
				width: "150px",
			},
		},
		{
			dataField: "birthDate",
			text: "Birth Date",

			sort: true,
			headerStyle: {
				backgroundColor: "#c8e6c9",
				width: "200px",
			},
		},
		{
			dataField: "organisationName",
			text: "Name of Organisation",

			sort: true,
			headerStyle: {
				backgroundColor: "#c8e6c9",
				width: "200px",
			},
		},
		{
			dataField: "faculty",
			text: "Faculty",

			sort: true,
			headerStyle: {
				backgroundColor: "#c8e6c9",
				width: "200px",
			},
		},
		{
			dataField: "department",
			text: "Department",

			sort: true,
			headerStyle: {
				backgroundColor: "#c8e6c9",
				width: "200px",
			},
		},
		{
			dataField: "category",
			text: "Category",

			sort: true,
			headerStyle: {
				backgroundColor: "#c8e6c9",
				width: "200px",
			},
		},
		{
			dataField: "rank",
			text: "Rank",

			sort: true,
			headerStyle: {
				backgroundColor: "#c8e6c9",
				width: "200px",
			},
		},
		{
			dataField: "gradeLevel",
			text: "Grade Level",

			sort: true,
			headerStyle: {
				backgroundColor: "#c8e6c9",
				width: "200px",
			},
		},
		{
			dataField: "retirementDate",
			text: "Date of Retirement",

			sort: true,
			headerStyle: {
				backgroundColor: "#c8e6c9",
				width: "200px",
			},
		},
		{
			dataField: "ippisNum",
			text: "IPPIS Number",

			sort: true,
			headerStyle: {
				backgroundColor: "#c8e6c9",
				width: "200px",
			},
		},
		{
			dataField: "salaryStr",
			text: "Salary Structure",

			sort: true,
			headerStyle: {
				backgroundColor: "#c8e6c9",
				width: "200px",
			},
		},
		{
			dataField: "staffNum",
			text: "Staff Number",

			sort: true,
			headerStyle: {
				backgroundColor: "#c8e6c9",
				width: "200px",
			},
		},
		{
			dataField: "assumptionOfdutyDate",
			text: "Assumption of Duty",

			sort: true,
			headerStyle: {
				backgroundColor: "#c8e6c9",
				width: "200px",
			},
		},
		{
			dataField: "nok1",
			text: "NOK 1",

			sort: true,
			headerStyle: {
				backgroundColor: "#c8e6c9",
				width: "200px",
			},
		},
		{
			dataField: "nokAddress1",
			text: "NOK 1 Address",

			sort: true,
			headerStyle: {
				backgroundColor: "#c8e6c9",
				width: "200px",
			},
		},
		{
			dataField: "nokEmail1",
			text: "NOK 1 Email",

			sort: true,
			headerStyle: {
				backgroundColor: "#c8e6c9",
				width: "200px",
			},
		},
		{
			dataField: "relationship1",
			text: "NOK 1 relationship",

			sort: true,
			headerStyle: {
				backgroundColor: "#c8e6c9",
				width: "200px",
			},
		},
		{
			dataField: "nokPhone1",
			text: "NOK 1 Phone",

			sort: true,
			headerStyle: {
				backgroundColor: "#c8e6c9",
				width: "200px",
			},
		},
		{
			dataField: "nok2",
			text: "NOK 2",

			sort: true,
			headerStyle: {
				backgroundColor: "#c8e6c9",
				width: "200px",
			},
		},
		{
			dataField: "nokAddress2",
			text: "NOK 2 Address",

			sort: true,
			headerStyle: {
				backgroundColor: "#c8e6c9",
				width: "200px",
			},
		},
		{
			dataField: "nokEmail2",
			text: "NOK 2 Email",

			sort: true,
			headerStyle: {
				backgroundColor: "#c8e6c9",
				width: "200px",
			},
		},
		{
			dataField: "nokPhone2",
			text: "NOK 2 Phone",

			sort: true,
			headerStyle: {
				backgroundColor: "#c8e6c9",
				width: "200px",
			},
		},
		{
			dataField: "relationship2",
			text: "NOK 2 relationship",

			sort: true,
			headerStyle: {
				backgroundColor: "#c8e6c9",
				width: "200px",
			},
		},
		{
			dataField: "passport",
			text: "Passport",
			formatter: passport,
			sort: true,
			headerStyle: {
				backgroundColor: "#c8e6c9",
				width: "200px",
			},
		},
		{
			dataField: "signature",
			text: "Signature",
			formatter: signature,
			sort: true,
			headerStyle: {
				backgroundColor: "#c8e6c9",
				width: "200px",
			},
		},
	];

	const MyExportCSV = (props) => {
		const handleClick = () => {
			props.onExport();
		};
		return (
			<div style={{ textAlign: "right" }}>
				<Button variant='primary' size='lg' onClick={handleClick}>
					Export To CSV
				</Button>
			</div>
		);
	};
	return (
		<ContentWrapper>
			<div className='card'>
				<div className='card-header'>
					<h3 className='card-title'>Members Register</h3>
				</div>
				<section className='content pb-3'>
					<div className='card-body'>
						<div
							style={{
								width: "auto",
								height: "500px",
								overflow: "auto",
							}}>
							<ToolkitProvider
								stripped
								hover
								keyField='_id'
								columns={columns}
								data={data}
								exportCSV>
								{(props) => (
									<div>
										<MyExportCSV {...props.csvProps} />
										<hr />
										<BootstrapTable {...props.baseProps} />
									</div>
								)}
							</ToolkitProvider>
						</div>
					</div>
				</section>
			</div>
		</ContentWrapper>
	);
}

export default MemberRoster;
