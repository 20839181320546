import React from "react";
import MemberDashboard from "../../members/MemberDashboard";
import AdminDashboard from "../../admin/AdminDashboard";
import { useSelector } from "react-redux";
import Preloader from "../../utilities/Preloader";

function HomeDashboard(props) {
	const { profile } = useSelector((state) => state.userProfile);

	if (profile?.user?.accessLevel === 1) {
		return <MemberDashboard {...props} user={profile} />;
	} else if (profile?.accessLevel > 1) {
		return <AdminDashboard {...props} user={profile} />;
	} else
		return (
			<>
				<Preloader />
			</>
		);
}

export default HomeDashboard;
