import React from "react";

function GeneralSecInfo() {
  return (
    <>
      <div className="card card-widget widget-user-2">
        <div className="widget-user-header bg-warning">
          <div className="widget-user-image">
            <img
              className="img-circle elevation-2"
              src="img/team/secretary.jpg"
              alt="User Avatar"
            />
          </div>

          <h3 className="widget-user-username">JAMES O. ONYEABOR</h3>
          <h5 className="widget-user-desc">B.A, M.A, CIBN, ICOM, ERM</h5>
          <h5 className="widget-user-desc">General Secretary</h5>
        </div>
        <div className="card-footer p-0">
          <div className="card-header">
            <h3 className="card-title">
              <i className="fas fa-bullhorn"></i>
              Biography:
            </h3>
          </div>
          James O. Onyeabor had his secondary education at Ugwangue College
          Warri, Delta State and tertiary education at University of Nigeria,
          Nsukka where he graduated with BA. (Honours) 2007, Masters of Arts in
          Archaeology 2012 (Museum Option) Masters of Arts in Tourism 2020 with
          PhD (Enterprise Risk Management in Hospitality Industry) in view and a
          Charted Banker in 2016 with Central Bank of Nigeria. A Senior Curator
          in the department of Archaeology and Tourism University of Nigeria.
          His core interests are research/teaching in Museum education, Tourism
          Planning & development, Event Management and Enterprise Risk
          Management. He has publications to his credit. Married to Nneka and
          the marriage is blessed with Children.
          <div className="card-header">
            <h3 className="card-title">
              <i className="fas fa-bullhorn"></i>
              Membership of Professional Bodies:
            </h3>
          </div>
          Member, International Commission for Museum and Monument (ICOM).
          <br />
          Member, Enterprise Risk Management of Nigeria (ERM).
          <br />
          Member, Charted Institute of Bankers of Nigeria (CIBN).
          <br />
          Member, Hospitality and Tourism Management Association of Nigeria
          (HATMAN)
          <br />
          James O. Onyeabor is a worthy servant, who serves in various
          capacities: General Secretary, Lion Multi- Purpose Cooperative Society
          Nigeria Limited 2021 to date. Member Caretaker Committee, University
          of Nigeria General Enterprises Limited–UNGEL 2019/2020, Ag. Secretary
          Faculty of Arts Appraisal Committee 2017-2020, Ag.Secretary,
          Department of Archaeology and Tourism 2020-2021, Strategic Manager:
          Deje Shopping Enterprises, Head of Administration: Tourismlinks
          Consulting Services (Human Resources outsourcing and Development) 2015
          to date, Unit Head, Enterprise Risk Management University of Nigeria
          Nsukka Staff Microfinance Bank 2015-2017. Internal Auditor: Argungun
          Microfinance Bank 2008-2009, Customer Service Executive: Zecool Hotel
          Kaduna 2010-2012, Marketing Executive: AIICO Insurance Company Lagos
          2013, Marketing Executive: Diamond Bank PLC Katsina 2014.
        </div>
      </div>
    </>
  );
}

export default GeneralSecInfo;
