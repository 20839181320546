import React from "react";

function AssistantSecretaryInfo() {
  return (
    <>
      <div className="card card-widget widget-user-2">
        <div className="widget-user-header bg-warning">
          <div className="widget-user-image">
            <img
              className="img-circle elevation-2"
              src="img/team/assistant_sec.jpg"
              alt="User Avatar"
            />
          </div>

          <h3 className="widget-user-username">IKECHUKWU A. MOBOSI</h3>
          <h5 className="widget-user-desc">Ph.D, M.Sc, B.Sc</h5>
          <h5 className="widget-user-desc">
            Assistant Secretary General/Financial Strategist
          </h5>
        </div>
        <div className="card-footer p-0">
          <div className="card-header">
            <h3 className="card-title">
              <i className="fas fa-bullhorn"></i>
              Professional/Academic Qualifications:
            </h3>
          </div>
          Dr Ikechukwu Andrew MOBOSI is a lecturer in the Department of
          Economics, Faculty of the Social Sciences, University of Nigeria,
          Nsukka. He holds a PhD degree in the field of Health Economics, M.Sc
          Economics in the field of Public Finance and Fiscal Policy and B.Sc in
          Statistics/Economics, all from the University of Nigeria, Nsukka. He
          is a multidisciplinary person with core competence in Statistical Data
          Management and Analysis, Macroeconomics Modeling and Forecasting,
          Public Sector Management and Households Survey. He has high interest
          in International Relation Economics.
          <div className="card-header">
            <h3 className="card-title">
              <i className="fas fa-bullhorn"></i>
              Work Experience:
            </h3>
          </div>
          He is currently a lecturer in the Department of Economics, University
          of Nigeria, Nsukka; a Financial Adviser (FA) Agent with First Bank of
          Nigeria (FBN) Insurance (015 – 2016). He is a consultant to Qverse
          Nigerian Limited, designing E-learning Teaching Materials (including
          Audio and Video teaching) for different Universities in Nigeria. He
          has a long-standing experience in design and implementation of
          developmental projects having consulted for local and international
          organizations, carrying out several community interventions projects
          and also performed Monitoring and Evaluation function at Quality Life
          Organization (An NGO working on HIV/AIDS, OVC and Malaria projects).
          In 2010, he developed in a team, the database for the African
          Institute for Applied Economics (AIAE) now Africa Heritage Institute
          (AHI). He has been involved in several surveys such as a household
          survey programme with a State branch of the National Bureau of
          Statistics (NBS), 2019 Africa Heritage Institute (AHI) survey on
          Business Environment and Competitiveness across Nigerian States
          (BECANS IV) serving as the Bauchi State field officer. In 2020, he
          served as the Enugu State field officer for the Centre for the Study
          of the Economies of Africa (CSEA) executing the Political Economy of
          the Research on Improving Systems of Education (RISE) Nigeria Project.
          He has outstanding skills in drafting instruments for data collection,
          the use of Computer Applications and Statistical/Econometrics standard
          software, such as Microsoft Word, Excel, Access and PowerPoint, SPSS,
          STATA, EViews, Gretl and DevInfo. Dr Mobosi is a research fellow at
          African Heritage Institute, Enugu, CSEA, and Green Mobilization
          Initiative (an NGO based in Abuja) and he has several local and
          international articles published in reputable Journals with reasonable
          number of conferences to his credit. He is happily married with kids.
        </div>
      </div>
    </>
  );
}

export default AssistantSecretaryInfo;
