import React from "react";
import GuarantorPreview from "../forms/GuarantorPreview";
import { useSelector, useDispatch } from "react-redux";
import {
	acknowledgeLoanRequest,
	rejectLoanRequest,
	fetchLoanRequest,
} from "../../store/actions/adminActions";

import { clearNotifications } from "../../store/actions/notificationsActions";



import Swal from "sweetalert2";
import LoanTable from "./LoanTable";
import MyPagination from "../utilities/Pagination";
import Loader from "../utilities/Loader";

export default function LoanRequest() {
	const dispatch = useDispatch();

	const [currPage, setCurrPage] = React.useState(1);

	const { loanRequests: requests, isLoading, loanRequestPageNum: numberOfPages } = useSelector((state) => state.admin);

	const notification = useSelector((state) => state.notification);
	// const { numberOfPages } = useSelector((state) => state.posts);

	React.useEffect(() => {
		const successMessage = notification?.success?.message;
		const errorMessage = notification?.errors?.message;

		if (successMessage) {
			Swal.fire({
				icon: "success",
				text: successMessage,
				showConfirmButton: false,
				timer: 2000,
			});
		} else if (errorMessage) {
			Swal.fire({
				icon: "error",
				text: errorMessage,
				showConfirmButton: false,
				timer: 2000,
			});
		}
		dispatch(clearNotifications());
	}, [notification, dispatch]);

	React.useEffect(() => {
		dispatch(fetchLoanRequest(currPage || 1));
	}, [dispatch, currPage]);

	const [showGuarantor, setShowGuarantor] = React.useState();

	const handleDelete = (id) => {
		Swal.fire({
			title: "Are you sure you want to delete this user?",
			text: "This action cannot be undone.",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "Yes, delete",
			cancelButtonText: "Cancel",
		}).then((result) => {
			if (result.isConfirmed) {
				dispatch(rejectLoanRequest(id));
				// Swal.fire("Confirmed!", "The user has been confirmed.", "success");
			} else {
				Swal.fire("Cancelled", "The action was cancelled.", "info");
			}
		});
	};
	const handleConfirm = (trx) => {
		const guarantors = trx.guarantors;

		const message =
			guarantors.length > 0
				? "This action cannot be undone."
				: "This loan request is missing the required number of guarantors";
		Swal.fire({
			title: "Are you sure you want to confirm this loan?",
			text: message,
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "Yes, confirm",
			cancelButtonText: "Cancel",
		}).then((result) => {
			if (result.isConfirmed) {
				dispatch(acknowledgeLoanRequest({ transactionId: trx.transactionId }));
				// Swal.fire("Confirmed!", "The user has been confirmed.", "success");
			} else {
				Swal.fire("Cancelled", "The action was cancelled.", "info");
			}
		});
	};

	const showLoanPage = () => {
		setShowGuarantor(null);
	};

	const afterPageClicked = (page_num) => {
		setCurrPage(page_num);
		dispatch(fetchLoanRequest(page_num));
	};

	if (isLoading) return <Loader isLoading={isLoading} />

	return (
		<>
			{showGuarantor ? (
				<GuarantorPreview data={showGuarantor} showLoanPage={showLoanPage} />
			) : (
				<MyPagination currentPage={currPage}
					numberOfPages={numberOfPages}
					pageClicked={(ele) => {
						afterPageClicked(ele);
					}}>
					<LoanTable loanRequests={requests} setShowGuarantor={setShowGuarantor} handleConfirm={handleConfirm} handleDelete={handleDelete} />
				</MyPagination>

			)}
		</>
	);
}
