import React from "react";

function InfoBox(props) {
	return (
		<div className='col-md-3 col-sm-6 '>
			<div className='info-box'>
				<span className={`info-box-icon ${"bg-success"}`}>
					<i className={props.icon}></i>
				</span>

				<div className='info-box-content'>
					<span className='info-box-text text-uppercase'>{props.title}</span>
					<span className='info-box-number'>{`\u{020A6}` + props.number}</span>
				</div>
			</div>
		</div>
	);
}

export default InfoBox;
