import React from 'react'
import { Button, Card, Col, ListGroup, Modal, Row, Accordion, Badge } from 'react-bootstrap';


function LoanDetailModal({ show, onHide, loan, setShowContractModal, showGuarantor }) {


    if (!loan) return null;

    return (
        <Modal show={show} onHide={onHide} size="xl">
            <Modal.Header closeButton className="bg-success text-white">
                <Modal.Title>Loan Details for {loan.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={6}>
                        <Card className="mb-4 shadow-sm">
                            <Card.Header className="bg-light">
                                <h5 className="mb-0">Personal Information</h5>
                            </Card.Header>
                            <ListGroup variant="flush">
                                <ListGroup.Item><strong>Name:</strong> {loan.name}</ListGroup.Item>
                                <ListGroup.Item><strong>IPPIS Number:</strong> {loan.ippisNum}</ListGroup.Item>
                                <ListGroup.Item><strong>Staff Number:</strong> {loan.staffNum}</ListGroup.Item>
                                <ListGroup.Item><strong>Member ID:</strong> {loan.memberId}</ListGroup.Item>
                            </ListGroup>
                        </Card>

                        <Card className="mb-4 shadow-sm">
                            <Card.Header className="bg-light">
                                <h5 className="mb-0">Loan Information</h5>
                            </Card.Header>
                            <ListGroup variant="flush">
                                <ListGroup.Item><strong>Loan Type:</strong> <Badge bg="info">{loan.loanType}</Badge></ListGroup.Item>
                                <ListGroup.Item><strong>Loan Amount:</strong> {loan.amount}</ListGroup.Item>
                                <ListGroup.Item><strong>Duration:</strong> {loan.duration} months</ListGroup.Item>
                                <ListGroup.Item><strong>Interest Rate:</strong> {loan.interestRate}%</ListGroup.Item>
                                <ListGroup.Item><strong>Pro-rated Percentage Interest:</strong> {loan.proRata}</ListGroup.Item>
                            </ListGroup>
                        </Card>
                    </Col>

                    <Col md={6}>
                        <Card className="mb-4 shadow-sm">
                            <Card.Header className="bg-light">
                                <h5 className="mb-0">Financial Details</h5>
                            </Card.Header>
                            <ListGroup variant="flush">
                                <ListGroup.Item><strong>Interest Deducted at Source:</strong> {loan.deductedInterest}</ListGroup.Item>
                                <ListGroup.Item><strong>Handling Charge:</strong> {loan.handlingCharge}</ListGroup.Item>
                                <ListGroup.Item><strong>Amount Payable Per Month:</strong> {loan.payable}</ListGroup.Item>
                            </ListGroup>
                        </Card>

                        <Card className="mb-4 shadow-sm">
                            <Card.Header className="bg-light">
                                <h5 className="mb-0">Additional Information</h5>
                            </Card.Header>
                            <Card.Body>
                                <p><strong>Request Time:</strong> {loan.time}</p>
                                <Button variant="outline-primary" onClick={() => setShowContractModal(true)}>View Contract</Button>
                            </Card.Body>
                        </Card>

                        <Card className="mb-4 shadow-sm">
                            <Card.Header className="bg-light">
                                <h5 className="mb-0">Guarantor Information</h5>
                            </Card.Header>
                            <Card.Body>
                                <Accordion>
                                    {loan?.guarantors?.map((guarantor, index) => (
                                        <Card key={index}>
                                            <Accordion.Toggle as={Card.Header} eventKey={index.toString()}>
                                                Guarantor {index + 1}: {guarantor?.surname} {guarantor?.otherName}
                                            </Accordion.Toggle>
                                            <Accordion.Collapse eventKey={index.toString()}>
                                                <Card.Body>
                                                    <ListGroup variant="flush">
                                                        <ListGroup.Item><strong>Residential Address:</strong> {guarantor?.residentialAddress}</ListGroup.Item>
                                                        <ListGroup.Item><strong>Telephone:</strong> {guarantor?.telephone}</ListGroup.Item>
                                                        <ListGroup.Item><strong>Email:</strong> {guarantor?.email}</ListGroup.Item>
                                                        <ListGroup.Item><strong>Work Place:</strong> {guarantor?.workPlace}</ListGroup.Item>
                                                        <ListGroup.Item><strong>Next of Kin:</strong> {guarantor?.nok} ({guarantor?.nokPhone}, {guarantor?.nokEmail})</ListGroup.Item>
                                                    </ListGroup>
                                                    <Button variant="outline-primary" className="mt-3" onClick={() => showGuarantor(true)}>View Guarantor Form</Button>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    ))}
                                </Accordion>

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
};




export default LoanDetailModal
