import React from "react";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { logout } from "../../../../store/actions/authActions";
import { NavLink } from "react-bootstrap";
import { Link } from "react-router-dom";
import decode from "jwt-decode";

function AppHeader() {
	const dispatch = useDispatch();
	const [user, setUser] = React.useState(
		JSON.parse(localStorage.getItem("profile"))
	);

	const history = useHistory();
	const pathArray = window.location.pathname.split("/");
	const secondLoc = pathArray[2];

	React.useEffect(() => {
		const token = user?.token;

		if (token) {
			const decodedToken = decode(token);

			if (decodedToken.exp * 1000 < new Date().getTime())
				dispatch(logout(history));
		}
		if (!token) dispatch(logout(history));
		setUser(JSON.parse(localStorage.getItem("profile")));
	}, [history, user?.token, dispatch]);

	// const redirect = () => {
	// 	return <Redirect to='/' />;
	// };
	return (
		<nav
			className='main-header fixed-top navbar navbar-expand navbar-dark'
			style={{ backgroundColor: "#28a745" }}>
			{/* <!-- Left navbar links --> */}
			<ul className='navbar-nav'>
				<li className='nav-item'>
					<a
						className='nav-link'
						data-widget='pushmenu'
						href='#testhome'
						role='button'>
						<i className='fas fa-bars'></i>
					</a>
				</li>
			</ul>

			{/* <!-- Right navbar links --> */}
			<ul className='navbar-nav ml-auto'>
				{/* <!-- Navbar Search --> */}
				<li className='nav-item'>
					<a
						className='nav-link'
						data-widget='navbar-search'
						href='#testsearch'
						role='button'>
						<i className='fas fa-search'></i>
					</a>
					<div className='navbar-search-block'>
						<form className='form-inline'>
							<div className='input-group input-group-sm'>
								<input
									className='form-control form-control-navbar'
									type='search'
									placeholder='Search'
									aria-label='Search'
								/>
								<div className='input-group-append'>
									<button className='btn btn-navbar' type='submit'>
										<i className='fas fa-search'></i>
									</button>
									<button
										className='btn btn-navbar'
										type='button'
										data-widget='navbar-search'>
										<i className='fas fa-times'></i>
									</button>
								</div>
							</div>
						</form>
					</div>
				</li>
				{secondLoc === "commodity" && (
					<li>
						<Link
							className='nav-link btn btn-outline-warning mx-3'
							style={{ color: "white" }}
							to='/dashboard/cart'>
							<i className='fas fa-cart-plus'></i>
							{"  "} Check out
						</Link>
					</li>
				)}
				{/* <!-- Messages Dropdown Menu --> */}

				{/* <!-- Notifications Dropdown Menu --> */}
				<li className='nav-item dropdown'>
					<a className='nav-link' data-toggle='dropdown' href='#test'>
						<i className='far fa-bell'></i>
						<span className='badge badge-warning navbar-badge'>0</span>
					</a>
					<div className='dropdown-menu dropdown-menu-lg dropdown-menu-right'>
						<span className='dropdown-item dropdown-header'>
							No Notifications
						</span>
						{/* <div className="dropdown-divider"></div>
            <a href="#test" className="dropdown-item">
              <i className="fas fa-envelope mr-2"></i> 4 new messages
              <span className="float-right text-muted text-sm">3 mins</span>
            </a>
            <div className="dropdown-divider"></div>
            <a href="#test" className="dropdown-item">
              <i className="fas fa-users mr-2"></i> 8 friend requests
              <span className="float-right text-muted text-sm">12 hours</span>
            </a>
            <div className="dropdown-divider"></div>
            <a href="#test" className="dropdown-item">
              <i className="fas fa-file mr-2"></i> 3 new reports
              <span className="float-right text-muted text-sm">2 days</span>
            </a>
            <div className="dropdown-divider"></div>
            <a href="#test" className="dropdown-item dropdown-footer">
              See All Notifications
            </a> */}
					</div>
				</li>
				<li className='nav-item'>
					<NavLink className='nav-link' data-widget='fullscreen' role='button'>
						<i className='fas fa-expand-arrows-alt'></i>
					</NavLink>
				</li>

				<li className='nav-item'>
					<a
						className='nav-link'
						data-widget='control-sidebar'
						data-slide='true'
						href='#test'
						role='button'>
						<i className='fas fa-th-large'></i>
					</a>
				</li>
				<li className='nav-item'>
					<button
						style={{ color: "white" }}
						className='nav-link btn btn-primary float-right'
						onClick={() => {
							dispatch(logout(history));
						}}>
						logout
					</button>
				</li>
			</ul>
		</nav>
	);
}

export default AppHeader;
