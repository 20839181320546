import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { submitGuarantor } from "../store/actions/userActions";
import { ToastContainer, toast } from "react-toastify";
import swal from "sweetalert2";
import { clearNotifications } from "../store/actions/notificationsActions";
import GuarantorContract from "./GuarantorContract";

export default function GuarantorPreview(props) {
	const [submitting, setIsubmitting] = React.useState(false);
	const [disable, setDisable] = React.useState(false);
	const notification = useSelector((state) => state.notification);
	const dispatch = useDispatch();
	const history = useHistory();
	const { state } = history?.location;

	React.useEffect(() => {
		const { success } = notification;
		if (success.message) {
			const { message } = notification.success;

			swal
				.fire({
					title: "Success",
					text: message,
					icon: "success",
					showDenyButton: true,
					confirmButtonText: "Print",
					denyButtonText: `Go back`,
				})
				.then((value) => {
					dispatch(clearNotifications());

					setIsubmitting(false);
					setDisable(true);

					if (value.isConfirmed) {
						window.print();
					} else if (value.isDenied) {
						history.goBack();
					}
				});
		}
	}, [notification, dispatch, history]);

	const handlesubmit = () => {
		setIsubmitting(true);
		dispatch(submitGuarantor(state));

	};
	return (
		<div className='m-3 mb-5'>
			<div className='col-m-12 col-s-12'>
				<div className=' no-print callout callout-info'>
					<h5>
						<i className='fas fa-info'></i> Note:
					</h5>
					please click submit at the end of the page to complete the process.
					This page has been enhanced for printing. you may wish to print this
					form.
				</div>

				<div className='invoice p-3 mb-3 col-12'>
					<GuarantorContract {...props} />
				</div>
				{/* <!-- this row will not appear when printing --> */}
				<div className='row no-print'>
					<div className='col-12 m-3'>
						<Link to='/guarantor-form'>
							<button
								type='button'
								className='btn btn-primary '
								style={{ marginRight: "5px" }}>
								<i className='fas fa-edit'></i> Edit Form
							</button>
						</Link>
						<button
							type='button'
							className='btn btn-primary float-right'
							style={{ marginRight: "5px" }}
							onClick={handlesubmit}
							disabled={disable}>
							{submitting ? (
								<span>submitting...</span>
							) : (
								<span>
									<i className='fas fa-download'></i> Submit Form
								</span>
							)}
						</button>
					</div>
				</div>
			</div>
			<ToastContainer position='top-center' />
		</div>
	);
}
