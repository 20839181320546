import React, { useState } from "react";
import SelectDisplay from "../../utilities/SelectDisplay";
import ContentWrapper from "../../utilities/ContentWrapper";
import AccountBalances from "./AccountBalances";
import { useSelector, useDispatch } from "react-redux";
import {
	fetchBalanceSummary,
	getBalances,
	loansTransactionHistory,
	savingsTransactionHistory,
} from "../../../store/actions/userActions";
import SavingLedger from "./SavingLedger";
import LoansLedger from "./LoansLedger";
import Preloader from "../../utilities/Preloader";

export function Balances(props) {
	const [isSelected, setIsSelected] = useState("Savings");

	const { savings, loans, isLoading, balance, profile } = useSelector(
		(state) => state.userProfile
	);
	const dispatch = useDispatch();

	const toggleSelected = (e) => {
		setIsSelected(e.target.value);
	};
	const { user } = profile;

	React.useEffect(() => {
		// dispatch(loansTransactionHistory());
		// dispatch(savingsTransactionHistory());
		// dispatch(fetchBalanceSummary());
		dispatch(getBalances(user?.memberId));
	}, [dispatch, user]);

	// const userid = "LMCS/10/2021/000037";

	// const memberId = user?.memberId?.toLowerCase();

	// const userSavingData = savings?.filter((saving) => {
	// 	return saving?.memberId?.toLowerCase() === memberId;
	// });

	// const userLoanData = loans?.filter((loan) => {
	// 	return loan?.memberId.toLowerCase() === memberId;
	// });

	// const userBalances = balance.filter((bal) => {
	// 	return bal.memberId.toLowerCase() === memberId;
	// });

	const userSavingData = balance.savingLedger || [];
	const userLoanData = balance.loanLedger || [];
	const userBalances = balance.summary || [];

	return (
		<ContentWrapper>
			{isLoading ? (
				<Preloader />
			) : (
				<>
					<AccountBalances balances={userBalances} />
					<SelectDisplay
						isSelected={isSelected}
						toggleSelected={toggleSelected}
					/>

					{isSelected === "Savings" ? (
						<SavingLedger userSavingData={userSavingData} />
					) : (
						<LoansLedger userLoanData={userLoanData} />
					)}
				</>
			)}
		</ContentWrapper>
	);
}

export default Balances;
