import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchReferrals } from "../../store/actions/adminActions";

function ViewReferralTable() {
	const { referrals } = useSelector((state) => state.admin);
	const dispatch = useDispatch();

	const sortedRef = referrals?.referrals?.sort((a, b) => {
		return b.referedUsers?.length - a.referedUsers?.length;
	});

	React.useEffect(() => {
		dispatch(fetchReferrals());
	}, [dispatch]);

	return (
		<div className='card'>
			<div className='card-body'>
				{sortedRef?.map((ref, i) => {
					return (
						<div id='accordion' key={ref._id + i}>
							<div className='card card-primary card-outline'>
								<div className='card-header '>
									<h4
										className='card-title w-100 h-100  row justify-content-center align-items-center'
										style={{ margin: "auto", maxHeight: "40px" }}>
										<div
											className='d-flex col-12  align-items-center '
											style={{ margin: "auto", maxHeight: "40px" }}>
											<a
												className='d-flex w-80  align-items-center'
												data-toggle='collapse'
												href={`#collapse${i + 1} `}
												style={{
													width: "80%",
												}}>
												<p> {i + 1}.</p>
												<img
													className='img-circle elevation-2'
													src={ref?.userId?.passport}
													alt='User Avatar'
													style={{
														width: "40px",
														height: "40px",
														margin: " 0 15px 0",
													}}
												/>
												{ref.userId?.name}{" "}
											</a>
											<div className='widget-user-image float-right'>
												referrals : {ref.referedUsers?.length}
											</div>
										</div>
									</h4>
								</div>
								<div
									id={`collapse${i + 1}`}
									className='collapse'
									data-parent='#accordion'>
									<div className='card-body'>
										<dl className='row'>
											{ref.referedUsers?.map((ref, i) => {
												return (
													<>
														<dt className='col-sm-2' key={i}>
															{i + 1}
															{"."}
														</dt>
														<dd className='col-sm-10'> {ref?.name}</dd>
													</>
												);
											})}
										</dl>
									</div>
								</div>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
}

export default ViewReferralTable;
