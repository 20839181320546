import React, { useState } from "react";
import ContentWrapper from "../utilities/ContentWrapper";
import { Form, Alert } from "react-bootstrap";
import { sendEmail } from "../../store/actions/adminActions";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";

function ComposeMessage() {
  const [sendType, setSendType] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});

  const dispatch = useDispatch();

  const findError = () => {
    const newErrors = {};
    if (!subject || subject === "")
      newErrors.subject = "subject cannot be blank!";
    if (!message || message === "")
      newErrors.message = "message cannot be blank!";
    if (!sendType || sendType === "")
      newErrors.sendType = "SELECT MESSAGE TYPE";

    return newErrors;
  };

  const handleSelect = (e) => {
    setSendType(e.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    const newErrors = findError();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      dispatch(
        sendEmail({
          subject,
          message,
          sendType,
        })
      );

      setSubject("");
      setMessage("");
      toast.success("message sent");
    }
  };
  return (
    <ContentWrapper>
      <div className="col-md-12">
        <div className="card card-success card-outline">
          <div className="card-header">
            <h3 className="card-title">Compose New Message</h3>
          </div>
          <div className="card-body">
            <button
              className="btn btn-outline-success dropdown-toggle mb-5"
              type="button"
              id="btnDropdownDemo"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {" "}
              <i className="far fa-envelope"></i>{" "}
              {sendType === "" ? "select message type" : sendType}
            </button>

            <div className="dropdown-menu" aria-labelledby="btnDropdownDemo">
              <button
                className="dropdown-item btn btn-success"
                value="sms"
                onClick={handleSelect}
              >
                SMS
              </button>
              <button
                className="dropdown-item btn btn-success"
                value="email"
                onClick={handleSelect}
              >
                EMAIL
              </button>
              <button
                className="dropdown-item btn btn-success"
                value="both"
                onClick={handleSelect}
              >
                SMS AND EMAIL
              </button>
            </div>

            {errors.sendType && (
              <Alert variant="danger">{errors?.sendType} </Alert>
            )}

            <Form.Group>
              <Form.Control
                type="text"
                className="form-control"
                placeholder="Subject:"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                isInvalid={!!errors?.subject}
              />
            </Form.Group>
            <Form.Control.Feedback type="invalid">
              {errors?.subject}
            </Form.Control.Feedback>
            <Form.Group>
              <Form.Control
                id="compose-textarea"
                as="textarea"
                className="form-control"
                style={{ height: "300px" }}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                isInvalid={!!errors?.message}
              />
            </Form.Group>
            <Form.Control.Feedback type="invalid">
              {errors?.message}
            </Form.Control.Feedback>
            <div className="form-group">
              {/* <div className="btn btn-default btn-file">
                <i className="fas fa-paperclip"></i> Attachment
                <input type="file" name="attachment" />
              </div> */}
              {/* <p className="help-block">Max. 32MB</p> */}
            </div>
          </div>
          <div className="card-footer">
            <div className="float-right">
              <button
                type="submit"
                className="btn btn-primary"
                onClick={handleSubmit}
              >
                <i className="far fa-envelope"></i> Send
              </button>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer position="bottom-center" />
    </ContentWrapper>
  );
}

export default ComposeMessage;
