import React from "react";
import Preloader from "../utilities/Preloader";

function DecreaseAdminPreview(props) {
  const { user } = props;
  const { decreaseSavingsRequest } = props.user;

  const salarystruc = user?.employmentDetails?.salaryStructure;

  return user && user ? (
    <div style={{ fontSize: "1.7rem" }}>
      <div style={{ textAlign: "center" }}>
        <h4>
          <strong>
            LION MULTI-PURPOSE COOPERATIVE SOCIETY NIGERIA LIMITED
          </strong>
        </h4>
        <b>
          Suite 116, Continuing Education Centre (CEC), UNGEL Guest House,
          University of Nigeria Main Campus, Nsukka.
        </b>
        <br />
        <br />
        <br />
        <h4>
          <b>INSTRUCTION TO DECREASE SAVINGS OR SHARE CAPITAL</b>
        </h4>
        (Joining the society for the first time, fill this form)
        <br />
        <br />
      </div>

      <span style={{ textAlign: "left" }}>
        The president, Lion Multi-Purpose Cooperative Society Nig. Ltd
        <br />
        Sir/Ma <br /> Please accept this as my instruction to debit my salary
        account with University of Nigeria, Nsukka/Other Institution (please
        specify) ..
        {user.employmentDetails?.organisationName !== "University of Nigeria"
          ? user.employmentDetails.organisationName
          : null}
        <dl className="row">
          <dt className="col-sm-6">Name</dt>
          <dd className="col-sm-6">{user?.name}</dd>
          <dt className="col-sm-6">LMCS Membership ID</dt>
          <dd className="col-sm-6">{user?.memberId}</dd>
          <dt className="col-sm-6">Faculty</dt>
          <dd className="col-sm-6">{user?.employmentDetails.faculty}</dd>
          <dt className="col-sm-6">Department</dt>
          <dd className="col-sm-6">{user?.employmentDetails.department}</dd>
          <dt className="col-sm-6">Salary Structure</dt>
          <dd className="col-sm-6">{salarystruc.toUpperCase()}</dd>
        </dl>
        <br />
        <b>1. Share Capital</b> <br />
        Please decrease my share capital amount from NGN{" "}
        <b>{user.initialSavingsRequest?.shareCapitalAmount}</b> to
        <b>{decreaseSavingsRequest?.shareCapitalAmount}</b> every month.
        <br />
        <b>2. Ordinary Savings</b> <br />
        Please decrease my share capital amount from NGN{" "}
        <b>{user.initialSavingsRequest?.ordinarySavingsAmount}</b> to
        {} <b>{decreaseSavingsRequest?.ordinarySavingsAmount}</b> every month.
        <br />
        {decreaseSavingsRequest?.christmasSavingsAmount ? (
          <>
            <b>3. Christmas Savings</b> <br />
            Please decrease my share capital amount from{" "}
            <b>NGN {user.initialSavingsRequest?.christmasSavingsAmount}</b> to
            <b>{decreaseSavingsRequest?.christmasSavingsAmount}</b> every month.
          </>
        ) : null}
        <br />
        {decreaseSavingsRequest?.educationSavingsAmount ? (
          <>
            <b>4. Education Savings</b> <br />
            Please decrease my share capital amount from NGN{" "}
            <b>{user.initialSavingsRequest?.educationSavingsAmount}</b>to{" "}
            {decreaseSavingsRequest?.educationSavingsAmount} every month.
          </>
        ) : null}
        <br />
        {decreaseSavingsRequest?.retirementSavingsAmount ? (
          <>
            {" "}
            <b>5. Retirement Savings</b> <br />
            Please decrease my share capital amount from NGN{" "}
            <b>{user.initialSavingsRequest?.retirementSavingsAmount}</b> to{" "}
            {decreaseSavingsRequest?.retirementSavingsAmount} every month.
          </>
        ) : null}
      </span>
    </div>
  ) : (
    <Preloader />
  );
}

export default DecreaseAdminPreview;
