import React from "react";
import ContentWrapper from "../../utilities/ContentWrapper";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loanRequest } from "../../../store/actions/userActions";
import { ToastContainer, toast } from "react-toastify";
import { clearNotifications } from "../../../store/actions/notificationsActions";
import LoanContract from "./LoanContract";
import DebitAuthForm from "./DebitAuthForm";

function ContractPreview(props) {
	const [disabled, setDisabled] = React.useState(false);
	const notification = useSelector((state) => state.notification);
	const dispatch = useDispatch();
	const history = useHistory();

	React.useEffect(() => {
		const { success } = notification;
		if (success.message) {
			const { message } = notification.success;
			toast.success(message);

			setDisabled(true);
			return dispatch(clearNotifications());
		}
	}, [notification, dispatch, history]);

	function backToForm() {
		history.push({
			pathname: "/dashboard/loan-request",
			state: props?.location?.state,
		});
	}

	const handlesubmit = () => {
		dispatch(loanRequest(props.location.state));
		const timer = setTimeout(() => {
			window.print();
		}, 10000);
		return () => clearTimeout(timer);
	};
	return (
		<ContentWrapper>
			<div className='col-12'>
				<div className=' no-print callout callout-info'>
					<h5>
						<i className='fas fa-info'></i> Note:
					</h5>
					please click submit at the end of the page to complete the process.
					This page has been enhanced for printing. you may wish to print this
					form.
				</div>

				<div className='invoice p-3 mb-3 col-12'>
					<LoanContract {...props} />
				</div>
				<br />
				<div className='invoice p-3 mb-3 col-12'>
					<DebitAuthForm {...props} />
				</div>
				{/* <!-- this row will not appear when printing --> */}
				<div className='row no-print'>
					<div className='col-12'>
						<button
							type='button'
							className='btn btn-primary '
							style={{ marginRight: "5px" }}
							onClick={backToForm}>
							<i className='fas fa-edit'></i> Edit Form
						</button>

						<button
							type='button'
							className='btn btn-primary float-right'
							style={{ marginRight: "5px" }}
							disabled={disabled}
							onClick={handlesubmit}>
							<i className='fas fa-download'></i> Submit Form
						</button>
					</div>
				</div>
			</div>
			<ToastContainer position='top-center' />
		</ContentWrapper>
	);
}

export default ContractPreview;
