import React, { useState, useEffect } from "react";
import Paper from "./Paper";
import { useDispatch, useSelector } from "react-redux";
import { initialSavingsRequest } from "../../../store/actions/userActions";
import { clearNotifications } from "../../../store/actions/notificationsActions";
import Swal from "sweetalert2";
import { Spinner } from "react-bootstrap";

function InstructionPreview(props) {
	const notification = useSelector((state) => state.notification);
	const [submitting, setSubmitting] = useState(false);
	const [disabled, setDisabled] = useState(false);
	const [showPrint, setShowPrint] = useState(false);
	const dispatch = useDispatch();

	useEffect(() => {
		const successMessage = notification?.success?.message;
		const errorMessage = notification?.errors?.message;

		if (successMessage) {
			setSubmitting(false);
			Swal.fire({
				icon: "success",
				text: successMessage,
				showConfirmButton: true,
			}).then(() => {
				setDisabled(true);
				setShowPrint(true);
				// window.print();
			});
		} else if (errorMessage) {
			Swal.fire({
				icon: "error",
				text: errorMessage,
				showConfirmButton: true,
			});
		}
		dispatch(clearNotifications());
	}, [notification, dispatch]);

	const handlesubmit = () => {
		setSubmitting(true);
		dispatch(initialSavingsRequest(props.state));
	};

	return (
		<div className=''>
			<div className=' no-print callout callout-info'>
				<h5>
					<i className='fas fa-info'></i> Note:
				</h5>
				please click submit at the end of the page to complete the process. This
				page has been enhanced for printing. you may wish to print this form.
			</div>

			<div className='invoice p-3 mb-3 col-12'>
				<Paper {...props} />
			</div>
			{/* <!-- this row will not appear when printing --> */}
			<div className='row no-print mb-4'>
				<div className='col-12'>
					<button
						onClick={() => props.setPreview(false)}
						type='button'
						className='btn btn-primary mx-auto'>
						<i className='fas fa-edit'></i> Edit Form
					</button>

					{!showPrint ? (
						<button
							type='button'
							className='btn btn-primary float-right'
							style={{ marginRight: "5px" }}
							onClick={handlesubmit}
							disabled={disabled}>
							{submitting ? (
								<Spinner
									as='span'
									animation='border'
									size='sm'
									role='status'
									aria-hidden='true'
								/>
							) : (
								<i className='fas fa-download'></i>
							)}
							Submit Form
						</button>
					) : (
						<button
							type='button'
							className='btn btn-primary float-right'
							style={{ marginRight: "5px" }}
							onClick={() => window.print()}>
							Print
						</button>
					)}
				</div>
			</div>
		</div>
	);
}

export default InstructionPreview;
