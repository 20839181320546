const initState = {
	profile: {},
	savings: [],
	loans: [],
	balance: [],
	myLoans: [],
	refLink: "",
	isLoading: true,
};

const userProfileReducer = (state = initState, action) => {
	switch (action.type) {
		case "FETCH_USER":
			return {
				...state,
				profile: action?.payload,
			};
		case "AUTH_FAILURE":
			return {
				...state,
			};
		case "GET_LOANS":
			return {
				...state,
				loans: action?.payload,
			};
		case "SUMMARY_BALANCE":
			return {
				...state,
				balance: action?.payload,
			};
		case "GET_BALANCES":
			return {
				...state,
				balance: action?.payload,
				isLoading: false,
			};

		case "GET_SAVINGS":
			return {
				...state,
				savings: action?.payload,
			};

		case "GET_REF_LINK":
			return {
				...state,
				refLink: action.payload,
			};
		case "FETCH_LOANEE":
			return {
				...state,
				loanee: action.payload,
			};
		case "FETCH_MY_LOAN":
			return {
				...state,
				myLoans: action.payload,
			};
		case "START_LOADING":
			return {
				...state,
				isLoading: true,
			};
		case "STOP_LOADING":
			return {
				...state,
				isLoading: false,
			};

		default:
			return state;
	}
};
export default userProfileReducer;
