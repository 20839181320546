import React from "react";

function LegalAdviserInfo() {
  return (
    <>
      <div className="card card-widget widget-user-2">
        <div className="widget-user-header bg-warning">
          <div className="widget-user-image">
            <img
              className="img-circle elevation-2"
              src="img/team/legal_adviser.jpg"
              alt="User Avatar"
            />
          </div>

          <h3 className="widget-user-username">
            BARR. (MRS.) NDILI R. OBIAGELI
          </h3>
          <h5 className="widget-user-desc">LLB, BL, MICMC</h5>
          <h5 className="widget-user-desc">Legal Adviser</h5>
        </div>
        <div className="card-footer p-0">
          Barr. (Mrs.) Ndili Rosemary Obiageli was born in Enugu State in the
          70’s to the family of Late James Ngene and Late Ezinne Rose Ngene of
          Amafor Ugbawka in Nkanu East Local Governement Area of Enugu State.
          <br />
          <br />
          She started her early education at Ogui Nike Primary School, Enugu. At
          the completion of her primary education, she proceeded to the then
          City Girls Secondary School, Enugu before completing her secondary
          education at the prestigious Queens’ School, Enugu in 1994. <br />{" "}
          After making high flying success with her secondary education, she got
          admission in 1995 to study law at Enugu State University of Science
          and Technology (ESUT) after which she proceeded to the Nigerian Law
          School, Abuja campus for a one year programme and thereafter, she was
          called to the Nigerian Bar in year 2003. While waiting for her call-up
          for the mandatory one year National Youth Service Corp (NYSC), she was
          engaged in private practice with Goddy Nwachukwu Chambers, Enugu.{" "}
          <br />
          She was called-up for the National Youth Service Corp (NYSC) in
          2003/2004 batch ‘B’, where she was engaged as a lecturer at Atiku
          Abubakar College of Legal and Islamic Studies, Nguru, Yobe State for
          her primary assignment. During this period, she taught family Law and
          Civil Procedure. After her service year, she got employment as a legal
          Officer with Enugu State Ministry of Justice, Enugu where she rose to
          the position of Chief Legal Officer. She also had a few years private
          practice experience with Femi Jolaosho Chambers, Abeokuta Ogun State.
          <br />
          In her quest for knowledge and diversity she took a fresh challenge by
          enrolling for a Post Graduate Diploma in Business Administration
          (Marketing) from the prestigious University of Nigeria, Enugu campus.
          In furtherance to her pursuit for professionalism, she registered with
          Institute of Chartered Mediators and Conciliators, Nigeria and was
          inducted as a Member of the Institute. She is a Chartered Mediator and
          also one of the Neutrals at the Enugu State Multi- Door Courthouse,
          Enugu.
          <br />
          In the course of her career, she has attended so many conferences,
          trainings and seminars that have prepared her technically,
          professionally and mentally to handle the position of Legal Adviser to
          Lion Cooperative Society Nig. Limited. Rosemary is happily married and
          blessed with children.
        </div>
      </div>
    </>
  );
}

export default LegalAdviserInfo;
