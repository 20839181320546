import React from "react";
import { Form, Col, Row } from "react-bootstrap";

function SavingsForm({
	handleChange,
	error,
	formData,
	handleSubmit,
}) {
	return (
		<div className='card card-success'>
			<div className='card-header'>
				<h2 className='card-title'>
					Debit Instructions for savings and share capital
				</h2>
			</div>
			<Form className=' invoice p-3 mb-3 col-12'>
				<div className=' card-header'>
					<h4 className='card-title' style={{ color: "red" }}>
						Share Capital *
					</h4>
				</div>
				<div className='callout callout-info p-2'>
					<h6>
						<i className='fas fa-info'></i> Note:
					</h6>
					share Capital contribution is compulsory & not less than N5,000/month,
					contributable for at least 20 months. Minimum share capital after
					20months must be up to N100,000 and the upper limit for share capital
					is N5,000,000
				</div>
				<div className='card-body'>
					<Row>
						<Col lg={6} md={6} sm={6}>
							<Form.Group controlId='formdepartment0'>
								<Form.Label>Debit Amount</Form.Label>
								<Form.Control
									type='number'
									name='shareCapitalAmount'
									placeholder='enter amount to be debited from salary account every month'
									defaultValue={formData?.shareCapitalAmount}
									onChange={handleChange}
									isInvalid={!!error.shareCapitalAmount}
								/>
								<Form.Control.Feedback type='invalid'>
									{error.shareCapitalAmount}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
						<Col lg={6} md={6} sm={6}>
							<Form.Group controlId='formdepartment1'>
								<Form.Label>Period</Form.Label>
								<Form.Control
									type='number'
									name='shareCapitalMonths'
									placeholder=' months account should be debited'
									defaultValue={formData?.shareCapitalMonths}
									onChange={handleChange}
									isInvalid={!!error.shareCapitalMonths}
								/>
								<Form.Control.Feedback type='invalid'>
									{error.shareCapitalMonths}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
				</div>

				<div className='card-header'>
					<h4 className='card-title' style={{ color: "red" }}>
						Ordinary Savings*
					</h4>
				</div>
				<div className=' callout callout-info p-2'>
					<h6>
						<i className='fas fa-info'></i> Note:
					</h6>
					amount contributable as ordinary savings is compulsory & not less than
					N5,000/month with no upper limit.
				</div>
				<div className='card-body'>
					<Row>
						<Col lg={6} md={6} sm={6}>
							<Form.Group controlId='formdepartment3'>
								<Form.Label>Debit Amount</Form.Label>
								<Form.Control
									type='number'
									name='ordinarySavingsAmount'
									placeholder='enter amount to be debited from salary account every month'
									onChange={handleChange}
									isInvalid={!!error.ordinarySavingsAmount}
									defaultValue={formData?.ordinarySavingsAmount}
								/>
								<Form.Control.Feedback type='invalid'>
									{error.ordinarySavingsAmount}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
						<Col lg={6} md={6} sm={6}>
							<Form.Group controlId='formdepartment4'>
								<Form.Label>Period</Form.Label>
								<Form.Control
									type='number'
									name='ordinarySavingsMonths'
									placeholder=' months account should be debited (OPTIONAL)'
									defaultValue={formData?.ordinarySavingsMonths}
									onChange={handleChange}
								/>
								{/* <Form.Control.Feedback type="invalid">
            {error.ordinarySavingsMonths}
          </Form.Control.Feedback> */}
							</Form.Group>
						</Col>
					</Row>
				</div>
				<div className=' callout callout-info p-2'>
					<h6>
						<i className='fas fa-info'></i> Note:
					</h6>
					THE FOLLOWING ARE OPTIONAL SAVINGS
				</div>
				<div className='card-header'>
					<h4 className='card-title' style={{ color: "green" }}>
						Christmas Savings
					</h4>
				</div>

				<div className='card-body'>
					<Row>
						<Col lg={6} md={6} sm={6}>
							<Form.Group controlId='formdepartment5'>
								<Form.Label>Debit Amount</Form.Label>
								<Form.Control
									type='number'
									name='christmasSavingsAmount'
									placeholder='enter amount to be debited from salary account every month'
									onChange={handleChange}
									defaultValue={formData?.christmasSavingsAmount}
								// isInvalid={!!error}
								/>
								<Form.Control.Feedback type='invalid'>
									{/* {error} */}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
						<Col lg={6} md={6} sm={6}>
							<Form.Group controlId='formdepartment6'>
								<Form.Label>Period</Form.Label>
								<Form.Control
									type='number'
									name='christmasSavingsMonths'
									placeholder=' months account should be debited'
									onChange={handleChange}
									defaultValue={formData?.christmasSavingsMonths}
								// isInvalid={!!error.fullName}
								/>
								<Form.Control.Feedback type='invalid'>
									{/* {error.fullName} */}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
				</div>
				<div className='card-header'>
					<h4 className='card-title' style={{ color: "green" }}>
						Educational Savings
					</h4>
				</div>

				<div className='card-body'>
					<Row>
						<Col lg={6} md={6} sm={6}>
							<Form.Group controlId='formdepartment7'>
								<Form.Label>Debit Amount</Form.Label>
								<Form.Control
									type='number'
									name='educationSavingsAmount'
									placeholder='enter amount to be debited from salary account every month'
									onChange={handleChange}
									defaultValue={formData?.educationSavingsAmount}
								// isInvalid={!!error}
								/>
								<Form.Control.Feedback type='invalid'>
									{/* {error} */}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
						<Col lg={6} md={6} sm={6}>
							<Form.Group controlId='formdepartment8'>
								<Form.Label>Period</Form.Label>
								<Form.Control
									type='number'
									name='educationSavingsMonths'
									placeholder=' months account should be debited'
									onChange={handleChange}
									defaultValue={formData?.educationSavingsMonths}
								// isInvalid={!!error.fullName}
								/>
								<Form.Control.Feedback type='invalid'>
									{/* {error.fullName} */}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
				</div>
				<div className='card-header'>
					<h4 className='card-title' style={{ color: "green" }}>
						Retirement Savings
					</h4>
				</div>

				<div className='card-body'>
					<Row>
						<Col lg={6} md={6} sm={6}>
							<Form.Group controlId='formdepartment9'>
								<Form.Label>Debit Amount</Form.Label>
								<Form.Control
									type='number'
									name='retirementSavingsAmount'
									placeholder='enter amount to be debited from salary account every month'
									onChange={handleChange}
									defaultValue={formData?.retirementSavingsAmount}
								// isInvalid={!!error}
								/>
								<Form.Control.Feedback type='invalid'>
									{/* {error} */}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
						<Col lg={6} md={6} sm={6}>
							<Form.Group controlId='formdepartment10'>
								<Form.Label>Period</Form.Label>
								<Form.Control
									type='number'
									name='retirementSavingsMonths'
									placeholder=' months account should be debited'
									defaultValue={formData?.retirementSavingsMonths}
									onChange={handleChange}
								// isInvalid={!!error.fullName}
								/>
								<Form.Control.Feedback type='invalid'>
									{/* {error.fullName} */}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
				</div>
				<div className='card-header'>
					<h4 className='card-title' style={{ color: "green" }}>
						Land Development Savings
					</h4>
				</div>

				<div className='card-body'>
					<Row>
						<Col lg={6} md={6} sm={6}>
							<Form.Group controlId='formdepartment9'>
								<Form.Label>Debit Amount</Form.Label>
								<Form.Control
									type='number'
									name='landDevSavingsAmount'
									placeholder='enter amount to be debited from salary account every month'
									onChange={handleChange}
									defaultValue={formData?.landDevSavingsAmount}
								// isInvalid={!!error}
								/>
								<Form.Control.Feedback type='invalid'>
									{/* {error} */}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
						<Col lg={6} md={6} sm={6}>
							<Form.Group controlId='formdepartment10'>
								<Form.Label>Period</Form.Label>
								<Form.Control
									type='number'
									name='landDevSavingsMonths'
									placeholder=' months account should be debited'
									defaultValue={formData?.landDevSavingsMonths}
									onChange={handleChange}
								// isInvalid={!!error.fullName}
								/>
								<Form.Control.Feedback type='invalid'>
									{/* {error.fullName} */}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
				</div>
				<div className='card-footer'>
					<button
						type='submit'
						onClick={handleSubmit}
						className='btn btn-primary float-right'>
						Preview Instructions
					</button>
				</div>
			</Form>
		</div>
	);
}

export default SavingsForm;
