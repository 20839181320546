import React from "react";
import ContentWrapper from "../../utilities/ContentWrapper";
import CustomModal from "../../utilities/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import { getFinReport } from "../../../store/actions/adminActions";
import PreviewPDF from "../../admin/byLaws/PreviewPDF";

function FinancialReport() {
	const [showModal, setShowModal] = React.useState(false);
	const [currDoc, setCurrDoc] = React.useState();

	const dispatch = useDispatch();
	const finReports = useSelector((state) => state.admin.finReports);

	function handleHideModal() {
		setShowModal(false);
	}

	function handleShowModal(fileLoc) {
		setShowModal(true);
		setCurrDoc(fileLoc);
	}

	React.useEffect(() => {
		dispatch(getFinReport());
	}, [dispatch]);

	return (
		<ContentWrapper>
			<div className='card' id='cart'>
				<div className='row'>
					<div className='col-md-12 cart'>
						<div className='title d-flex'>
							<div className='col'>
								<h4>
									<b>AUDITED FINANCIAL REPORT</b>
								</h4>
							</div>
						</div>
					</div>

					<div className='col-md-12 cart'>
						{finReports.length > 0 ? (
							finReports.map((doc) => (
								<div className='row border-top border-bottom p-2' key={doc._id}>
									<div className='row main align-items-center'>
										<div className='col'>
											<div className='row text-muted'>
												{doc.year.toUpperCase()}
											</div>
										</div>
										<div className='col'>
											{" "}
											<div className='row'>{doc.description}</div>
										</div>
										<div className='col' role='button'>
											<span
												className='close text-success'
												onClick={() => handleShowModal(doc)}>
												preview
											</span>
										</div>
									</div>
								</div>
							))
						) : (
							<h4>No report have been uploaded</h4>
						)}
					</div>
				</div>
			</div>
			<CustomModal
				hide={handleHideModal}
				show={showModal}
				size={"xl"}
				title={"PREVIEW DOC"}>
				<PreviewPDF file={currDoc?.finDocFile.file_location} />
			</CustomModal>
		</ContentWrapper>
	);
}

export default FinancialReport;
