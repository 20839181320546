import React from "react";
import { Form, Col, Row } from "react-bootstrap";
import PreviewPDF from "./PreviewPDF";
import { useDispatch } from "react-redux";
import { addLegalDoc } from "../../../store/actions/adminActions";

function AddByeLawForm({ loading, setLoading }) {
	const [inputValue, setInputValue] = React.useState({
		docName: "",
		description: "",
		file: null,
		legalDocFile: "",
	});

	const dispatch = useDispatch();

	const handleChange = (e) => {
		if (e?.target?.files) {
			const file = e?.target?.files[0];
			let reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onloadend = (e) => {
				setInputValue({
					...inputValue,
					file: e.target.result,
					legalDocFile: file,
				});
				return;
			};
		}
		setInputValue({
			...inputValue,
			[e.target.name]: e.target.value,
		});
	};

	function handleSubmit(e) {
		e.preventDefault();

		setLoading(true);

		const formData = new FormData();
		formData.append("docName", inputValue.docName);
		formData.append("description", inputValue.description);
		formData.append("legalDocFile", inputValue.legalDocFile);

		dispatch(addLegalDoc(formData));
	}

	return (
		<div className='card' id='cart'>
			<Form className='col-md-12' onSubmit={handleSubmit}>
				<div className='row'>
					<Form.Group className='mb-3' as={Col} controlId='formdepartment'>
						<Form.Label>Legal Doc Name</Form.Label>
						<Form.Control
							type='text'
							name='docName'
							value={inputValue.docName}
							onChange={handleChange}
							required
						/>
					</Form.Group>
				</div>
				<Row>
					<Form.Group className='mb-3' as={Col} controlId='formdepartment'>
						<Form.Label>LEGAL DOC DESCRIPTION</Form.Label>
						<Form.Control
							as='textarea'
							name='description'
							placeholder='optional'
							onChange={handleChange}
						/>
					</Form.Group>
				</Row>
				<Row>
					<Form.Group
						xs={12}
						md={8}
						as={Col}
						controlId='formFile'
						className='mb-3'>
						<Form.Label>Select File *PDF only</Form.Label>
						<Form.Control
							type='file'
							accept='application/pdf'
							onChange={handleChange}
						/>
					</Form.Group>
				</Row>

				<Row>
					{inputValue.file && <PreviewPDF file={inputValue.file} preview />}
				</Row>
				<button type='submit' className='check-out-btn '>
					UPLOAD DOC{" "}
					{loading && (
						<div className='ml-2 p-2 spinner-grow' role='status'>
							<span className='sr-only'>Loading...</span>
						</div>
					)}
				</button>
			</Form>
		</div>
	);
}

export default AddByeLawForm;
