import React from "react";
import { fetchMyLoans } from "../../../store/actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import ContentWrapper from "../../utilities/ContentWrapper";
import LoanStatusPreview from "./LoanStatusPreview";

function LoanStatus() {
	const dispatch = useDispatch();
	const [show, setShow] = React.useState(false);
	const [target, setTarget] = React.useState();

	const myLoans = useSelector((state) => state.userProfile.myLoans);

	React.useEffect(() => {
		dispatch(fetchMyLoans());
	}, [dispatch]);

	const handleClose = () => setShow(false);
	const handleShow = (e, value) => {
		setTarget(value);
		setShow(true);
	};

	return (
		<ContentWrapper>
			<div className='card'>
				<div className='row'>
					<div className='col cart'>
						<div className='title'>
							<div className='col'>
								<h4>
									<b>MY LOANS</b>
								</h4>
							</div>
						</div>
					</div>
				</div>
				<table>
					<thead>
						<tr>
							<th>#</th>
							<th>LOAN ID</th>
							<th>LOAN TYPE</th>
							<th>status</th>
							<th>more info</th>
						</tr>
					</thead>
					{myLoans.map((loan, i) => {
						return (
							<tbody key={loan.transactionId}>
								<tr>
									<td>{i + 1}</td>
									<td>{loan.transactionId}</td>
									<td>{loan.loanType}</td>
									<td>{loan.acknowledged}</td>
									<td>
										<span>
											<button
												className='btn btn-info'
												onClick={(e) => handleShow(e, loan)}>
												more info
											</button>
										</span>
									</td>
								</tr>
							</tbody>
						);
					})}
				</table>
			</div>
			<LoanStatusPreview show={show} handleClose={handleClose} data={target} />
		</ContentWrapper>
	);
}

export default LoanStatus;
