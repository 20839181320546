import React from "react";

function VisionStatement() {
	return (
		<div id='vision' className=' text-center bg-light '>
			<div className='container mb-3 '>
				<div className='section-title'>
					<h2>Vision Statement</h2>
				</div>
				<div className='row justify-content-md-center'>
					<div className='col-md-8' style={{ marginBottom: "10px" }}>
						<p>
							{" "}
							To be the largest and best Cooperative Society brand in Nigeria.
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default VisionStatement;
