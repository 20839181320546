import React from "react";
import ContentHeader from "../views/dashboard/contents/ContentHeader";
import { Alert } from "react-bootstrap";
import moment from "moment";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

function MemberDashboard(props) {
	const { profile } = useSelector((state) => state.userProfile);

	const [greeting, setGreeting] = React.useState("");

	function generateGreetings() {
		var currentHour = moment().format("HH");

		if (currentHour >= 3 && currentHour < 12) {
			return "Good Morning";
		} else if (currentHour >= 12 && currentHour < 16) {
			return "Good Afternoon";
		} else if (currentHour >= 16 && currentHour < 20) {
			return "Good Evening";
		} else if (currentHour >= 20 && currentHour < 3) {
			return "Good Night";
		} else {
			return "Hello";
		}
	}

	React.useEffect(() => {
		setGreeting(generateGreetings());
	}, []);

	return (
		<div className=' content-wrapper '>
			<ContentHeader title={profile?.user.name} />
			<div className='content'>
				<div className='container-fluid'>
					<Alert variant='success'>
						<Alert.Heading>
							{greeting} {profile?.user?.name}
						</Alert.Heading>
						{!profile?.user?.nok && (
							<>
								<h6>
									please update your profile by entering your next of kin's
									information, click the button below to get started
								</h6>
								<hr />
								<Link className='btn btn-dark' to='/dashboard/profile'>
									update profile
								</Link>
							</>
						)}
						{!profile?.user?.bankDetails && (
							<>
								<h6>
									Please add your bank details to your profile, click the button
									below to get started
								</h6>
								<hr />
								<Link className='btn btn-dark' to='/dashboard/profile'>
									Add bank Detail
								</Link>
							</>
						)}
					</Alert>
				</div>
			</div>
		</div>
	);
}

export default MemberDashboard;
