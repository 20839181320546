import React from "react";
import "./product.css";

function Product(props) {
  const { productName, description, imgUrl, price, status, productId } = props;

  let upperindex = price.length - 1;
  let basePrice = price[0];
  let upperPrice = price[upperindex];

  return (
    <div
      className="product-card col-md-4 col-12"
      onClick={() => {
        props.history.push({
          pathname: `/dashboard/commodity/${productId}`,
          state: { productId },
        });
      }}
    >
      <img
        src={!imgUrl ? "../../img/product-image-placeholder.jpg" : imgUrl}
        alt="product"
        className="product-image"
      />

      <div className="short-des">
        <h5 id="product-name">{productName}</h5>
        <p id="description">{description}</p>

        <p>{`\u{020A6}${basePrice} - \u{020A6}${upperPrice}`} </p>
        <span className="status">
          {status === "available" ? (
            <button id="product" className="available">
              Available for Purchase{" "}
            </button>
          ) : (
            <button id="product" className="not-available">
              Not Availabe{" "}
            </button>
          )}
        </span>
      </div>
    </div>
  );
}

export default Product;
