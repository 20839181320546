import React from "react";
import { Form, Button } from "react-bootstrap";
import InputField from "../utilities/InputField";
import { useDispatch, useSelector } from "react-redux";

import { updateBankDetails } from "../../store/actions/adminActions";
import { clearNotifications } from "../../store/actions/notificationsActions";
import Swal from "sweetalert2";

function EditBankInfo(props) {
	const { data } = props;
	const [error, setError] = React.useState({});
	const notification = useSelector((state) => state.notification);
	const [inputValue, setInputValue] = React.useState({
		bankName: data?.bankName,
		accountNumber: data?.accountNumber,
		accountName: data?.accountName,
	});

	const dispatch = useDispatch();
	const handleChange = (e) => {
		setInputValue({ ...inputValue, [e.target.name]: e.target.value });
		if (error[e.target.name]) {
			setError({ ...error, [e.target.name]: null });
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		const id = props.userId;

		dispatch(updateBankDetails(id, inputValue));
	};

	React.useEffect(() => {
		const successMessage = notification?.success?.message;
		const errorMessage = notification?.errors?.message;

		if (successMessage) {
			Swal.fire({
				icon: "success",
				text: successMessage,
				showConfirmButton: false,
				timer: 3000,
			}).then(() => props.hide());
		} else if (errorMessage) {
			Swal.fire({
				icon: "error",
				text: errorMessage,
				showConfirmButton: true,
			});
		}

		dispatch(clearNotifications());
	}, [dispatch, notification, props]);
	return (
		<Form onSubmit={handleSubmit}>
			<InputField
				label='Account Name'
				type='text'
				name='accountName'
				value={inputValue?.accountName}
				onChange={handleChange}
				placeholder='enter account name'
				error={error.accountName}
				className='col-lg-12'
				require
			/>
			<InputField
				label='Account Number'
				type='text'
				name='accountNumber'
				value={inputValue?.accountNumber}
				onChange={handleChange}
				placeholder='enter account number'
				error={error.accountNumber}
				className='col-lg-12'
				require
			/>

			<InputField
				label='Bank Name'
				type='text'
				name='bankName'
				value={inputValue?.bankName}
				onChange={handleChange}
				placeholder='enter bank name'
				error={error.bankName}
				className='col-lg-12'
				require
			/>
			<Button className='btn-block' type='submit'>
				submit
			</Button>
		</Form>
	);
}

export default EditBankInfo;
