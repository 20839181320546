import React from "react";

function LoanItemHeader() {
	return (
		<div className='transaction-card ' style={{ margin: "auto" }}>
			<div className='details' style={{ minHeight: "40px" }}>
				<div className='narration'>
					{" "}
					<div>
						<b>NARRATION</b>
					</div>
				</div>
				<div className='row balances loan'>
					<div className='col'>
						<b> LOANS AND DEDUCTION </b>
						<div className='row'>
							<div className='col'>Debit</div> <div className='col'>Credit</div>
							<div className='col'>Balance</div>
						</div>
					</div>
				</div>
				{/* <div className="row balances">
          <div className="col">
            <b> INTEREST </b>
            <div className="row">
              <div className="col">Debit</div> <div className="col">Credit</div>
              <div className="col">Balance</div>
            </div>
          </div>
        </div> */}
			</div>
		</div>
	);
}

export default LoanItemHeader;
