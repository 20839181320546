import React, { useEffect } from "react";
import ContentWrapper from "../../utilities/ContentWrapper";
import { Button, ButtonGroup, Form, Row, ToggleButton } from "react-bootstrap";
import LoanSummary from "./LoanSummary";
import { useSelector } from "react-redux";
import { pioneers } from "../../../data/pioneers";

function LoanApplication(props) {

	const user = useSelector((state) => state.userProfile.profile.user);
	const [isPioneer, setIsPioneer] = React.useState();

	useEffect(() => {
		setIsPioneer(pioneers.includes(user?.memberId));
	}, [user?.memberId]);

	console.log(props.location.state);

	React.useEffect(() => {
		if (props.location.state) {
			setInputValue({
				...props.location.state,
			});
		}
	}, [props.location.state]);

	const [inputValue, setInputValue] = React.useState({
		amount: "",
		loanType: "",
		duration: "1",
		bankName: "",
		accountName: "",
		accountNumber: "",
		shareCapital: "",
		ordinarySavings: "",
		retirementSavings: "",
		christmasSavings: "",
		educationSavings: "",
		loanBalance: "",
		netSalary: "",
		gross_Salary: "",
		payable: 0,
		proRata: 0,
	});
	const [interestRate, setInterestRate] = React.useState("-");
	const [totalMonths, setTotalMonth] = React.useState(0);
	const [isIndebted, setIsindepted] = React.useState("false");
	const [done, setDone] = React.useState(false);
	const [error, setError] = React.useState({});

	const handleChange = (e) => {
		setInputValue({ ...inputValue, [e.target.name]: e.target.value });
	};

	const handleSelect = (e) => {
		setInputValue({ ...inputValue, [e.target.name]: e.target.value });
	};

	React.useEffect(() => {
		if (inputValue.loanType === "Normal Loan(1 year)") {
			if (isPioneer) {
				setInterestRate(8);
			} else {
				setInterestRate(11);
			}
			setTotalMonth(12);
		}
		if (inputValue.loanType === "Normal Loan(2 year)") {
			if (isPioneer) {
				setInterestRate(12);
			} else {
				setInterestRate(15);
			}
			setTotalMonth(24);
		}
		if (inputValue.loanType === "Emergency Loan") {
			if (isPioneer) {
				setInterestRate(15);
			} else {
				setInterestRate(19);
			}
			setTotalMonth(12);
		}
		if (inputValue.loanType === "Emergency Loan(2 year)") {
			setInterestRate(26);
			setTotalMonth(24);
		}
		if (
			inputValue.loanType === "Housing project Loan" ||
			inputValue.loanType === "Land Loan" ||
			inputValue.loanType === "Car Loan" ||
			inputValue.loanType === "Motorcycle Loan" ||
			inputValue.loanType === "Tricycle Loan"
		) {
			if (isPioneer) {
				setInterestRate(12);
			} else {
				setInterestRate(16);
			}
			setTotalMonth(36);
		}
		if (inputValue.loanType === "Commodity Purchase Loan") {
			setInterestRate(5);
			setTotalMonth(3);
		}
		if (
			inputValue.loanType === "Electronic Purchase Loan" ||
			inputValue.loanType === "Stationary Purchase Loan" ||
			inputValue.loanType === "Office Equipment Purchase Loan"
		) {
			setInterestRate(7);
			setTotalMonth(12);
		}
	}, [inputValue.loanType, isPioneer]);

	const calculateProRata = (duration, interestRate, totalMonths) => {
		return (interestRate / totalMonths) * Number(duration);
	};

	const calculateAmountRepayable = (amount, duration) => {
		// let serviceCharge = (0.5 / 100) * Number(amount);
		// let amountCharge = (interestRate / 100) * Number(amount);

		return Number(amount) / Number(duration);
	};

	const goBack = () => {
		setDone(false);
	};

	const findError = () => {
		const {
			amount,
			loanType,
			duration,
			bankName,
			accountName,
			accountNumber,
			shareCapital,
			ordinarySavings,
			loanBalance,
			netSalary,
			gross_Salary,
		} = inputValue;
		const newErrors = {};
		if (!amount || amount === "") newErrors.amount = "amount cannot be blank";
		if (!loanType || loanType === "")
			newErrors.loanType = "loanType cannot be blank";
		if (!duration || duration === "") {
			newErrors.duration = "duration cannot be blank";
		} else if (loanType === "Normal Loan(1 year)" && duration > 12) {
			newErrors.duration =
				"duration for selected loan type must be less than 13 months";
		} else if (loanType === "Normal Loan(2 year)" && duration < 12) {
			newErrors.duration =
				"duration for selected loan type must be atleast 13 months";
		} else if (loanType === "Emergency Loan" && duration > 12) {
			newErrors.duration =
				"duration for selected loan type must be less than 13 months";
		} else if (loanType === "Commodity Purchase Loan" && duration > 3) {
			newErrors.duration =
				"duration for selected loan type must be less than 4 months";
		} else if (
			(loanType === "Electronic Purchase Loan" ||
				loanType === "Stationary Purchase Loan" ||
				loanType === "Office Equipment Purchase Loan") &&
			duration > 12
		) {
			newErrors.duration =
				"duration for selected loan type must be less than 13 months";
		}

		if (!bankName || bankName === "")
			newErrors.bankName = "bankName cannot be blank";
		if (!accountName || accountName === "")
			newErrors.accountName = "accountName cannot be blank";
		if (!accountNumber || accountNumber === "")
			newErrors.accountNumber = "accountNumber cannot be blank";
		if (!shareCapital || shareCapital === "")
			newErrors.shareCapital = "shareCapital cannot be blank";
		if (!ordinarySavings || ordinarySavings === "")
			newErrors.ordinarySavings = "ordinarySavings cannot be blank";
		if (!netSalary || netSalary === "")
			newErrors.netSalary = "net salary cannot be blank";
		if (!gross_Salary || gross_Salary === "")
			newErrors.gross_Salary = "gross salary cannot be blank";
		if (isIndebted === "true") {
			if (!loanBalance || loanBalance === "")
				newErrors.loanBalance = "loanBalance cannot be blank";
		}

		return newErrors;
	};
	const handleFinished = (e) => {
		e.preventDefault();
		const newError = findError();
		if (Object.keys(newError).length > 0) {
			setError(newError);
		} else {
			let interest = calculateProRata(
				inputValue.duration,
				interestRate,
				totalMonths
			);
			let amountPayable = calculateAmountRepayable(
				inputValue.amount,
				inputValue.duration
			);
			inputValue.proRata = interest.toFixed(2);
			inputValue.payable = amountPayable.toFixed(2);
			inputValue.isIndebted = isIndebted;
			inputValue.interestRate = interestRate;
			inputValue.totalMonths = totalMonths;
			setDone(true);
		}
	};
	const numberRange = (startval, range) => {
		return new Array(range).fill().map((d, i) => i + startval);
	};

	function handleClearForm() {
		setInputValue({
			amount: "",
			loanType: "",
			duration: "1",
			bankName: "",
			accountName: "",
			accountNumber: "",
			shareCapital: "",
			ordinarySavings: "",
			retirementSavings: "",
			christmasSavings: "",
			educationSavings: "",
			landDevelopmentSavings: "",
			loanBalance: "",
			netSalary: "",
			gross_Salary: "",
			payable: 0,
			proRata: 0,
		});
	}

	return (
		<ContentWrapper>
			<div className='card' id='cart'>
				<div className='row'>
					{!done ? (
						<div className='col-md-12 cart'>
							<div className='title'>
								<div className='row'>
									<div className='col'>
										<h3 className='card-title'>
											<b>Loan Contract Form</b>
										</h3>
									</div>

									<Form>
										<Row>
											<Form.Group
												controlId='formdepartment1'
												className='p-2 col-md-6'>
												<Form.Label>Loan Amount</Form.Label>
												<Form.Control
													type='number'
													name='amount'
													placeholder='enter the loan amount'
													value={inputValue.amount}
													onChange={handleChange}
													isInvalid={!!error.amount}
												/>
												<Form.Control.Feedback type='invalid'>
													{error.amount}
												</Form.Control.Feedback>
											</Form.Group>
											<Form.Group
												controlId='formdepartment0'
												className='p-2 col-md-6'>
												<Form.Label>Loan Type</Form.Label>
												<Form.Control
													as='select'
													name='loanType'
													value={inputValue.loanType}
													onChange={handleSelect}
													isInvalid={!!error.loanType}>
													<option className='text-muted' value=''>
														{" "}
														select loan type
													</option>
													<option
														value='Normal Loan(1 year)'
														className='text-muted'>
														1 year normal loan{" "}
													</option>
													<option value='Normal Loan(2 year)'>
														2 year normal loan
													</option>
													<option value='Emergency Loan'>
														1 year Emergency Loan
													</option>
													<option value='Emergency Loan(2 year)'>
														2 year Emergency Loan
													</option>
													<option value='Housing project Loan'>
														Housing project Loan
													</option>
													<option value='Land Loan'>Land Loan</option>
													<option value='Car Loan'>Car Loan</option>
													<option value='Motorcycle Loan'>
														Motorcycle Loan
													</option>
													<option value='Tricycle Loan'>Tricycle Loan</option>
													<option value='Commodity Purchase Loan'>
														Commodity Purchase Loan
													</option>
													<option value='Electronic Purchase Loan'>
														Electronics Purchase Loan
													</option>
													<option value='Land Development Loan'>
														Land Development Loan
													</option>
													<option value='Stationary Purchase Loan'>
														Stationery Purchase Loan
													</option>
													<option value='Office Equipment Purchase Loan'>
														Office Equipment Purchase Loan
													</option>
												</Form.Control>
												<Form.Control.Feedback type='invalid'>
													{error.loanType}
												</Form.Control.Feedback>
											</Form.Group>

											<Form.Group controlId='formdepartment1' className='p-2'>
												<Form.Label>Loan Duration(Months) </Form.Label>
												<Form.Control
													as='select'
													name='duration'
													placeholder='enter the number of months'
													value={inputValue.duration}
													onChange={handleChange}
													isInvalid={!!error.duration}>
													{numberRange(1, 36).map((num) => {
														return (
															<option
																className='text-muted'
																value={num}
																key={num}>
																{num}
															</option>
														);
													})}
												</Form.Control>

												<Form.Control.Feedback type='invalid'>
													{error.duration}
												</Form.Control.Feedback>
											</Form.Group>
										</Row>
										<ButtonGroup>
											<Form.Label className='m-3'>
												Are you indebted to LMCS?{" "}
											</Form.Label>

											<ToggleButton
												type='radio'
												name='isIndepted'
												value={"true"}
												checked={isIndebted === "true"}
												onChange={(e) => setIsindepted(e.currentTarget.value)}>
												Yes{" "}
											</ToggleButton>
											<ToggleButton
												type='radio'
												name='isIndepted'
												value={"false"}
												checked={isIndebted === "false"}
												onChange={(e) => setIsindepted(e.currentTarget.value)}>
												No{" "}
											</ToggleButton>
										</ButtonGroup>

										{isIndebted === "true" ? (
											<Form.Group
												controlId='formdepartment1'
												className='p-2 col-md-6'>
												<Form.Label>Current Loan Balance</Form.Label>
												<Form.Control
													type='text'
													name='loanBalance'
													placeholder='enter current loan balance'
													value={inputValue.loanBalance}
													onChange={handleChange}
													isInvalid={!!error.loanBalance}
												/>
												<Form.Control.Feedback type='invalid'>
													{error.loanBalance}
												</Form.Control.Feedback>
											</Form.Group>
										) : null}

										<Row>
											<Form.Group
												controlId='formdepartment1'
												className='p-2 col-md-6'>
												<Form.Label>
													Monthly Gross Salary(Compulsory)
												</Form.Label>
												<Form.Control
													type='text'
													name='gross_Salary'
													placeholder='enter monthly gross salary'
													value={inputValue.gross_Salary}
													onChange={handleChange}
													isInvalid={!!error.gross_Salary}
												/>
												<Form.Control.Feedback type='invalid'>
													{error.gross_Salary}
												</Form.Control.Feedback>
											</Form.Group>
											<Form.Group
												controlId='formdepartment1'
												className='p-2 col-md-6'>
												<Form.Label>Monthly Net Salary(Compulsory)</Form.Label>
												<Form.Control
													type='text'
													name='netSalary'
													placeholder='enter monthly net salary'
													value={inputValue.netSalary}
													onChange={handleChange}
													isInvalid={!!error.netSalary}
												/>
												<Form.Control.Feedback type='invalid'>
													{error.netSalary}
												</Form.Control.Feedback>
											</Form.Group>
										</Row>
										<Row>
											<Form.Group
												controlId='formdepartment1'
												className='p-2 col-md-6'>
												<Form.Label>
													Share Capital Balance(Compulsory)
												</Form.Label>
												<Form.Control
													type='text'
													name='shareCapital'
													placeholder='enter current value of your share capital balance'
													value={inputValue.shareCapital}
													onChange={handleChange}
													isInvalid={!!error.shareCapital}
												/>
												<Form.Control.Feedback type='invalid'>
													{error.shareCapital}
												</Form.Control.Feedback>
											</Form.Group>
											<Form.Group
												controlId='formdepartment1'
												className='p-2 col-md-6'>
												<Form.Label>
													Ordinary Savings Balance(Compulsory)
												</Form.Label>
												<Form.Control
													type='text'
													name='ordinarySavings'
													placeholder='enter current value of your ordinary savings balance'
													value={inputValue.ordinarySavings}
													onChange={handleChange}
													isInvalid={!!error.ordinarySavings}
												/>
												<Form.Control.Feedback type='invalid'>
													{error.ordinarySavings}
												</Form.Control.Feedback>
											</Form.Group>
										</Row>
										<Row>
											<Form.Group
												controlId='formdepartment1'
												className='p-2 col-md-6'>
												<Form.Label>
													Retirement Savings Balance(Optional)
												</Form.Label>
												<Form.Control
													type='text'
													name='retirementSavings'
													placeholder='enter current value of your retirement savings balance'
													value={inputValue.retirementSavings}
													onChange={handleChange}
												//   isInvalid={!!error.shareCapitalMonths}
												/>
												{/* <Form.Control.Feedback type="invalid">
                {error.shareCapitalMonths}
              </Form.Control.Feedback> */}
											</Form.Group>
											<Form.Group
												controlId='formdepartment1'
												className='p-2 col-md-6'>
												<Form.Label>
													Christmas Savings Balance (Optional)
												</Form.Label>
												<Form.Control
													type='text'
													name='christmasSavings'
													placeholder='enter current value of your christmas savings balance'
													value={inputValue.christmasSavings}
													onChange={handleChange}
												//   isInvalid={!!error.shareCapitalMonths}
												/>
												{/* <Form.Control.Feedback type="invalid">
                {error.shareCapitalMonths}
              </Form.Control.Feedback> */}
											</Form.Group>
										</Row>
										<Row>
											<Form.Group
												controlId='formdepartment1'
												className='p-2 col-md-6'>
												<Form.Label>
													Education Savings Balance (Optional)
												</Form.Label>
												<Form.Control
													type='text'
													name='educationSavings'
													placeholder='enter current value of your education savings balance'
													value={inputValue.educationSavings}
													onChange={handleChange}
												//   isInvalid={!!error.shareCapitalMonths}
												/>
												{/* <Form.Control.Feedback type="invalid">
                {error.shareCapitalMonths}
              </Form.Control.Feedback> */}
											</Form.Group>
											<Form.Group
												controlId='formdepartment1'
												className='p-2 col-md-6'>
												<Form.Label>
													Land Development Savings (Optional)
												</Form.Label>
												<Form.Control
													type='text'
													name='landDevelopementSavings'
													placeholder='enter current value of your land development savings balance'
													value={inputValue.landDevelopmentSavings}
													onChange={handleChange}
												//   isInvalid={!!error.shareCapitalMonths}
												/>
												{/* <Form.Control.Feedback type="invalid">
                {error.shareCapitalMonths}
              </Form.Control.Feedback> */}
											</Form.Group>
										</Row>
										<Row>
											<Form.Group
												controlId='formdepartment1'
												className='p-2 col-md-6'>
												<Form.Label>Bank Name</Form.Label>
												<Form.Control
													type='text'
													name='bankName'
													placeholder='enter bank name'
													defaultValue={inputValue.bankName}
													onChange={handleChange}
													isInvalid={!!error.bankName}
												/>
												<Form.Control.Feedback type='invalid'>
													{error.bankName}
												</Form.Control.Feedback>
											</Form.Group>
											<Form.Group
												controlId='formdepartment1'
												className='p-2 col-md-6'>
												<Form.Label>Account Name</Form.Label>
												<Form.Control
													type='text'
													name='accountName'
													placeholder='enter account name'
													value={inputValue.accountName}
													onChange={handleChange}
													isInvalid={!!error.accountName}
												/>
												<Form.Control.Feedback type='invalid'>
													{error.accountName}
												</Form.Control.Feedback>
											</Form.Group>
										</Row>
										<Form.Group
											controlId='formdepartment1'
											className='p-2 col-md-6'>
											<Form.Label>Account Number</Form.Label>
											<Form.Control
												type='text'
												name='accountNumber'
												placeholder='enter account number'
												value={inputValue.accountNumber}
												onChange={handleChange}
												isInvalid={!!error.accountNumber}
											/>
											<Form.Control.Feedback type='invalid'>
												{error.accountNumber}
											</Form.Control.Feedback>
										</Form.Group>
										<Button onClick={handleFinished}>Finished</Button>
										<Button
											variant='danger'
											className='ml-2'
											onClick={handleClearForm}>
											Clear
										</Button>
									</Form>
								</div>
							</div>
						</div>
					) : (
						<LoanSummary
							interestRate={interestRate}
							value={inputValue}
							goBack={goBack}
						/>
					)}
				</div>
			</div>
		</ContentWrapper>
	);
}

export default LoanApplication;
