import React from "react";
import { Form, Row, Button } from "react-bootstrap";
import InputField from "../../utilities/InputField";
import { useDispatch, useSelector } from "react-redux";
import { editprofile } from "../../../store/actions/userActions";
import { clearNotifications } from "../../../store/actions/notificationsActions";
import Swal from "sweetalert2";

function EditProfile(props) {
	const [error, setError] = React.useState({});
	const userProfile = useSelector((state) => state.userProfile);
	const { user } = userProfile?.profile ?? {};

	const initialUser = {
		nok1: user?.nok?.nok1 || "",
		nokAddress1: user?.nok?.nokAddress1 || "",
		nokEmail1: user?.nok?.nokEmail1 || "",
		nokPhone1: user?.nok?.nokPhone1 || "",
		relationship1: user?.nok?.relationship1 || "",
		nok2: user?.nok?.nok2 || "",
		nokAddress2: user?.nok?.nokAddress2 || "",
		nokEmail2: user?.nok?.nokEmail2 || "",
		nokPhone2: user?.nok?.nokPhone2 || "",
		relationship2: user?.nok?.relationship2 || "",
	};
	const [inputValue, setInputValue] = React.useState(initialUser);

	const dispatch = useDispatch();
	const handleChange = (e) => {
		setInputValue({ ...inputValue, [e.target.name]: e.target.value });
		if (error[e.target.name]) {
			setError({ ...error, [e.target.name]: null });
		}
	};

	const notification = useSelector((state) => state.notification);

	const { hide } = props;

	React.useEffect(() => {
		const successMessage = notification?.success?.message;
		if (successMessage) {
			Swal.fire({
				icon: "success",
				text: successMessage,
				showConfirmButton: false,
				timer: 1000,
			}).then(() => {
				dispatch(clearNotifications());
				hide();
				window.location.reload();
			});
		}
	}, [dispatch, notification, hide]);

	const findErrors = () => {
		const {
			nok1,
			nokAddress1,
			nokEmail1,
			nokPhone1,
			relationship1,
			nok2,
			nokAddress2,
			nokEmail2,
			nokPhone2,
			relationship2,
		} = inputValue;
		const newErrors = {};
		if (!nok1 || nok1 === "") {
			newErrors.nok1 = "field cannot be blank!";
		}
		if (!nokAddress1 || nokAddress1 === "") {
			newErrors.nokAddress1 = "field cannot be blank!";
		}
		if (!nokEmail1 || nokEmail1 === "") {
			newErrors.nokEmail1 = "field cannot be blank!";
		}
		if (!nokPhone1 || nokPhone1 === "") {
			newErrors.nokPhone1 = "field cannot be blank!";
		}
		if (!nok2 || nok2 === "") {
			newErrors.nok2 = "field cannot be blank!";
		}
		if (!relationship1 || relationship1 === "") {
			newErrors.relationship1 = "field cannot be blank!";
		}
		if (!relationship2 || relationship2 === "") {
			newErrors.relationship2 = "field cannot be blank!";
		}
		if (!nokAddress2 || nokAddress2 === "") {
			newErrors.nokAddress2 = "field cannot be blank!";
		}
		if (!nokEmail2 || nokEmail2 === "") {
			newErrors.nokEmail2 = "field cannot be blank!";
		}
		if (!nokPhone2 || nokPhone2 === "") {
			newErrors.nokPhone2 = "field cannot be blank!";
		}
		return newErrors;
	};
	const handleSubmit = (e) => {
		e.preventDefault();

		const newErrors = findErrors();
		if (Object.keys(newErrors).length > 0) {
			setError(newErrors);
		} else {
			inputValue.id = props.id;
			inputValue.type = props.type;
			dispatch(editprofile(inputValue));
		}
	};

	return (
		<Form onSubmit={handleSubmit}>
			<Row>
				<InputField
					label='Next Of Kin 1'
					type='text'
					name='nok1'
					value={inputValue?.nok1}
					onChange={handleChange}
					placeholder='enter name of next of kin'
					error={error?.nok1}
					className='col-lg-6'
					require={true}
				/>
				<InputField
					label='NOK 1 Address'
					type='text'
					name='nokAddress1'
					value={inputValue?.nokAddress1}
					onChange={handleChange}
					placeholder='enter work address'
					error={error?.nokAddress1}
					className='col-lg-6'
					require={true}
				/>
			</Row>

			<Row>
				<InputField
					label='NOK 1 Email'
					type='text'
					name='nokEmail1'
					value={inputValue?.nokEmail1}
					onChange={handleChange}
					placeholder='enter name of next of kin'
					error={error?.nokEmail1}
					className='col-lg-6'
					require={true}
				/>
				<InputField
					label='NOK 1 Phone Number'
					type='text'
					name='nokPhone1'
					value={inputValue?.nokPhone1}
					onChange={handleChange}
					placeholder='enter work address'
					error={error?.nokPhone1}
					className='col-lg-6'
					require={true}
				/>
			</Row>
			<Row>
				<InputField
					label='Relationship'
					type='text'
					name='relationship1'
					value={inputValue?.relationship1}
					onChange={handleChange}
					placeholder='enter relationship '
					error={error?.relationship1}
					className='col-lg-6'
					require={true}
				/>
			</Row>
			<hr />
			<Row>
				<InputField
					label='Next Of Kin 2'
					type='text'
					name='nok2'
					value={inputValue?.nok2}
					onChange={handleChange}
					placeholder='enter name of next of kin'
					error={error?.nok2}
					className='col-lg-6'
					require={true}
				/>
				<InputField
					label='NOK 2 Address'
					type='text'
					name='nokAddress2'
					value={inputValue?.nokAddress2}
					onChange={handleChange}
					placeholder='enter work address'
					error={error?.nokAddress2}
					className='col-lg-6'
					require={true}
				/>
			</Row>

			<Row>
				<InputField
					label='NOK 2 Email'
					type='text'
					name='nokEmail2'
					value={inputValue?.nokEmail2}
					onChange={handleChange}
					placeholder='enter name of next of kin'
					error={error?.nokEmail2}
					className='col-lg-6'
					require={true}
				/>
				<InputField
					label='NOK 2 Phone Number'
					type='text'
					name='nokPhone2'
					value={inputValue?.nokPhone2}
					onChange={handleChange}
					placeholder='enter work address'
					error={error?.nokPhone2}
					className='col-lg-6'
					require={true}
				/>
			</Row>
			<Row>
				<InputField
					label='Relationship'
					type='text'
					name='relationship2'
					value={inputValue?.relationship2}
					onChange={handleChange}
					placeholder='enter relationship '
					error={error?.relationship2}
					className='col-lg-6'
					require={true}
				/>
			</Row>
			<Button className='btn-block' type='submit'>
				submit
			</Button>
		</Form>
	);
}

export default EditProfile;
