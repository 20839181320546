import React from "react";
import ContentWrapper from "../../utilities/ContentWrapper";
import CustomModal from "../../utilities/CustomModal";
import AddFinancialReport from "./AddFinancialReport";
import PreviewPDF from "../byLaws/PreviewPDF";

import { useDispatch, useSelector } from "react-redux";
import {
	deleteFinReport,
	deleteLegalDoc,
	getFinReport,
	getLegalDocs,
} from "../../../store/actions/adminActions";
import { clearNotifications } from "../../../store/actions/notificationsActions";
import { toast } from "react-toastify";

function AuditedFinReport() {
	const [showModal, setShowModal] = React.useState(false);
	const [displayType, setDisplayType] = React.useState();
	const [currDoc, setCurrDoc] = React.useState();
	const [loading, setLoading] = React.useState(false);

	const dispatch = useDispatch();
	const finReports = useSelector((state) => state.admin.finReports);

	console.log(finReports);

	function handleHideModal() {
		setShowModal(false);
	}

	function handleShowModal(type, fileLoc) {
		setShowModal(true);
		setDisplayType(type);
		setCurrDoc(fileLoc);
	}

	function handleDeleteDoc(id) {
		dispatch(deleteFinReport(id));
	}

	const notification = useSelector((state) => state.notification);

	React.useEffect(() => {
		setLoading(false);
		if (notification.success?.message) {
			toast.success(notification.success?.message);

			dispatch(getFinReport());
			dispatch(clearNotifications());

			handleHideModal();
		}
		if (notification.errors?.message) {
			toast.error("failed");
		}
		dispatch(clearNotifications());
	}, [
		dispatch,
		notification.errors?.message,
		notification.success?.message,
		setLoading,
	]);

	React.useEffect(() => {
		dispatch(getFinReport());
	}, [dispatch]);
	return (
		<ContentWrapper>
			<div className='card' id='cart'>
				<div className='row'>
					<div className='col-md-12 cart'>
						<div className='title d-flex'>
							<div className='col'>
								<h4>
									<b>Audited Financial Report</b>
								</h4>
							</div>
							<button
								className='check-out-btn col-lg-3'
								onClick={() => handleShowModal("form")}>
								ADD REPORT
							</button>
						</div>
					</div>

					<div className='col-md-12 cart'>
						{finReports?.length > 0 ? (
							finReports.map((doc) => (
								<div className='row border-top border-bottom p-2' key={doc._id}>
									<div className='row main align-items-center'>
										<div className='col'>
											<div className='row  text-bold'>
												{doc.year.toUpperCase()}
											</div>
										</div>
										<div className='col'>
											{" "}
											<div className='row'>{doc.description}</div>
										</div>
										<div className='col' role='button'>
											<span
												className='close text-success'
												onClick={() => handleShowModal("preview", doc)}>
												preview
											</span>
										</div>
										<div
											className='col'
											role='button'
											onClick={() => handleDeleteDoc(doc._id)}>
											<span className='close text-danger'>remove item</span>
										</div>
									</div>
								</div>
							))
						) : (
							<h4>No report have been uploaded</h4>
						)}
					</div>
				</div>
			</div>
			<CustomModal
				hide={handleHideModal}
				show={showModal}
				size={displayType === "form" ? "md" : "xl"}
				title={
					displayType === "form"
						? "ADD AUDITED FINANCIAL REPORT"
						: "PREVIEW REPORT"
				}>
				{displayType === "form" && (
					<AddFinancialReport loading={loading} setLoading={setLoading} />
				)}
				{displayType === "preview" && (
					<PreviewPDF file={currDoc?.finDocFile?.file_location} />
				)}
			</CustomModal>
		</ContentWrapper>
	);
}

export default AuditedFinReport;
