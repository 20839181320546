import React from "react";
import { MODERATOR_LEVEL } from "./components/common/accessLevel";
import Landing from "./components/landing/Landing";
import { Switch, Route } from "react-router-dom";
import Registration from "./components/views/auths/Registration";
import SignIn from "./components/views/auths/SignIn";
import Dashboard from "./components/views/dashboard/Dashboard";
import PrivateRoute from "./components/common/PrivateRoute";
import { connect } from "react-redux";
import "./App.css";

import Balances from "./components/members/balances/Balances";
import HomeDashboard from "./components/views/dashboard/HomeDashboard";
import UserConfirm from "./components/admin/UserConfirm";
import ProfileOverView from "./components/admin/ProfileOverView";
import ViewInstructions from "./components/admin/ViewInstructions";
import MemberProfile from "./components/members/profile/MemberProfile";
import UserProfile from "./components/admin/UserProfile";
import ForgotPassword from "./components/views/auths/ForgotPassword";
import ChangePassword from "./components/views/auths/ChangePassword";
import { useDispatch } from "react-redux";
import SummaryReport from "./components/admin/SummaryReport";
import RequestView from "./components/admin/RequestView";
import ComposeMessage from "./components/admin/ComposeMessage";
import ReferralTable from "./components/admin/ReferralTable";
import MemberRoster from "./components/admin/MemberRoster";
import { fetchUserProfile } from "./store/actions/userProfileActions";
import CommodityRequest from "./components/members/commodity/CommodityRequest";
import CommodityItem from "./components/members/commodity/CommodityItem";
import CartPage from "./components/members/commodity/CartPage";
import AddProduct from "./components/admin/AddProduct";
import ViewProducts from "./components/admin/ViewProducts";
import EditProduct from "./components/admin/EditProduct";
import ViewCommodityRequest from "./components/admin/ViewCommodityRequest";
import LoanApplication from "./components/members/loans/LoanApplication";
import ContractPreview from "./components/members/loans/ContractPreview";
import Guarantor from "./components/Guarantor";
import GuarantorPreview from "./components/GuarantorPreview";
import LoanRequest from "./components/admin/LoanRequest";
import AdminLoanPreview from "./components/forms/AdminLoanPreview";
import LoanSummary from "./components/admin/LoanSummary";
import LoanStatus from "./components/members/loans/LoanStatus";
import ByLaws from "./components/admin/byLaws/ByLaws";
import { ToastContainer } from "react-toastify";
import LegalDocs from "./components/members/legals/LegalDocs";
import AuditedFinReport from "./components/admin/financial/AuditedFinReport";
import FinancialReport from "./components/members/financial-report/FinancialReport";
import BankDetailReport from "./components/admin/BankDetailReport";
import SavingsInstruction from "./components/members/profile/SavingsInstructions";
import ManageCommodityRequests from "./components/admin/commodity/ManageCommodityRequest";
import Invoice from "./components/admin/commodity/InvoicePreview";

const App = (props) => {
	const user = props.user;

	const dispatch = useDispatch();

	React.useEffect(() => {
		// dispatch(wakeup());
		dispatch(fetchUserProfile(user));
	}, [dispatch, user]);

	return (
		<div>
			<Route exact path='/' render={() => <Landing />} />

			<Route path='/dashboard' render={() => <Dashboard user={user} />} />

			<Route path='/guarantor-form' render={() => <Guarantor />} />
			<Route path='/guarantor-preview' render={() => <GuarantorPreview />} />
			<Route>
				<Switch>
					<Route
						exact
						path='/signup'
						render={() => <Registration {...props} />}
					/>
					<Route exact path='/signin' render={() => <SignIn {...props} />} />
					<Route
						exact
						path='/forgotpassword'
						render={() => <ForgotPassword {...props} />}
					/>
					<Route
						exact
						path='/emailreset'
						render={() => <ChangePassword {...props} />}
					/>
					<PrivateRoute
						exact
						user={user}
						path='/dashboard'
						component={HomeDashboard}
					/>
					<PrivateRoute
						exact
						user={user}
						path='/dashboard/profile'
						component={MemberProfile}
					/>
					<PrivateRoute
						exact
						user={user}
						path='/dashboard/balances'
						component={Balances}
					/>
					<PrivateRoute
						exact
						user={user}
						RequiredAccessLevel={MODERATOR_LEVEL}
						path='/dashboard/confirmuser'
						component={UserConfirm}
					/>
					<PrivateRoute
						exact
						user={user}
						RequiredAccessLevel={MODERATOR_LEVEL}
						path='/dashboard/message'
						component={ComposeMessage}
					/>
					<PrivateRoute
						exact
						user={user}
						RequiredAccessLevel={MODERATOR_LEVEL}
						path='/dashboard/member-register'
						component={MemberRoster}
					/>
					<PrivateRoute
						exact
						user={user}
						RequiredAccessLevel={MODERATOR_LEVEL}
						path='/dashboard/referralreport'
						component={ReferralTable}
					/>
					<PrivateRoute
						exact
						user={user}
						RequiredAccessLevel={MODERATOR_LEVEL}
						path='/print-request'
						component={RequestView}
					/>
					{/* <PrivateRoute
						exact
						user={user}
						RequiredAccessLevel={MODERATOR_LEVEL}
						path='/dashboard/viewmembers'
						component={ViewMembers}
					/> */}
					<PrivateRoute
						exact
						user={user}
						RequiredAccessLevel={MODERATOR_LEVEL}
						path='/dashboard/summaryreport'
						component={SummaryReport}
					/>
					<PrivateRoute
						exact
						user={user}
						RequiredAccessLevel={MODERATOR_LEVEL}
						path='/dashboard/confirmuser/:id'
						component={ProfileOverView}
					/>
					<PrivateRoute
						exact
						user={user}
						path='/dashboard/savings/instruction'
						component={SavingsInstruction}
					/>
					{/* <PrivateRoute
						exact
						user={user}
						path='/dashboard/savings/initial'
						component={InitialSavingInstruction}
					/>
					<PrivateRoute
						exact
						user={user}
						path='/dashboard/savings/increment'
						component={IncreaseShareCapital}
					/> 
					<PrivateRoute
						exact
						user={user}
						path='/dashboard/savings/decrement'
						component={DecreaseShareCapital}
					/>*/}

					{/* <PrivateRoute
						exact
						user={user}
						path='/dashboard/savings/initial/preview'
						component={InitialSavingPreview}
					/>
					<PrivateRoute
						exact
						user={user}
						path='/dashboard/savings/increase/preview'
						component={IncreasePreviewPage}
					/>
					<PrivateRoute
						exact
						user={user}
						path='/dashboard/savings/decrease/preview'
						component={DecreasePreviewPage}
					/> */}
					<PrivateRoute
						exact
						user={user}
						path='/dashboard/commodity'
						component={CommodityRequest}
					/>
					<PrivateRoute
						exact
						user={user}
						path='/dashboard/commodity/:id'
						component={CommodityItem}
					/>
					<PrivateRoute
						exact
						user={user}
						path='/dashboard/cart'
						component={CartPage}
					/>
					<PrivateRoute
						exact
						user={user}
						path='/dashboard/loan-request'
						component={LoanApplication}
					/>
					<PrivateRoute
						exact
						user={user}
						path='/dashboard/loan-status'
						component={LoanStatus}
					/>
					<PrivateRoute
						exact
						user={user}
						RequiredAccessLevel={MODERATOR_LEVEL}
						path='/dashboard/instructions/initial'
						component={ViewInstructions}
					/>
					{/* <PrivateRoute
						exact
						user={user}
						RequiredAccessLevel={MODERATOR_LEVEL}
						path='/dashboard/instructions/increment'
						component={NewIncreaseRequest}
					/>
					<PrivateRoute
						exact
						user={user}
						RequiredAccessLevel={MODERATOR_LEVEL}
						path='/dashboard/instructions/decrement'
						component={NewIncreaseRequest}
					/> */}
					<PrivateRoute
						exact
						user={user}
						RequiredAccessLevel={MODERATOR_LEVEL}
						path='/dashboard/memberprofile/:id'
						component={UserProfile}
					/>
					<PrivateRoute
						exact
						user={user}
						RequiredAccessLevel={MODERATOR_LEVEL}
						path='/dashboard/addproduct'
						component={AddProduct}
					/>
					<PrivateRoute
						exact
						user={user}
						RequiredAccessLevel={MODERATOR_LEVEL}
						path='/dashboard/products'
						component={ViewProducts}
					/>
					<PrivateRoute
						exact
						user={user}
						RequiredAccessLevel={MODERATOR_LEVEL}
						path='/dashboard/products/:id'
						component={EditProduct}
					/>
					<PrivateRoute
						exact
						user={user}
						RequiredAccessLevel={MODERATOR_LEVEL}
						path='/dashboard/commodityrequests'
						component={ManageCommodityRequests}
					/>

					<PrivateRoute
						exact
						user={user}
						RequiredAccessLevel={MODERATOR_LEVEL}
						path='/dashboard/commodity-invoice/:id'
						component={Invoice}
					/>
					<PrivateRoute
						exact
						user={user}
						RequiredAccessLevel={MODERATOR_LEVEL}
						path='/dashboard/loan-requests'
						component={LoanRequest}
					/>
					<PrivateRoute
						exact
						user={user}
						RequiredAccessLevel={MODERATOR_LEVEL}
						path='/dashboard/loan-contract-preview'
						component={AdminLoanPreview}
					/>
					<PrivateRoute
						exact
						user={user}
						RequiredAccessLevel={MODERATOR_LEVEL}
						path='/dashboard/loan-summary'
						component={LoanSummary}
					/>
					<PrivateRoute
						exact
						user={user}
						path='/dashboard/loan/preview'
						component={ContractPreview}
					/>
					<PrivateRoute
						exact
						user={user}
						RequiredAccessLevel={MODERATOR_LEVEL}
						path='/dashboard/legal-docs'
						component={ByLaws}
					/>
					<PrivateRoute
						exact
						user={user}
						path='/dashboard/user/legal-docs'
						component={LegalDocs}
					/>
					<PrivateRoute
						exact
						user={user}
						RequiredAccessLevel={MODERATOR_LEVEL}
						path='/dashboard/audited-financial-report'
						component={AuditedFinReport}
					/>
					<PrivateRoute
						exact
						user={user}
						RequiredAccessLevel={MODERATOR_LEVEL}
						path='/dashboard/bank-report'
						component={BankDetailReport}
					/>
					<PrivateRoute
						exact
						user={user}
						path='/dashboard/user/audited-financial-report'
						component={FinancialReport}
					/>
				</Switch>
			</Route>
			<ToastContainer position='top-center' />
		</div>
	);
};

function mapStateToProps(state) {
	return { user: state.auth.user };
}
export default connect(mapStateToProps)(App);
