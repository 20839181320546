import React from "react";
import ContentWrapper from "../utilities/ContentWrapper";
import Accordion from "../utilities/Accordion";
import Preloader from "../utilities/Preloader";
import { clearNotifications } from "../../store/actions/notificationsActions";
import { ToastContainer, toast } from "react-toastify";
import {
  fetchMembers,
  acknowledgeReciept,
  declineReciept,
} from "../../store/actions/adminActions";
import { useSelector, useDispatch } from "react-redux";

function ViewInstructions() {
  const dispatch = useDispatch();
  const members = useSelector((state) => state.admin);
  const notification = useSelector((state) => state.notification);
  React.useEffect(() => {
    if (notification.success?.message) {
      toast.success("successful");
      dispatch(clearNotifications());

      // const timer = setTimeout(() => {
      //   window.print();
      // }, 5000);
      // return () => clearTimeout(timer);
    }
    if (notification.errors?.message) {
      const { message } = notification.errors;
      toast.error(message);
      dispatch(clearNotifications());
    }
  }, [notification, dispatch]);

  React.useEffect(() => {
    dispatch(fetchMembers());
  }, [dispatch]);

  const handleReciept = (userData) => {
    dispatch(acknowledgeReciept(userData));
  };
  const handleDecline = (userData) => {
    dispatch(declineReciept(userData));
  };
  const memberswithrequest = members?.members?.filter((member) => {
    const { initialSavingsRequest } = member;
    return initialSavingsRequest?.acknowledged === "pending";
  });

  return members && members ? (
    <ContentWrapper>
      {memberswithrequest.map((member, i) => (
        <Accordion
          type="initial"
          key={member._id}
          member={member}
          index={i}
          handleReciept={handleReciept}
          handleDecline={handleDecline}
        />
      ))}
      <ToastContainer position="top-center" />
    </ContentWrapper>
  ) : (
    <Preloader />
  );
}

export default ViewInstructions;
