import React from 'react'
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import ContentWrapper from '../utilities/ContentWrapper'
import format from "format-number";
import { useHistory } from "react-router-dom";

function LoanTable({ loanRequests, setShowGuarantor, handleConfirm, handleDelete }) {

    const history = useHistory();

    const renderLoanContract = (props, e) => (
        <Tooltip id='button-tooltip' {...props}>
            view contract
        </Tooltip>
    );
    const renderGuarantor = (props, e) => (
        <Tooltip id='button-tooltip' {...props}>
            view guarantors
        </Tooltip>
    );
    const renderConfirm = (props, e) => (
        <Tooltip id='button-tooltip' {...props}>
            confirm
        </Tooltip>
    );
    const renderReject = (props, e) => (
        <Tooltip id='button-tooltip' {...props}>
            reject
        </Tooltip>
    );

    const showContract = (e, value) => {
        history.push({
            pathname: "/dashboard/loan-contract-preview",
            state: value,
        });
    };

    const handleShowGuarantor = (e, value) => {
        setShowGuarantor(value);
    };

    return (
        <ContentWrapper>
            <div className='card' id='cart'>
                <div className='row'>
                    <div className='col-md-12 cart'>
                        <div className='title'>
                            <div className='row'>
                                <div className='col'>
                                    <h4>
                                        <b>LOAN REQUESTS </b>
                                    </h4>
                                </div>
                            </div>
                        </div>

                        <div className='row justify-content-center'>
                            <div className='col-md-12'>
                                <div
                                    className='table-responsive--md '
                                    style={{ margin: "auto" }}>
                                    <table className='table style--two'>
                                        <thead>
                                            <tr>
                                                <th scope='col'>Loanee</th>
                                                <th scope='col'>LoanId</th>
                                                <th scope='col'>Loan Amount</th>
                                                <th scope='col'>Loan Type</th>
                                                <th scope='col'> MORE</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {loanRequests.length > 0 ? (
                                                loanRequests.map((trx) => {
                                                    return (
                                                        <tr key={trx._id}>
                                                            <td data-label='#Trx'>
                                                                {trx?.loaneeDetails.name}
                                                            </td>

                                                            <td data-label='Amount'>
                                                                {trx?.transactionId}
                                                            </td>
                                                            <td data-label='Amount'>
                                                                <strong>
                                                                    {format({ prefix: "₦" })(trx.amount)}
                                                                </strong>
                                                            </td>

                                                            <td data-label='Time'>{trx.loanType}</td>
                                                            <td data-label='MORE'>
                                                                <div className='btn-group'>
                                                                    <OverlayTrigger
                                                                        placement='top'
                                                                        delay={{ show: 250, hide: 400 }}
                                                                        overlay={renderLoanContract}>
                                                                        <Button
                                                                            variant='warning'
                                                                            onClick={(e) => showContract(e, trx)}>
                                                                            <i className='fas fa-file-contract'></i>
                                                                        </Button>
                                                                    </OverlayTrigger>
                                                                    <OverlayTrigger
                                                                        placement='top'
                                                                        delay={{ show: 250, hide: 400 }}
                                                                        overlay={renderGuarantor}>
                                                                        <Button
                                                                            variant='primary'
                                                                            onClick={(e) =>
                                                                                handleShowGuarantor(e, trx)
                                                                            }>
                                                                            <i className='fas fa-id-badge'></i>
                                                                        </Button>
                                                                    </OverlayTrigger>
                                                                    <OverlayTrigger
                                                                        placement='top'
                                                                        delay={{ show: 250, hide: 400 }}
                                                                        overlay={renderConfirm}>
                                                                        <Button
                                                                            variant='success'
                                                                            onClick={() => handleConfirm(trx)}>
                                                                            <i className='fas fa-check-circle'></i>
                                                                        </Button>
                                                                    </OverlayTrigger>
                                                                    <OverlayTrigger
                                                                        placement='top'
                                                                        delay={{ show: 250, hide: 400 }}
                                                                        overlay={renderReject}>
                                                                        <Button
                                                                            variant='danger'
                                                                            onClick={() =>
                                                                                handleDelete(trx?._id)
                                                                            }>
                                                                            <i className='fas fa-ban'></i>
                                                                        </Button>
                                                                    </OverlayTrigger>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            ) : (
                                                <td>
                                                    <h4 className=' m-4'>Nothing found!!</h4>
                                                </td>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ContentWrapper>
    )
}

export default LoanTable
