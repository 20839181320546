import React from "react";

function InitialSavingAdminPreview(props) {
  const { user } = props;
  const { initialSavingsRequest } = props.user;
  const salarystruc = user?.employmentDetails.salaryStructure;
  return (
    <div className="invoice p-3 mb-3 col-12">
      <div style={{ fontSize: "1.7rem" }}>
        <div style={{ textAlign: "center" }}>
          <h4>
            <strong>
              LION MULTI-PURPOSE COOPERATIVE SOCIETY NIGERIA LIMITED
            </strong>
          </h4>
          <b>
            Suite 116, Continuing Education Centre (CEC), UNGEL Guest House,
            University of Nigeria Main Campus, Nsukka.
          </b>
          <br />
          <br />
          <br />
          <h4>
            <b>INSTRUCTION ON INITIAL SAVINGS AND SHARE CAPITAL</b>
          </h4>
          (Joining the society for the first time, fill this form)
          <br />
          <br />
        </div>

        <span style={{ textAlign: "left" }}>
          The president, Lion Multi-Purpose Cooperative Society Nig. Ltd
          <br />
          Sir/Ma <br /> Please accept this as my instruction to debit my salary
          account with University of Nigeria, Nsukka/Other Institution (please
          specify) ..
          {user.employmentDetails.organisationName !== "University of Nigeria"
            ? user.employmentDetails.organisationName
            : null}
          <dl className="row">
            <dt className="col-sm-6">Name</dt>
            <dd className="col-sm-6">{user?.name}</dd>
            <dt className="col-sm-6">LMCS Membership ID</dt>
            <dd className="col-sm-6">{user?.memberId}</dd>
            <dt className="col-sm-6">Faculty</dt>
            <dd className="col-sm-6">{user?.employmentDetails.faculty}</dd>
            <dt className="col-sm-6">Department</dt>
            <dd className="col-sm-6">{user?.employmentDetails.department}</dd>
            <dt className="col-sm-6">Salary Structure</dt>
            <dd className="col-sm-6">{salarystruc.toUpperCase()}</dd>
          </dl>
          <br />
          <b>1. Share Capital</b> <br />
          Please debit my salary account with the sum of NGN{" "}
          <b>{initialSavingsRequest.shareCapitalAmount}</b> every month for
          <b>{initialSavingsRequest.shareCapitalMonths}</b> month(s) as part of
          my share capital. (Please note that share Capital contribution is
          compulsory & not less than N5,000/month, contributable for at least 20
          months. Minimum share capital after 20months must be up to N100,000
          and the upper limit for share capital is N5,000,000).
          <br />
          <b>2. Ordinary Savings</b> <br />
          Please debit my salary account with the sum of NGN{" "}
          <b>{initialSavingsRequest.ordinarySavingsAmount}</b> every month for
          {} <b>{initialSavingsRequest.ordinarySavingsMonths}</b> {}months as
          part of my ordinary Savings. (Please note that amount contributable as
          ordinary savings is compulsory & not less than N5,000/month with no
          upper limit.)
          <br />
          <b>3. Christmas Savings</b> <br />
          Please debit my salary account with the sum of{" "}
          <b>NGN {initialSavingsRequest.christmasSavingsAmount}</b> every month
          for
          <b>{initialSavingsRequest.christmasSavingsMonths}</b> months as part
          of my ordinary Savings. (Please note that amount contributable as
          ordinary savings is compulsory & not less than N5,000/month with no
          upper limit.)
          <br />
          <b>4. Education Savings</b> <br />
          Please debit my salary account with NGN{" "}
          <b>{initialSavingsRequest.educationSavingsAmount}</b> every month for{" "}
          {initialSavingsRequest.educationSavingsMonths}.months as part of my
          annual withdrawable Education savings (please note that annual
          withdrawable Education savings is optional).
          <br />
          <b>5. Retirement Savings</b> <br />
          Please debit my salary account with NGN{" "}
          <b>{initialSavingsRequest.retirementSavingsAmount}</b>. every month
          for {initialSavingsRequest.retirementSavingsMonths}.months as part of
          my Retirement savings for 10years or any other number of years
          exceeding 10years for tenured/withdrawable retirement savings (please
          note that tenured/withdrawable Retirement savings is optional).
        </span>
        <span
          className="float-right"
          style={{ width: "100px", height: "100px" }}
        >
          <img
            src={user.signature}
            alt={"..."}
            style={{ width: "100px", height: "60px", marginTop: "70px" }}
          />
        </span>
      </div>
    </div>
  );
}

export default InitialSavingAdminPreview;
