import React from "react";
import { Modal, Button, Form, Col } from "react-bootstrap";

function AddVariation(props) {
  const [errors, setErrors] = React.useState({});
  const {
    smShow,
    inputValue,
    handleCloseModal,
    handleVariation,

    handleChange,
  } = props;

  const findError = () => {
    const newErrors = {};
    if (!inputValue.variationName || inputValue.variationName === "") {
      newErrors.variationName = "cannot be blank";
    }
    if (!inputValue.price || inputValue.price === "") {
      newErrors.price = "cannot be blank";
    } else if (inputValue.price < 0) {
      newErrors.price = "cannot nagative value";
    }

    return newErrors;
  };

  const handleAdd = (e) => {
    e.preventDefault();
    const newErrors = findError();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      handleVariation();
    }
  };
  return (
    <Modal
      size="sm"
      show={smShow}
      onHide={() => handleCloseModal()}
      aria-labelledby="example-modal-sizes-title-sm"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-sm"></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" as={Col} controlId="formdepartment">
            <Form.Label>Variation Name</Form.Label>
            <Form.Control
              type="text"
              name="variationName"
              onChange={(e) => handleChange(e)}
              isInvalid={!!errors.variationName}
            />
            <Form.Control.Feedback type="invalid">
              {errors.variationName}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3 " as={Col} controlId="formdepartment">
            <Form.Label>Price</Form.Label>
            <Form.Control
              className="input-number"
              type="number"
              name="price"
              onChange={(e) => handleChange(e)}
              isInvalid={!!errors.price}
            />
            <Form.Control.Feedback type="invalid">
              {" "}
              {errors.price}
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
        <Button className="btn" onClick={() => handleCloseModal()}>
          Cancel{" "}
        </Button>{" "}
        <Button className="btn" onClick={handleAdd}>
          Add{" "}
        </Button>{" "}
      </Modal.Body>
    </Modal>
  );
}

export default AddVariation;
