import React from "react";

function Auditor() {
  return (
    <>
      <div className="card card-widget widget-user-2">
        <div className="widget-user-header bg-warning">
          <div className="widget-user-image">
            <img
              className="img-circle elevation-2"
              src="img/team/auditor.jpg"
              alt="User Avatar"
            />
          </div>

          <h3 className="widget-user-username">CHIEDOZIE T. NWACHUKWU</h3>
          <h5 className="widget-user-desc">B.SC, MBA, JP, CNA</h5>
          <h5 className="widget-user-desc">Auditor</h5>
        </div>
        <div className="card-footer p-0">
          <div className="card-header">
            <h3 className="card-title">
              <i className="fas fa-bullhorn"></i>
              Professional/Academic Qualifications:
            </h3>
          </div>
          Chiedozie Tochukwu Nwachukwu is an Associate Member of the Association
          of National Accountants of Nigeria. He graduated from Enugu State
          University of Science and Technology (ESUT) with B.Sc. in Accounting
          and also possesses an MBA in Management from University of Nigeria
          Nsukka. Mr. Nwachukwu is a graduate of Diamond Bank banking school and
          the renowned UBA Academy. He is also a Certified Professional
          Accountant.
          <div className="card-header">
            <h3 className="card-title">
              <i className="fas fa-bullhorn"></i>
              Work Experience:
            </h3>
          </div>
          His working career spans over 13 years from the private to the public
          sector. He started with Diamond Bank Plc as a Business Development
          Officer. He served in various departments of the bank including
          customer service, funds transfer, clearing, credit risk management,
          Treasury, cash management and International operations. He moved to
          UBA in July 2011 and was deployed to Head Office, Group Audit and
          Control Directorate of the bank on appointment following an excellent
          performance in the UBA academy with an eagle eye for details. While in
          UBA, he led Audit teams in the Audit of the banks Head Office
          providing an assessment on the banks internal control system to
          management as well ensuring compliance to the banks policies and
          procedural guidelines. He was also involved in various audits of
          branch offices including spot checks and fraud investigations. In
          September 2012, he moved to the Bursary Department of University of
          Nigeria, Nsukka, where he served as the University Cashier Paymaster.
          He moved to Head Payroll MIS from September 2015 and currently serves
          as Head, Budget and Fees under Budget and Expenditure Control
          Management Division (BECM). He possess over Nine (9) years work
          experience in University Bursary administration. Mr. Chiedozie
          Nwachukwu is experienced in the operation and management of various
          accounting software including Flex-cube, Finacle, Sage ERP, Government
          Integrated Financial Management Information Systems, Soft-suite apps,
          Structured Pay Manager SPM, Remita to mention a few. He has attended
          several Conferences/Training and is a member of various committees in
          University of Nigeria.
        </div>
      </div>
    </>
  );
}

export default Auditor;
