import React from "react";
import "./select.css";

function SelectDisplay(props) {
  return (
    <div className="select-container" id="select">
      <button
        value="Savings"
        className={props.isSelected === "Savings" ? "active" : "unselected"}
        onClick={(e) => {
          props.toggleSelected(e);
        }}
      >
        Savings and Deposit Transaction History
      </button>

      <button
        value="Loans"
        className={props.isSelected === "Loans" ? "active" : "unselected"}
        onClick={(e) => {
          props.toggleSelected(e);
        }}
      >
        Loans and Deductions Transaction History
      </button>
    </div>
  );
}

export default SelectDisplay;
