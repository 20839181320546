import React from "react";
import DecreaseAdminPreview from "../forms/DecreaseAdminPreview";
import IncreaseAdminPreview from "../forms/IncreaseAdminPreview";
import InitialSavingAdminPreview from "../forms/InitialSavingAdminPreview";
import ContentWrapper from "../utilities/ContentWrapper";

function RequestView(props) {
  const { state } = props.location;

  const renderInfo = (type) => {
    switch (type) {
      case "increase-instrutions":
        return <IncreaseAdminPreview user={state?.user} />;
      case "initial-instrutions":
        return <InitialSavingAdminPreview user={state?.user} />;
      case "decrease-instrutions":
        return <DecreaseAdminPreview user={state?.user} />;

      default:
        return null;
    }
  };

  return (
    <ContentWrapper>
      <div className="col-12">
        <div className=" no-print callout callout-info">
          <h5>
            <i className="fas fa-info"></i> Note:
          </h5>
          please click submit at the end of the page to complete the process.
          This page has been enhanced for printing. you may wish to print this
          form.
        </div>

        <div className="invoice p-3 mb-3 col-12">{renderInfo(state.type)}</div>

        <div className="row no-print">
          <div className="col-12">
            <button
              type="button"
              className="btn btn-primary float-right"
              style={{ marginRight: "5px" }}
              onClick={() => {
                window.print();
              }}
            >
              <i className="fas fa-download"></i> Print
            </button>
          </div>
        </div>
      </div>
    </ContentWrapper>
  );
}

export default RequestView;
