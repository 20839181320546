import React from "react";
import "./historyitem.css";

function HistoryItemHeader() {
	return (
		<div className='transaction-card '>
			<div className='details' style={{ minHeight: "40px" }}>
				<div className='narration'>
					{" "}
					<div>
						<b>NARRATION</b>
					</div>
				</div>
				<div className='row balances'>
					<div className='col'>DEBIT</div> <div className='col'>CREDIT</div>
					<div className='col'>BALANCE</div>
				</div>
			</div>
		</div>
	);
}

export default HistoryItemHeader;
