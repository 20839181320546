import React from "react";

function VicePresidentInfo() {
  return (
    <>
      <div className="card card-widget widget-user-2">
        <div className="widget-user-header bg-warning">
          <div className="widget-user-image">
            <img
              className="img-circle elevation-2"
              src="img/team/vice_president.jpg"
              alt="User Avatar"
            />
          </div>

          <h3 className="widget-user-username">ROBERT C. EZEANWU</h3>
          <h5 className="widget-user-desc">
            "BSc, M.A, MBA, PhD - FPAMDI, AFIICA, ASCON, MACCE, MISMN
          </h5>
          <h5 className="widget-user-desc">Vice President</h5>
        </div>
        <div className="card-footer p-0">
          <div className="card-header">
            <h3 className="card-title">
              <i className="fas fa-bullhorn"></i>
              Professional/Academic Qualifications:
            </h3>
          </div>
          Robert C. E. Ezeanwu is a Fellow of the prestigious Public and
          Management Development Institute of Nigeria and an Associate Fellow of
          the Institute of Industrialist and Corporate Administrators of
          Nigeria. He is also a member of the African Council of Communication
          Educators and a member of the Institute of Strategic Management of
          Nigeria. He had his first degree from the Department of Mass
          Communication, Nnamdi Azikiwe University, Awka. He equally possesses a
          Master’s degree and a Doctorate degree in Mass Communication and an
          MBA in Business Administration. He is currently a Lecturer and a
          Research Fellow in the department of Mass Communication and the Centre
          for Entrepreneurship & Development Research respectively, in the
          University of Nigeria, Nsukka.
          <div className="card-header">
            <h3 className="card-title">
              <i className="fas fa-bullhorn"></i>
              Work Experience:
            </h3>
          </div>
          Prior to working in the University of Nigeria, Robert had his initial
          working experience with the Anambra State Manifest & Insurance Scheme
          from 1999–2000 as the Assistant Manager, Field Operations Department
          (First Vehicle Insurance Company created by the Anambra State
          Government to improve her internally generated revenue). He moved to
          Enugu as a Marketing Executive & Public Relations Officer for O’Neal
          Group Enugu, in 2001. He later got involved in publishing and design
          and moved to Nsukka in late 2001. He opened up his publishing business
          in partnership with Multi Educational Services Trust an NGO working
          extensively with UNICEF in the areas of skill acquisition and human
          capital development from 2001 to 2007. He also worked concurrently
          during this period at the largest private school network in Enugu
          State, Shalom Academy Nsukka where he left in 2007 as the HOD
          Languages, House Master and Editor in Chief of the Shalom Magazine
          which he founded. Robert had nine years cognate experience spanning
          planning and coordination of organization’s field operations;
          sourcing, identifying and mapping out viable outlets, units and new
          territories for effective coverage of vehicle passenger by insurance
          staff; media placement of adverts and public relations drive;
          servicing/sourcing/initiating customer awareness of organizations
          products and services; publication of annual school magazine in a
          special school for the gifted; talented and creative; budgeting,
          materials sourcing and teaching English language and literature. In
          May 2007, he was employed as an Extension Specialist as a foundation
          staff of the new Centre for Entrepreneurship and Development Research,
          UNN. He served as communications and information officer,
          lecturer/Instructor on BDS, BBM, creativity, leadership,
          entrepreneurship & business plan instructor, skill acquisition
          instructor and other general duties from 2007 to 2016. Since then, he
          has been lecturing both Entrepreneurship & Mass Communication Courses.
          He has also served as an Entrepreneurship Development Specialist
          Trainer in the CBN/EDC/UNN/CEDR Project, Youth Entrepreneurship
          Trainer NDDC and an Entrepreneurship Programme Instructor for people
          living with disabilities on behalf of SURE-P. He has functioned as a
          State Coordinator primary data researcher for the National
          Communications Commission survey of GSM effectiveness of service
          providers and households, 2010 & 2013 for Abia & Bayelsa states. He
          has been a UNICEF resource person in 2005 where he was among the team
          that trained primary school teachers on communication & reading skills
          in Rivers State. His research interests are in the areas of political
          communication, development communication, creativity, entrepreneurship
          development, skill acquisition, business communication and creative
          writing.
          <div className="card-header">
            <h3 className="card-title">
              <i className="fas fa-bullhorn"></i>
              Professional/Community Service:
            </h3>
          </div>
          Robert believes that he can help create jobs by transforming dreams
          into reality with emphasis on self-assessment, creativity,
          resourcefulness, persistence and calculated risk taking.
          <br />
          He is a 3rd Degree Black Belt Jidokwan Taekwondo Practitioner
          <br />
          He was the Camp Commandant, West African University Games, hosted by
          UNN in 2015
          <br />
          He has served as the Head Coach of Lions Taekwondo Club, UNN for the
          past 15 years
          <br />
          He has served as the pioneer Vice President Merchandising of the CEDR
          based Cooperative.
          <br />
          He is a member of the Police Community Relations Committee, Nsukka
          <br />
          He is a founding member of the University of Nigeria E-Market team and
          he is happily married with five children.
        </div>
      </div>
    </>
  );
}

export default VicePresidentInfo;
