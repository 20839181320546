import React from "react";
import moment from "moment";
import format from "format-number";

export default function GuarantorContract(props) {
  const { data, guarantor } = props;
  const loanee = data;

  const { loaneeDetails } = data;

  return (
    <>
      {guarantor ? (
        <div id="loan-contract" className="m-3">
          <div style={{ textAlign: "center" }}>
            <span>
              <img
                src="img/logo/unn-logo.png"
                alt=".."
                style={{ width: "70px" }}
              />
            </span>
            <h4 className="text-green">
              <strong>
                LION MULTI-PURPOSE COOPERATIVE SOCIETY NIGERIA LIMITED
              </strong>
            </h4>
            <h4 className="text-green">
              <strong>LOAN GUARANTORS FORM</strong>
            </h4>
            <br />
            <div className="row">
              <div className="col-6" style={{ textAlign: "left" }}>
                {" "}
                <p className="ml-4">
                  {" "}
                  <span className="text-danger">
                    <b>ADDRESS:</b>
                  </span>{" "}
                  Suite 116, Continuing Education Centre (CEC) UNGEL Guest
                  House, University of Nigeria Main Campus, Nsukka
                </p>
              </div>{" "}
              <div className="col-6" style={{ textAlign: "left" }}>
                <p>
                  <span className="text-danger">
                    <b>Email:</b>
                  </span>{" "}
                  lmcsnigltd@gmail.com
                </p>
                <p>
                  <span className="text-danger">
                    <b>Phone:</b>
                  </span>{" "}
                  08034077466, 08033412027
                </p>
                <p>
                  <span className="text-danger">
                    <b>Website:</b>
                  </span>{" "}
                  www.lmcsnigltd.org.ng
                </p>
              </div>
            </div>
          </div>
          <br />
          <br />
          <span style={{ textAlign: "left" }}>
            <p>
              <b>BORROWER’S DETAILS</b>
            </p>
            <br />

            <p>
              <b>Name</b>: {loaneeDetails?.name}
            </p>
            <p>
              <b>Membership ID:</b> {loanee?.userId}
            </p>
            <p>
              {" "}
              <b>Staff ID:</b> {loaneeDetails?.employmentDetails?.staffNum}
            </p>
            <p>
              {" "}
              <b>IPPIS No:</b> {loaneeDetails?.employmentDetails?.ippisNum}
            </p>
            <p>
              <b>Faculty:</b> {loaneeDetails?.employmentDetails?.faculty}
            </p>
            <p>
              <b>Department:</b> {loaneeDetails?.employmentDetails?.department}{" "}
            </p>
            <p>
              <b>Residential Address:</b> {loaneeDetails?.homeAddress}{" "}
            </p>
            <p>
              <b>Amount Being Guaranteed: </b>{" "}
              {format({ prefix: "₦" })(loanee?.amount)}{" "}
            </p>
            <br />
            <br />
            <span
              style={{ width: "100px", height: "100px" }}
              className="float-right"
            >
              <img
                src={guarantor?.passport}
                alt={"..."}
                style={{ width: "100px", height: "100px" }}
              />
            </span>
            <p>
              <b>GUARANTOR'S DATA</b>
            </p>
            <br />

            <p>
              <b>Surname</b>: {guarantor?.surname}
            </p>
            <p>
              <b>Other names:</b> {guarantor?.otherName}
            </p>
            <p>
              {" "}
              <b>Residential Address:</b> {guarantor?.residentialAddress}
            </p>
            <p>
              {" "}
              <b>Telephone Number:</b> {guarantor?.telephone}
            </p>
            <p>
              <b>Email:</b> {guarantor?.email}
            </p>
            <p>
              <b>Place of work with address:</b> {guarantor?.workPlace}{" "}
            </p>
            <p>
              <b>Permanent Home Address</b> {guarantor?.permanentAddress}{" "}
            </p>
            <p>
              <b>Next of Kin: </b> {guarantor?.nok}{" "}
            </p>
            <p>
              <b>Next of Kin Phone Number: </b> {guarantor?.nokPhone}{" "}
            </p>
            <p>
              <b>Next of Kin Email: </b> {guarantor?.nokEmail}{" "}
            </p>
            <p>
              Are you a Member of Lion Multi-Purpose Cooperative Society
              Limited? Yes/No
            </p>
            <p>If Yes, State the current values of your:</p>
            <p>Share Capital (₦){guarantor?.shareCapital}</p>
            <p>Ordinary Savings(₦){guarantor?.ordinarySavings}</p>
            <p>Retirement Savings(₦){guarantor?.retirementSavings}</p>
            <p>Christmas Savings (₦){guarantor?.christmasSavings}</p>
            <p>Education Savings (₦){guarantor?.educationSavings}</p>
            <br />
            <br />
            <p>
              <b>DECLARATION:</b>
            </p>
            <p>
              I hereby certify/confirm that I know the above person/company and
              consider him/she/it /fit and proper to access your cooperative
              loan/facility. I am also aware that he/she/ it has applied for a
              loan/facility of ₦ {format({ prefix: "₦" })(loanee?.amount)} and
              do hereby surety/indemnify/guarantee your cooperative to the tune
              of ₦ {format({ prefix: "₦" })(loanee?.amount)} in case the
              customer fails to repay the amount borrowed or any outstanding
              upon maturity of the facility. I agree to pay back the outstanding
              balance in case the above customer fails to offset the loan.
            </p>

            <br />
            <br />
            <br />
            <br />
            <br />

            <span style={{ width: "100px", height: "100px" }}>
              <img
                src={guarantor?.imageURL}
                alt={"..."}
                style={{ width: "100px", height: "60px", marginTop: "70px" }}
              />
            </span>
            <div className="row">
              <div className="col-6">
                <p>
                  <b>GUARANTOR’S SIGNATURE</b>{" "}
                </p>
              </div>
              <div className="col-6">
                <p>
                  <b>DATE</b>:{" "}
                  {moment(guarantor.createdAt).format("DD/MM/YYYY")}
                </p>
              </div>
            </div>
          </span>
        </div>
      ) : (
        <div id="loan-contract" className="m-3 p-3 bg-dark">
          <h4 className="text-white m-4">Nothing found!!</h4>
        </div>
      )}
    </>
  );
}
