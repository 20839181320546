import React, { useState } from "react";
import LoanItem from "./LoanItem";
import LoanItemHeader from "./LoanItemHeader";
import moment from "moment";
import { useSelector } from "react-redux";
import { pioneers } from "../../../data/pioneers";

function LoansLedger(props) {
	const { userLoanData } = props;

	const user = useSelector((state) => state.userProfile.profile.user);
	const [isPioneer, setIsPioneer] = React.useState();
	React.useEffect(() => {
		setIsPioneer(
			pioneers.find((p) => {
				return p === user?.memberId;
			})
		);
	}, [user?.memberId]);

	const [loansData, setLoansData] = useState([]);

	const [filterName, setFiltername] = useState("filter by loans type");

	React.useEffect(() => {
		setLoansData(
			userLoanData.sort((a, b) => {
				return moment(b.dt).diff(a.dt);
			})
		);
	}, [userLoanData]);

	const handleFilter = (e) => {
		setFiltername(e.target.name);
		const filteredData = userLoanData.filter((data) => {
			return data.loanCode?.trim() === e.target.value;
		});
		setLoansData(filteredData);

		if (e.target.value === "0") setLoansData(userLoanData);
	};

	return (
		<div className='scrolling-wrapper mt-4' style={{ overflowX: "auto" }}>
			<div className='dropright'>
				<button
					className='btn btn-outline-success dropdown-toggle'
					type='button'
					id='btnDropdownDemo'
					data-toggle='dropdown'
					aria-haspopup='true'
					aria-expanded='false'>
					{" "}
					<i className='fas fa-hand-holding-usd'></i> {filterName}
				</button>

				<div className='dropdown-menu' aria-labelledby='btnDropdownDemo'>
					<button
						className='dropdown-item btn btn-secondary'
						value='0'
						name='All Loans '
						onClick={handleFilter}>
						All Loans
					</button>
					<button
						className='dropdown-item btn btn-secondary'
						value={isPioneer ? "01020313" : "01020306"}
						name='1 year Normal Loan'
						onClick={handleFilter}>
						1 year Normal Loan
					</button>
					<button
						className='dropdown-item btn btn-secondary'
						value={isPioneer ? "01020315" : "01020314"}
						name='2 year Normal Loan'
						onClick={handleFilter}>
						2 year Normal Loan
					</button>
					<button
						className='dropdown-item btn btn-secondary'
						value={isPioneer ? "01020316" : "01020308"}
						name='Housing Loan'
						onClick={handleFilter}>
						Housing Loan
					</button>
					<button
						className='dropdown-item btn btn-secondary'
						value={isPioneer ? "01020317" : "01020309"}
						name='Land Loan'
						onClick={handleFilter}>
						Land Loan
					</button>
					<button
						className='dropdown-item btn btn-secondary'
						value={isPioneer ? "01020319" : "01020311"}
						name='Motorcycle Loan'
						onClick={handleFilter}>
						Motorcycle Loan
					</button>
					<button
						className='dropdown-item btn btn-secondary'
						value={isPioneer ? "01020320" : "01020312"}
						name='Tricycle Loan'
						onClick={handleFilter}>
						Tricycle Loan
					</button>
					<button
						className='dropdown-item btn btn-secondary'
						value={isPioneer ? "01020325" : "01020307"}
						name='Emergency Loan'
						onClick={handleFilter}>
						Emergency Loan
					</button>
					<button
						className='dropdown-item btn btn-secondary'
						value={isPioneer ? "01020321" : "01020301"}
						name='Commodity Loan'
						onClick={handleFilter}>
						Commodity Loan
					</button>
					<button
						className='dropdown-item btn btn-secondary'
						value={isPioneer ? "01020322" : "01020304"}
						name='Electronic Purchase Loan'
						onClick={handleFilter}>
						Electronic Purchase Loan
					</button>
					<button
						className='dropdown-item btn btn-secondary'
						value={isPioneer ? "01020324" : "01020303"}
						name='Office Equipment Loan'
						onClick={handleFilter}>
						Office Equipment Loan
					</button>
					<button
						className='dropdown-item btn btn-secondary'
						value={isPioneer ? "01020323" : "01020302"}
						name='Stationary Loan'
						onClick={handleFilter}>
						Stationary Loan
					</button>
				</div>
			</div>
			<LoanItemHeader />

			{loansData.map((data, i) => {
				return <LoanItem key={data.referenceNo + i} data={data} />;
			})}
		</div>
	);
}

export default LoansLedger;
