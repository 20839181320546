import React from "react";
import ContentWrapper from "../../utilities/ContentWrapper";
import { useDispatch, useSelector } from "react-redux";
import { generateReferralLink } from "../../../store/actions/userActions";
import { Button, Row } from "react-bootstrap";
import EditProfile from "./EditProfile";
import CustomModal from "../../utilities/CustomModal";
import AddBankInfo from "./AddBankInfo";

function MemberProfile() {
	const { profile } = useSelector((state) => state.userProfile);
	const [formtype, setFormtype] = React.useState();

	const { user } = profile;

	const dispatch = useDispatch();
	const reflink = useSelector((state) => state.userProfile);
	const [isCopied, setIsCopied] = React.useState(false);
	const [showForm, setShowForm] = React.useState(false);

	async function copyTextToClipboard(text) {
		if ("clipboard" in navigator) {
			return await navigator.clipboard.writeText(text);
		} else {
			return document.execCommand("copy", true, text);
		}
	}

	const handleClick = () => {
		copyTextToClipboard(reflink?.refLink?.message)
			.then(() => {
				// If successful, update the isCopied state value
				setIsCopied(true);
				setTimeout(() => {
					setIsCopied(false);
				}, 1500);
			})
			.catch((err) => {
				console.log(err);
			});
	};
	const handleShowForm = (type) => {
		setShowForm(!showForm);
		setFormtype(type);
	};
	const handleHideForm = () => {
		setShowForm(false);
	};
	React.useEffect(() => {
		if (user?.memberId !== "pending approval") {
			dispatch(generateReferralLink());
		}
	}, [dispatch, user?.memberId]);

	return (
		<ContentWrapper>
			<section className='content'>
				<Row>
					<div className='col-md-3'>
						<div className='card card-success card-outline'>
							<div className='card-body box-profile'>
								<div className='text-center'>
									<div>
										<img
											className='profile-user-img img-fluid img-circle'
											src={user?.passport}
											alt='User profile'
											style={{
												height: "100px",
												width: "100px",
												objectFit: "cover",
											}}
										/>
									</div>
								</div>

								<h3 className='profile-username text-center'>{user?.name} </h3>
								<p className='text-muted text-center'>{user?.memberId}</p>

								<ul className='list-group list-group-unbordered mb-3'>
									{user?.memberId !== "pending approval" ? (
										<li className='list-group-item'>
											<div className='form-group'>
												<input
													type='text'
													className='form-control'
													defaultValue={reflink?.refLink?.message}
													readOnly
												/>
											</div>

											<button
												onClick={handleClick}
												type='button'
												className='btn btn-block btn-outline-success btn-sm'
												style={{
													whiteSpace: "nowrap",
													overflow: "hidden",
													textOverflow: "ellipsis",
												}}>
												<b>{isCopied ? "Copied" : "Copy referral link"}</b>{" "}
											</button>
										</li>
									) : null}
								</ul>
								<Button
									onClick={() => handleShowForm("nok")}
									className='btn btn-success btn-block'>
									<b>Edit Profile</b>
								</Button>
								{!user?.bankDetails && (
									<Button
										onClick={() => handleShowForm("bank")}
										className='btn btn-success btn-block'>
										<b>Add Bank Detail</b>
									</Button>
								)}
							</div>
						</div>
					</div>
					<div className='col-md-9'>
						<div className='card'>
							<div className='card-header p-2'>
								<ul className='nav nav-pills'>
									<li className='nav-item'>
										<a
											className='nav-link active '
											href='#activity'
											data-toggle='tab'>
											Personal Details
										</a>
									</li>
									<li className='nav-item'>
										<a className='nav-link' href='#timeline' data-toggle='tab'>
											Employment Details
										</a>
									</li>
									<li className='nav-item'>
										<a className='nav-link' href='#nok' data-toggle='tab'>
											Next Of Kin Details
										</a>
									</li>
								</ul>
							</div>
							<div className='card-body'>
								<div className='tab-content'>
									{/* personal details tab */}
									<div className='active tab-pane' id='activity'>
										<div className='card'>
											<div className='card-body'>
												<dl className='row'>
													<dt className='col-sm-4'>Full name</dt>
													<dd className='col-sm-8'>{user?.name}</dd>
													<dt className='col-sm-4'>Date of birth</dt>
													<dd className='col-sm-8'>{user?.birthDate}</dd>

													<dt className='col-sm-4'>Email</dt>
													<dd className='col-sm-8'>{user?.email}</dd>
													<dt className='col-sm-4'>Phone number</dt>
													<dd className='col-sm-8'>{user?.phone}</dd>
													<dt className='col-sm-4'>Home address</dt>
													<dd className='col-sm-8'>{user?.homeAddress}</dd>
												</dl>
											</div>
										</div>
									</div>

									{/* personaldetails end */}
									<div className='tab-pane' id='timeline'>
										<div className='card'>
											<div className='card-body'>
												<dl className='row'>
													<dt className='col-sm-6'>Name of organisation</dt>
													<dd className='col-sm-6'>
														{user?.employmentDetails.organisationName}
													</dd>
													<dt className='col-sm-6'>campus Name</dt>
													<dd className='col-sm-6'>
														{user?.employmentDetails?.campusName}
													</dd>

													<dt className='col-sm-6'>Faculty</dt>
													<dd className='col-sm-6'>
														{user?.employmentDetails.faculty}
													</dd>

													<dt className='col-sm-6'>Department</dt>
													<dd className='col-sm-6'>
														{user?.employmentDetails.department}
													</dd>
													<dt className='col-sm-6'>Rank/Designation</dt>
													<dd className='col-sm-6'>
														{user?.employmentDetails.gradeLevel}
													</dd>

													<dt className='col-sm-6'>Step</dt>
													<dd className='col-sm-6'>
														{user?.employmentDetails?.step}
													</dd>
													<dt className='col-sm-6'>Date of retirement</dt>
													<dd className='col-sm-6'>
														{user?.employmentDetails.retirementDate}
													</dd>
													<dt className='col-sm-6'>IPPIS Number</dt>
													<dd className='col-sm-6'>
														{user?.employmentDetails.ippisNum}
													</dd>
													<dt className='col-sm-6'>Salary Structure</dt>
													<dd className='col-sm-6'>
														{user?.employmentDetails.salaryStructure}
													</dd>
													<dt className='col-sm-6'>Category</dt>
													<dd className='col-sm-6'>{user?.category}</dd>
													<dt className='col-sm-6'>
														Date of Assumption of duty{" "}
													</dt>
													<dd className='col-sm-6'>
														{user?.employmentDetails.assumptionOfdutyDate}
													</dd>
												</dl>
											</div>
										</div>
									</div>
									<div className='tab-pane' id='nok'>
										<div className='card'>
											<div className='card-body'>
												<dl className='row'>
													<dt className='col-sm-6'>NOK 1</dt>
													<dd className='col-sm-6'>{user?.nok?.nok1}</dd>
													<dt className='col-sm-6'>NOK 1 Address</dt>
													<dd className='col-sm-6'>{user?.nok?.nokAddress1}</dd>

													<dt className='col-sm-6'>NOK 1 Email</dt>
													<dd className='col-sm-6'>{user?.nok?.nokEmail1}</dd>

													<dt className='col-sm-6'>NOK 1 Phone </dt>
													<dd className='col-sm-6'>{user?.nok?.nokPhone1}</dd>
													<dt className='col-sm-6'>NOK 1 Relationship </dt>
													<dd className='col-sm-6'>
														{user?.nok?.relationship1}
													</dd>
												</dl>
												<hr />
												<dl className='row'>
													<dt className='col-sm-6'>NOK 2</dt>
													<dd className='col-sm-6'>{user?.nok?.nok2}</dd>
													<dt className='col-sm-6'>NOK 2 Address</dt>
													<dd className='col-sm-6'>{user?.nok?.nokAddress2}</dd>

													<dt className='col-sm-6'>NOK 2 Email</dt>
													<dd className='col-sm-6'>{user?.nok?.nokEmail2}</dd>

													<dt className='col-sm-6'>NOK 2 Phone </dt>
													<dd className='col-sm-6'>{user?.nok?.nokPhone2}</dd>
													<dt className='col-sm-6'>NOK 2 Relationship </dt>
													<dd className='col-sm-6'>
														{user?.nok?.relationship2}
													</dd>
												</dl>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Row>
			</section>
			<CustomModal
				hide={handleHideForm}
				show={showForm}
				size={formtype === "nok" ? "lg" : "md"}
				title={formtype === "nok" ? "Add Next of Kin" : "Add Bank Information"}>
				{formtype === "nok" && (
					<EditProfile id={user?._id} hide={handleHideForm} type={formtype} />
				)}

				{formtype === "bank" && (
					<AddBankInfo id={user?._id} hide={handleHideForm} type={formtype} />
				)}
			</CustomModal>
		</ContentWrapper>
	);
}

export default MemberProfile;
