import React from "react";

function MissionStatement() {
	return (
		<div id='mission' className='text-center bg-light'>
			<div className='container mb-3 '>
				<div className='section-title'>
					<h2>Mission Statement</h2>
				</div>
				<div className='row justify-content-md-center'>
					<div className='col-md-8 mb-4'>
						<p>
							To provide economic, effective and efficient services that will
							assist our members bridge the gap of acute and persistent
							financial deficit through the pool of resources that will be made
							available by cooperators and driven by cutting edge technology,
							sound management of financial and other resources with integrity
							and transparency, while making sure our management team and Staff
							are optimally motivated in order to add value and deliver quality
							services to the satisfaction of all stakeholders.
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default MissionStatement;
