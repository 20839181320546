import React, { useEffect, useState } from "react";
import ContentHeader from "../views/dashboard/contents/ContentHeader";
import NewMembersTable from "./NewMemberstable";
import { useDispatch, useSelector } from "react-redux";
import {
	deleteUser,
	confirmUser,
	newRegistrations,
} from "../../store/actions/adminActions";
import { clearNotifications } from "../../store/actions/notificationsActions";
import { toast } from "react-toastify";
import Preloader from "../utilities/Preloader";
import Swal from "sweetalert2";
import { Card } from "react-bootstrap";

function UserConfirm() {
	const dispatch = useDispatch();

	const { newUsers: users, isLoading } = useSelector((state) => state.admin);

	const notification = useSelector((state) => state.notification);

	useEffect(() => {
		dispatch(newRegistrations());
	}, [dispatch]);
	useEffect(() => {
		const successMessage = notification?.success?.message;
		const errorMessage = notification?.errors?.message;

		if (successMessage) {
			Swal.fire({
				icon: "success",
				text: successMessage,
				showConfirmButton: false,
				timer: 2000,
			});
		} else if (errorMessage) {
			Swal.fire({
				icon: "error",
				text: errorMessage,
				showConfirmButton: false,
				timer: 2000,
			});
		}
		dispatch(clearNotifications());
	}, [notification, dispatch]);

	const handleDelete = (id) => {
		Swal.fire({
			title: "Are you sure you want to delete this user?",
			text: "This action cannot be undone.",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "Yes, delete",
			cancelButtonText: "Cancel",
		}).then((result) => {
			if (result.isConfirmed) {
				dispatch(deleteUser(id));
				// Swal.fire("Confirmed!", "The user has been confirmed.", "success");
			} else {
				Swal.fire("Cancelled", "The action was cancelled.", "info");
			}
		});
	};

	const handleConfirm = (id) => {
		Swal.fire({
			title: "Are you sure you want to confirm this user?",
			text: "This action cannot be undone.",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "Yes, confirm",
			cancelButtonText: "Cancel",
		}).then((result) => {
			if (result.isConfirmed) {
				dispatch(confirmUser(id));
				// Swal.fire("Confirmed!", "The user has been confirmed.", "success");
			} else {
				Swal.fire("Cancelled", "The action was cancelled.", "info");
			}
		});
	};

	return (
		<div className=' content-wrapper '>
			<ContentHeader />
			<div className='content'>
				<div className='container-fluid'>
					<Card>
						<div className='card-header'>
							<h3>Unconfirmed Registration</h3>
						</div>
						{!isLoading ? (
							<NewMembersTable
								users={users}
								handleDelete={handleDelete}
								handleConfirm={handleConfirm}
								isLoading={isLoading}
							/>
						) : (
							<Preloader />
						)}
					</Card>
				</div>
			</div>
		</div>
	);
}

export default UserConfirm;
