import React from "react";
import { Modal, Button } from "react-bootstrap";
import format from "format-number";

function LoanStatusPreview(props) {
  const { data } = props;

  const [isCopied, setIsCopied] = React.useState(false);

  async function copyTextToClipboard(text) {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  }
  const handleClick = () => {
    copyTextToClipboard(
      `https://lmcsnigltd.org.ng/guarantor-form/?loanid=${data?.transactionId}`
    )
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <Modal
      show={props.show}
      onHide={() => {
        props.handleClose();
      }}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>{props.data?.transactionId}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="nk-block">
          <div className="nk-block-between flex-wrap g-3 row">
            <div className="nk-tnx col-12">
              <span className="nk-tnx-icon bg-success-dim text-success icon ni ni-arrow-to-right" />
              <div className="nk-tnx-text">
                <div className="nk-wgacc-group flex-lg-nowrap gx-4">
                  <div className="nk-wgacc-sub">
                    <div className="nk-wgacc-amount">
                      <div className="number">
                        <h5 className="title">{data?.loanType}</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <li className="list-group-item">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={`https://lmcsnigltd.org.ng/guarantor-form/?loanid=${data?.transactionId}`}
                    readOnly
                  />
                </div>

                <button
                  onClick={handleClick}
                  type="button"
                  className="btn btn-block btn-outline-success btn-sm"
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <b>{isCopied ? "Copied" : "Copy Guarantor link"}</b>{" "}
                </button>
              </li>
            </div>
            <ul className="align-center flex-wrap gx-3">
              <li>
                <span className="badge badge-sm badge-success">
                  {data?.status}
                </span>
              </li>
            </ul>
          </div>
          <hr />
          <div className="divider md stretched" />
          <h5 className="overline-title">Loan Details</h5>
          <div className="nk-wgacc-group flex-lg-nowrap gx-4">
            <div className="nk-wgacc-sub">
              <div className="nk-wgacc-amount">
                <div className="number">AMOUNT REQUESTED</div>
              </div>
              <div className="nk-wgacc-subtitle">LOAN PERIOD</div>
            </div>
            <div className="nk-wgacc-sub">
              <span className="nk-wgacc-sign text-soft">
                <em className="icon ni ni-plus" />
              </span>
              <div className="nk-wgacc-amount">
                <div className="number">
                  <span className="sub-text mt-n1">
                    {`\u{020A6}` +
                      format({ prefix: "", suffix: ".00" })(data?.amount)}{" "}
                  </span>
                </div>
              </div>
              <div className="nk-wgacc-subtitle">
                {data?.duration}
                {""} months
              </div>
            </div>
          </div>

          <hr />
          <div className="divider md stretched" />
          <h5 className="overline-title">GUARANTORS</h5>

          <div className="divider md stretched" />
          <div className="notes">
            <div className="nk-wgacc-group flex-lg-nowrap gx-4">
              <div className="nk-wgacc-sub">
                <div className="nk-wgacc-subtitle">Guarantor 1</div>
                <div className="nk-wgacc-subtitle">Guarantor 2</div>
              </div>
              <div className="nk-wgacc-sub">
                <span className="nk-wgacc-sign text-soft">
                  <em className="icon ni ni-plus" />
                </span>
                <div className="nk-wgacc-amount">
                  <div className="number">{data?.package}</div>
                </div>
                <div className="nk-wgacc-subtitle">{data?.percent3}</div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn btn-md btn-danger"
          onClick={() => {
            props.handleClose();
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default LoanStatusPreview;
