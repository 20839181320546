import React from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Card, Table, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import ContentWrapper from '../../utilities/ContentWrapper';

const Invoice = () => {
    const { id } = useParams();
    const location = useLocation();
    const history = useHistory();
    const approvedRequests = useSelector((state) => state.admin.approvedCommodityRequests);
    const invoiceDetails = location.state?.invoiceDetails || approvedRequests.find((request) => request._id === id);

    if (!invoiceDetails) {
        return <div className="text-center p-4">Invoice details not found!</div>;
    }

    const formatCurrency = (amount) => new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(amount);

    const formatDate = (dateString) => new Date(dateString).toLocaleDateString('en-NG', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    });

    return (
        <ContentWrapper>
            <Container className="my-5 invoice-container">
                <Card className="shadow invoice-card">
                    <Card.Header className="bg-success text-white text-center py-4">
                        <h2>LION MULTI-PURPOSE COOPERATIVE SOCIETY NIGERIA LIMITED</h2>
                        <p className="mb-0">Suite 116, Continuing Education Centre (CEC) UNGEL Guest House,<br />
                            University of Nigeria, Nsukka, Enugu State, Nigeria</p>
                    </Card.Header>

                    <Card.Body className="position-relative">
                        <div className="watermark">
                            <img src="/img/approved-light-watermark.png" alt="Approved Seal" />
                        </div>
                        <h3 className="text-center font-weight-bold mb-4">Commodity Request Invoice</h3>

                        <Row className="mb-4">
                            <Col md={6}>
                                <h5 className="font-weight-bold">Billed To:</h5>
                                <p><strong>{invoiceDetails.user.name}</strong></p>
                                <p>Member ID: {invoiceDetails.userId}</p>
                                <p>Email: {invoiceDetails.user.email}</p>
                            </Col>
                            <Col md={6} className="text-md-right">
                                <h5 className="font-weight-bold">Invoice Details:</h5>
                                <p>Invoice Number: <strong>INV-{invoiceDetails._id.slice(-6).toUpperCase()}</strong></p>
                                <p>Date: {formatDate(invoiceDetails.createdAt)}</p>
                                <p>Repayment Plan: {invoiceDetails.repaymentPlan}</p>
                            </Col>
                        </Row>

                        <Table striped bordered hover responsive className="invoice-table">
                            <thead className="bg-dark text-white">
                                <tr>
                                    <th>Item</th>
                                    <th>Quantity</th>
                                    <th>Unit Price</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {invoiceDetails.items.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.name} ({item.variation})</td>
                                        <td>{item.quantity}</td>
                                        <td>{formatCurrency(item.price / item.quantity)}</td>
                                        <td>{formatCurrency(item.price)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>

                        <Row className="mt-4">
                            <Col md={6}>
                                <h5 className="font-weight-bold">Payment Terms:</h5>
                                <p>Repayment Plan: {invoiceDetails.repaymentPlan}</p>
                                <p>Due Date: {formatDate(new Date(new Date(invoiceDetails.createdAt).setMonth(new Date(invoiceDetails.createdAt).getMonth() + 1)))}</p>
                            </Col>
                            <Col md={6}>
                                <Table bordered className="invoice-summary">
                                    <tbody>
                                        <tr>
                                            <td><strong>Subtotal:</strong></td>
                                            <td className="text-right">{formatCurrency(invoiceDetails.total)}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Markup:</strong></td>
                                            <td className="text-right">{formatCurrency(invoiceDetails.markup)}</td>
                                        </tr>
                                        <tr className="bg-light">
                                            <td><strong>Grand Total:</strong></td>
                                            <td className="text-right"><strong>{formatCurrency(invoiceDetails.grandtotal)}</strong></td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Card.Body>

                    <Card.Footer className="bg-light">
                        <Row>
                            <Col>
                                <Button variant="secondary" onClick={() => history.goBack()} className="print-hidden">
                                    <i className="fas fa-arrow-left mr-2"></i> Go Back
                                </Button>
                            </Col>
                            <Col className="text-right">
                                <Button variant="primary" onClick={() => window.print()} className="print-hidden">
                                    <i className="fas fa-print mr-2"></i> Print Invoice
                                </Button>
                            </Col>
                        </Row>
                    </Card.Footer>
                </Card>
            </Container>

            <style jsx global>{`
                @media print {
                    body * {
                        visibility: hidden;
                    }
                    .invoice-container, .invoice-container * {
                        visibility: visible;
                    }
                    .invoice-container {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                    }
                    .invoice-card {
                        box-shadow: none !important;
                        border: none !important;
                    }
                    .print-hidden {
                        display: none !important;
                    }
                    .invoice-table, .invoice-summary {
                        width: 100% !important;
                        border-collapse: collapse !important;
                    }
                    .invoice-table th, .invoice-table td,
                    .invoice-summary th, .invoice-summary td {
                        border: 1px solid #dee2e6 !important;
                        padding: 0.5rem !important;
                    }
                    .invoice-table thead {
                        background-color: #343a40 !important;
                        color: #ffffff !important;
                        -webkit-print-color-adjust: exact !important;
                        print-color-adjust: exact !important;
                    }
                    .bg-success {
                        background-color: #28a745 !important;
                        color: #ffffff !important;
                        -webkit-print-color-adjust: exact !important;
                        print-color-adjust: exact !important;
                    }
                    .bg-light {
                        background-color: #f8f9fa !important;
                        -webkit-print-color-adjust: exact !important;
                        print-color-adjust: exact !important;
                    }
                    .card-header, .card-body, .card-footer {
                        padding: 1rem !important;
                    }
                    .watermark {
                        display: block !important;
                        position: absolute !important;
                        top: 50% !important;
                        left: 50% !important;
                        transform: translate(-50%, -50%) !important;
                        opacity: 0.1 !important;
                        z-index: 1000 !important;
                        pointer-events: none !important;
                        -webkit-print-color-adjust: exact !important;
                        print-color-adjust: exact !important;
                    }
                    .watermark img {
                        width: 50% !important;
                        height: auto !important;
                    }
                }
                .watermark {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    opacity: 0.5;
                    pointer-events: none;
                    z-index: 1000;
                }
                .watermark img {
                    width: 50%;
                    height: auto;
                }
                @page {
                    size: auto;
                    margin: 0mm;
                }
            `}</style>


        </ContentWrapper>
    );
};

export default Invoice;