import React, { Component } from "react";
import PaymentDetails from "./PaymentDetails";
import { ToastContainer, toast } from "react-toastify";
import PersonalDetails from "./PersonalDetails.jsx";
import EmploymentDetails from "./EmploymentDetails";
import { Container, Row, Card } from "react-bootstrap";
import { Link, Redirect } from "react-router-dom";
import { getReferer, signUp } from "../../../store/actions/authActions";
import { connect } from "react-redux";
import { clearNotifications } from "../../../store/actions/notificationsActions";
import RegistrationGuide from "../../utilities/RegistrationGuide";
import { storeformData } from "../../../store/actions/authActions";
import Swal from "sweetalert2";

const renderForm = (
	formstep,
	passportURL,
	signatureURL,
	error,
	errors,
	terms,
	disableButton,
	data,
	refererName,
	handleChange,
	handleSubmit,
	nextStep,
	prevStep
) => {
	switch (formstep) {
		case 1:
			return (
				<PaymentDetails
					nextStep={nextStep}
					handleChange={handleChange}
					error={error}
					disableButton={disableButton}
					data={data}
				/>
			);
		case 2:
			return (
				<EmploymentDetails
					nextStep={nextStep}
					prevStep={prevStep}
					handleChange={handleChange}
					error={error}
					disableButton={disableButton}
					data={data}
				/>
			);
		case 3:
			return (
				<PersonalDetails
					nextStep={nextStep}
					prevStep={prevStep}
					handleChange={handleChange}
					handleSubmit={handleSubmit}
					submitError={errors}
					error={error}
					terms={terms}
					refererName={refererName}
					disableButton={disableButton}
					passportURL={passportURL}
					signatureURL={signatureURL}
					data={data}
				/>
			);
		default:
	}
};

const steps = [1];
export class Registration extends Component {
	state = {
		tellerName: "",
		tellerRefNum: "",
		bankName: "",
		paymentDate: "",
		amountPaid: "",
		organisationName: "",
		rank: "",
		gradeLevel: "",
		retirementDate: "",
		step: "",
		campusName: "",
		assumptionDate: "",
		ippisNum: "",
		salaryStructure: "",
		faculty: "",
		department: "",
		staffNum: "",
		fullName: "",
		email: "",
		homeAddress: "",
		signature: "",
		signatureURL: "",
		passport: "",
		passportURL: "",
		phone: "",
		password: "",
		confirmPassword: "",
		birthDate: "",
		category: "",
		title: "",
		gender: "",
		formstep: 1,
		terms: false,
		error: {},
		disableButton: true,
		regGuideModal: false,
		refererId: "",
		refererName: "",
	};

	componentDidMount() {
		const queryString = window.location.search;
		const urlParam = new URLSearchParams(queryString);
		const ref = urlParam.get("ref");
		if (ref) {
			this.props.getref(ref);
		}
	}
	UNSAFE_componentWillReceiveProps(nextProps) {
		console.log(nextProps);
		if (nextProps?.referer?.user) {
			const { user } = nextProps?.referer;
			this.setState({ refererId: user._id, refererName: user.name });
		}
		if (nextProps?.errors?.message) {
			const { message } = nextProps?.errors;
			toast.error(message);
			return this.props.clearNotifications();
		}

		const submissionSuccess = nextProps.success?.message;


		if (submissionSuccess) {
			this.showRedirectAlert()
			return this.props.clearNotifications();
		}
	}

	getFields = (steps) => {
		const {
			tellerName,
			tellerRefNum,
			bankName,
			paymentDate,
			amountPaid,
			organisationName,
			rank,
			gradeLevel,
			retirementDate,
			step,
			campusName,
			assumptionDate,
			ippisNum,
			salaryStructure,
			faculty,
			department,
			staffNum,
			category,
		} = this.state;

		switch (steps) {
			case 1:
				return {
					tellerName,
					tellerRefNum,
					bankName,
					paymentDate,
					amountPaid,
				};
			case 2:
				return {
					organisationName,
					rank,
					gradeLevel,
					retirementDate,
					step,
					campusName,
					assumptionDate,
					ippisNum,
					salaryStructure,
					faculty,
					department,
					staffNum,
					category,
				};
			case 3:
				return {};

			default:
				return null;
		}
	};
	nextStep = () => {
		const { formstep } = this.state;
		if (formstep)
			this.setState({ formstep: formstep + 1 }, () => {
				steps.push(formstep + 1);
				this.result();
				if (!this.result()) this.setState({ disableButton: true });
			});

		this.props.storeformData(this.state);
	};
	prevStep = (event) => {
		const { formstep } = this.state;
		this.setState({ formstep: formstep - 1 }, () => {
			steps.pop();
			this.result();
			this.handleChange(event);
		});
	};

	result = () => {
		const { formstep } = this.state;
		const fields = this.getFields(formstep);
		const fieldNames = Object.keys(fields);
		return fieldNames.every((fieldname) => {
			return this.props.data[fieldname]?.length > 0;
		});
	};
	handleChange = (event) => {
		const result = () => {
			const { formstep } = this.state;
			const fields = this.getFields(formstep);
			const fieldNames = Object.keys(fields);

			return fieldNames.every((fieldname) => {
				return this.state[fieldname]?.length > 0;
			});
		};

		if (event?.target?.files) {
			const file = event?.target?.files[0];

			// if (file.size > 1048576) {
			//   console.log(file.size);
			//   // this.setState({ [event.target.name]: "file cannot exceed 1mb" });
			// }

			this.setState({ [event?.target?.name]: file });
			this.setState({
				[[event?.target?.name] + "URL"]: URL.createObjectURL(file),
			});
		} else if (event?.target?.name === "terms") {
			if (event?.target?.checked) {
				this.setState({
					[event?.target?.name]: true,
				});
			} else {
				this.setState({ [event?.target?.name]: false });
			}
		} else {
			this.setState({ [event?.target?.name]: event?.target?.value }, () => {
				if (this.result() || result()) {
					this.setState({ disableButton: false });
				}
			});
		}

		if (!!this.state.error[event?.target?.name])
			this.setState({
				...this.state.error,
				[event.target.name]: null,
			});
	};

	findError = () => {
		const {
			tellerName,
			tellerRefNum,
			bankName,
			paymentDate,
			amountPaid,
			organisationName,
			rank,
			gradeLevel,
			retirementDate,
			step,
			campusName,
			assumptionDate,
			ippisNum,
			salaryStructure,
			faculty,
			department,
			staffNum,
			fullName,
			email,
			homeAddress,
			signature,
			passport,
			password,
			confirmPassword,
			birthDate,
			category,
			phone,
			title,
			gender,
			terms,
		} = this.state;
		const newErrors = {};
		if (!tellerName || tellerName === "") {
			newErrors.tellerName = "cannot be blank!";
		}
		if (!tellerRefNum || tellerRefNum === "") {
			newErrors.tellerRefNum = "cannot be blank!";
		}
		if (!bankName || bankName === "") {
			newErrors.bankName = "cannot be blank!";
		}
		if (!paymentDate || paymentDate === "") {
			newErrors.paymentDate = "cannot be blank!";
		}
		if (!amountPaid || amountPaid === "") {
			newErrors.amountPaid = "cannot be blank!";
		} else if (amountPaid !== "2000") {
			newErrors.amountPaid = "amount should be 2000";
		}
		if (!organisationName || organisationName === "") {
			newErrors.organisationName = "cannot be blank!";
		}
		if (!rank || rank === "") {
			newErrors.rank = "cannot be blank!";
		}
		if (!gradeLevel || gradeLevel === "") {
			newErrors.gradeLevel = "cannot be blank!";
		}
		if (!retirementDate || retirementDate === "") {
			newErrors.retirementDate = "cannot be blank!";
		}
		if (!step || step === "") {
			newErrors.step = "cannot be blank!";
		}
		if (!campusName || campusName === "") {
			newErrors.campusName = "cannot be blank!";
		}
		if (!assumptionDate || assumptionDate === "") {
			newErrors.assumptionDate = "cannot be blank!";
		}
		if (!ippisNum || ippisNum === "") {
			newErrors.ippisNum = "cannot be blank!";
		}
		if (!salaryStructure || salaryStructure === "") {
			newErrors.salaryStructure = "cannot be blank!";
		}
		if (!faculty || faculty === "") {
			newErrors.faculty = "cannot be blank!";
		}
		if (!department || department === "") {
			newErrors.department = "cannot be blank!";
		}
		if (!staffNum || staffNum === "") {
			newErrors.staffNum = "cannot be blank!";
		}
		if (!fullName || fullName === "") {
			newErrors.fullName = "cannot be blank!";
		}
		if (!email || email === "") {
			newErrors.email = "cannot be blank!";
		}
		if (!homeAddress || homeAddress === "") {
			newErrors.homeAddress = "cannot be blank!";
		}
		if (!signature || signature === "") {
			newErrors.signature = "cannot be blank!";
		}
		if (!passport || passport === "") {
			newErrors.passport = "cannot be blank!";
		}
		if (!password || password === "") {
			newErrors.password = "cannot be blank!";
		}
		if (!confirmPassword || confirmPassword === "") {
			newErrors.confirmPassword = "cannot be blank!";
		}
		if (!birthDate || birthDate === "") {
			newErrors.birthDate = "cannot be blank!";
		}
		if (!category || category === "") {
			newErrors.category = "cannot be blank!";
		}
		if (!phone || phone === "") {
			newErrors.phone = "cannot be blank!";
		}
		if (!title || title === "") {
			newErrors.title = "cannot be blank!";
		}
		if (!gender || gender === "") {
			newErrors.gender = "cannot be blank!";
		}
		if (!terms) {
			newErrors.terms = "please accept our terms";
		}

		return newErrors;
	};
	handleSubmit = (e) => {
		e.preventDefault();

		const newErrors = this.findError();
		if (Object.keys(newErrors).length > 0) {
			this.setState({ error: newErrors });
		} else {
			const { organisationName, category } = this.state;
			let firstNumIndex = this.state.staffNum.search(/\d/);

			let sliced = this.state.staffNum.slice(firstNumIndex);
			let staffNumber;
			if (
				organisationName === "University of Nigeria" &&
				category === "Senior"
			) {
				staffNumber = `UNP/SS. ${sliced}`;
			}
			if (
				organisationName === "University of Nigeria" &&
				category === "Junior"
			) {
				staffNumber = `UNP/JS. ${sliced}`;
			}
			if (organisationName !== "University of Nigeria") {
				staffNumber = this.state.staffNum;
			}

			const formData = new FormData();

			formData.append("fullname", this.state.fullName);
			formData.append("email", this.state.email);
			formData.append("tellername", this.state.tellerName);
			formData.append("tellerRefNum", this.state.tellerRefNum);
			formData.append("bankname", this.state.bankName);
			formData.append("paymentDate", this.state.paymentDate);
			formData.append("amount", this.state.amountPaid);
			formData.append("organisationName", this.state.organisationName);
			formData.append("rank", this.state.rank);
			formData.append("gradeLevel", this.state.gradeLevel);
			formData.append("retirementDate", this.state.retirementDate);
			formData.append("step", this.state.step);
			formData.append("campusName", this.state.campusName);
			formData.append("assumptionOfdutyDate", this.state.assumptionDate);
			formData.append("ippisNum", this.state.ippisNum);
			formData.append("salaryStructure", this.state.salaryStructure);
			formData.append("faculty", this.state.faculty);
			formData.append("department", this.state.department);
			formData.append("staffNum", staffNumber);
			formData.append("homeAddress", this.state.homeAddress);
			formData.append("signature", this.state.signature);
			formData.append("passport", this.state.passport);
			formData.append("password", this.state.password);
			formData.append("confirmpassword", this.state.confirmPassword);
			formData.append("birthDate", this.state.birthDate);
			formData.append("category", this.state.category);
			formData.append("phone", this.state.phone);
			formData.append("gender", this.state.gender);
			formData.append("title", this.state.title);
			formData.append("refererId", this.state.refererId);

			this.props.signup(formData);
		}
	};

	handleShowModal = () => {
		this.setState({ regGuideModal: !this.regGuideModal }, () => { });
	};
	handleCloseModal = () => {
		this.setState({ regGuideModal: false }, () => { });
	};

	showRedirectAlert = () => {
		Swal.fire({
			title: 'Registration sucessful',
			text: 'Click "Okay" to continue to login',
			icon: 'success',
			confirmButtonText: 'Okay',
			allowOutsideClick: false,
		}).then(() => {

			window.location.href = 'https://dashboard.lmcsnigltd.org.ng/';
		});
	};
	render() {
		const {
			formstep,
			error,
			disableButton,
			passportURL,
			signatureURL,
			terms,
			refererName,
		} = this.state;
		const { data } = this.props;

		// if (this.props.user) {
		// 	this.showRedirectAlert();
		// 	return null;
		// }
		return (
			<Container id='grad1'>
				<Card>
					<Row className='justify-content-center '>
						<div className=' border-0 col-md-3 register-left  p-0 mt-3 mb-2'>
							<div className='px-0 pt-4 pb-0 mt-3 mb-3 left-side rounded-left shadow'>
								<Link to='/'>
									<input type='submit' name='' value='home' />
								</Link>

								<h3>Welcome</h3>

								<p>
									Already have membership?
									<a href="https://dashboard.lmcsnigltd.org.ng">
										<input type='submit' name='' value='Sign In Instead' />
									</a>
								</p>

								<button id='reg_guide' onClick={this.handleShowModal}>
									REGISTRATION GUIDE
								</button>
							</div>
						</div>
						<div className='col-11 col-sm-9 col-md-7 col-lg-6 text-center p-0 mt-3 mb-2'>
							<Card className='px-0 pt-4 pb-0 mt-3 mb-3 shadow'>
								<h2>
									<strong>Member registration</strong>
								</h2>

								<Row>
									<div className='col-md-12 mx-0'>
										<ul id='progressbar'>
											<li className={"active"} id='payment'>
												<strong>Payment Details</strong>
											</li>
											<li
												className={steps.includes(2) ? "active" : ""}
												id='account'>
												<strong>Employment Details</strong>
											</li>
											<li
												className={steps.includes(3) ? "active" : ""}
												id='personal'>
												<strong>Personal Details</strong>
											</li>
											<li id='confirm'>
												<strong>Finish</strong>
											</li>
										</ul>

										<div id='msform'>
											{renderForm(
												formstep,
												passportURL,
												signatureURL,
												error,
												this.props.errors,
												terms,
												disableButton,
												data,
												refererName,
												this.handleChange,
												this.handleSubmit,
												this.nextStep,
												this.prevStep
											)}
										</div>
									</div>
								</Row>
							</Card>
						</div>
					</Row>
				</Card>

				<ToastContainer position='top-center' />
				<RegistrationGuide
					regGuideModal={this.state.regGuideModal}
					handleCloseModal={this.handleCloseModal}
				/>
			</Container>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		errors: state.notification.errors,
		success: state.notification.success,
		data: state.instruction.registration,
		referer: state.auth.referer,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		signup: (formdata) => dispatch(signUp(formdata)),
		clearNotifications: () => dispatch(clearNotifications()),
		storeformData: (formdata) => dispatch(storeformData(formdata)),
		getref: (ref) => dispatch(getReferer(ref)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Registration);
