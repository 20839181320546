import React from "react";
import InfoBox from "../../utilities/InfoBox";
import { useSelector } from "react-redux";
import { pioneers } from "../../../data/pioneers";

function AccountBalances({ balances }) {
	const user = useSelector((state) => state.userProfile.profile.user);
	const [isPioneer, setIsPioneer] = React.useState();
	React.useEffect(() => {
		setIsPioneer(
			pioneers.find((p) => {
				return p === user?.memberId;
			})
		);
	}, [user?.memberId]);

	let sharecapital = 0;
	let norm1 = 0,
		norm2 = 0,
		emergency = 0,
		housing = 0,
		land = 0,
		motocycle = 0,
		car = 0,
		tricycle = 0,
		electronic = 0,
		stationary = 0,
		office = 0,
		ordinarysavings = 0,
		christmassavings = 0,
		educationsavings = 0,
		retirementsavings = 0,
		promoterCar = 0,
		promoterCommodity = 0,
		promoterElectronic = 0,
		promoterEmergency = 0,
		promoterHousing = 0,
		promoterLand = 0,
		promoterMotorcycle = 0,
		promoterNorm1 = 0,
		promoterNorm2 = 0,
		promoterOffice = 0,
		promoterStationary = 0,
		promoterTricycle = 0,
		commodity = 0,
		sweatEquity = 0,
		landDevelopment = 0,
		unnShares = 0,
		specialDeposit = 0;

	balances?.forEach((balance) => {
		switch (balance.itemId?.trim()) {
			case "03010101":
				sharecapital = balance.Balance;
				break;
			case "02020305":
				ordinarysavings = balance.Balance;
				break;
			case "02010401":
				educationsavings = balance.Balance;
				break;
			case "02010402":
				christmassavings = balance.Balance;
				break;
			case "02020301":
				retirementsavings = balance.Balance;
				break;
			case "01020310":
				car = balance.Balance;
				break;
			case "01020306":
				norm1 = balance.Balance;
				break;
			case "01020314":
				norm2 = balance.Balance;
				break;
			case "01020308":
				housing = balance.Balance;
				break;
			case "01020309":
				land = balance.Balance;
				break;
			case "01020311":
				motocycle = balance.Balance;
				break;
			case "01020312":
				tricycle = balance.Balance;
				break;
			case "01020307":
				emergency = balance.Balance;
				break;
			case "01020325":
				promoterEmergency = balance.Balance;
				break;
			case "01020318":
				promoterCar = balance.Balance;
				break;
			case "01020316":
				promoterHousing = balance.Balance;
				break;
			case "01020317":
				promoterLand = balance.Balance;
				break;
			case "01020319":
				promoterMotorcycle = balance.Balance;
				break;

			case "01020313":
				promoterNorm1 = balance.Balance;
				break;

			case "01020320":
				promoterTricycle = balance.Balance;
				break;
			case "01020315":
				promoterNorm2 = balance.Balance;
				break;
			case "01020301":
				commodity = balance.Balance;
				break;
			case "01020321":
				promoterCommodity = balance.Balance;
				break;
			case "01020322":
				promoterElectronic = balance.Balance;
				break;
			case "01020304":
				electronic = balance.Balance;
				break;
			case "01020303":
				office = balance.Balance;
				break;
			case "01020324":
				promoterOffice = balance.Balance;
				break;
			case "01020323":
				promoterStationary = balance.Balance;
				break;
			case "01020302":
				stationary = balance.Balance;
				break;
			case "03010111":
				sweatEquity = balance.Balance;
				break;
			case "03010112":
				unnShares = balance.Balance;
				break;
			case "02010405":
				landDevelopment = balance.Balance;
				break;
			case "02020306":
				specialDeposit = balance.Balance;
				break;

			default:
				break;
		}
	});

	return (
		<div
			className='scrolling-wrapper row flex-row flex-nowrap mt-4 pb-4 pt-2'
			style={{ overflowX: "auto" }}>
			<InfoBox
				title='Share Capital'
				number={sharecapital}
				icon='fas fa-piggy-bank'
			/>
			<InfoBox
				title='Ordinary Savings'
				number={ordinarysavings}
				icon='fab fa-creative-commons-share'
			/>
			<InfoBox
				title='Education Savings'
				number={educationsavings}
				icon='fas fa-user-graduate'
			/>
			<InfoBox
				title='Christmas Savings'
				number={christmassavings}
				icon='fas fa-gifts'
			/>
			<InfoBox
				title='Retirement Savings'
				number={retirementsavings}
				icon='fas fa-piggy-bank'
			/>

			<InfoBox
				title='1 year Normal Loan'
				number={isPioneer ? promoterNorm1 : norm1}
				icon='fas fa-hand-holding-usd'
			/>
			<InfoBox
				title='2 year Normal Loan'
				number={isPioneer ? promoterNorm2 : norm2}
				icon='fas fa-hand-holding-usd'
			/>
			<InfoBox
				title='Emergency Loan'
				number={isPioneer ? promoterEmergency : emergency}
				icon='fas fa-hand-holding-usd'
			/>
			<InfoBox
				title='Commodity Loan'
				number={isPioneer ? promoterCommodity : commodity}
				icon='fas fa-hand-holding-usd'
			/>
			<InfoBox
				title='Housing Loan'
				number={isPioneer ? promoterHousing : housing}
				icon='fas fa-hand-holding-usd'
			/>
			<InfoBox
				title='Land Loan'
				number={isPioneer ? promoterLand : land}
				icon='fas fa-hand-holding-usd'
			/>
			<InfoBox
				title='Motorcycle Loan'
				number={isPioneer ? promoterMotorcycle : motocycle}
				icon='fas fa-hand-holding-usd'
			/>
			<InfoBox
				title='Car Loan'
				number={isPioneer ? promoterCar : car}
				icon='fas fa-hand-holding-usd'
			/>
			<InfoBox
				title='tricycle Loan'
				number={isPioneer ? promoterTricycle : tricycle}
				icon='fas fa-hand-holding-usd'
			/>
			<InfoBox
				title='Electronic Purchase Loan'
				number={isPioneer ? promoterElectronic : electronic}
				icon='fas fa-hand-holding-usd'
			/>
			<InfoBox
				title='Stationary Purchase Loan'
				number={isPioneer ? promoterStationary : stationary}
				icon='fas fa-hand-holding-usd'
			/>
			<InfoBox
				title='Office Equipment Loan'
				number={isPioneer ? promoterOffice : office}
				icon='fas fa-hand-holding-usd'
			/>
			<InfoBox
				title='Land Development Savings'
				number={landDevelopment}
				icon='fas fa-hand-holding-usd'
			/>
			<InfoBox
				title='UNN Shares'
				number={unnShares}
				icon='fas fa-hand-holding-usd'
			/>
			<InfoBox
				title='SPECIAL DEPOSIT'
				number={specialDeposit}
				icon='fas fa-hand-holding-usd'
			/>
			<InfoBox
				title='Sweat Equity'
				number={sweatEquity}
				icon='fas fa-hand-holding-usd'
			/>
		</div>
	);
}

export default AccountBalances;
