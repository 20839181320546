import React, { useEffect } from "react";
import ContentWrapper from "../utilities/ContentWrapper";
import { useDispatch, useSelector } from "react-redux";
import {
  acknowledgeLoanRequest,
  rejectLoanRequest,
} from "../../store/actions/adminActions";
import { ToastContainer, toast } from "react-toastify";
import { clearNotifications } from "../../store/actions/notificationsActions";
import AdminLoanContract from "./AdminLoanContract";
import DialogModal from "../utilities/DialogModal";
import Authority from "./Authority";

function AdminLoanPreview(props) {
  const notification = useSelector((state) => state.notification);
  const dispatch = useDispatch();

  useEffect(() => {
    const { success } = notification;
    if (success.message) {
      const { message } = notification.success;
      toast.success(message);
      return dispatch(clearNotifications());
    }
  }, [notification, dispatch]);

  const [smShow, setSmShow] = React.useState(false);
  const handleShowModal = () => {
    setSmShow(true);
  };

  const handleCloseModal = () => {
    setSmShow(false);
  };
  const handleSubmit = () => {
    dispatch(acknowledgeLoanRequest(props.location.state));
    handleCloseModal();
    const timer = setTimeout(() => {
      window.print();
    }, 10000);
    return () => clearTimeout(timer);
  };

  return (
    <ContentWrapper>
      <div className="col-12">
        <div className=" no-print callout callout-info">
          <h5>
            <i className="fas fa-info"></i> Note:
          </h5>
          please click submit at the end of the page to complete the process.
          This page has been enhanced for printing. you may wish to print this
          form.
        </div>

        <div className="invoice p-3 mb-3 col-12">
          <AdminLoanContract {...props} />
        </div>
        <br />

        <div className="invoice p-3 mb-3 col-12">
          <Authority {...props} />
        </div>
        {/* <!-- this row will not appear when printing --> */}
        <div className="row no-print" style={{ marginTop: "100px" }}>
          <div className="col-12">
            <button
              type="button"
              className="btn btn-danger "
              style={{ marginRight: "5px" }}
              onClick={() => rejectLoanRequest(props.location.state)}
            >
              <i className="fas fa-eject"></i> {""}Reject
            </button>

            <button
              type="button"
              className="btn btn-success float-right"
              style={{ marginRight: "5px" }}
              onClick={handleShowModal}
            >
              <i className="fas fa-download"></i> Acknowledge reciept
            </button>
          </div>
        </div>
      </div>
      <ToastContainer position="top-center" />
      <DialogModal
        type={props.type}
        smShow={smShow}
        handleCloseModal={handleCloseModal}
        handleSubmit={handleSubmit}
      />
    </ContentWrapper>
  );
}

export default AdminLoanPreview;
