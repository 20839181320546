import React from "react";
import { Nav, NavLink } from "react-bootstrap";
import { loadTree } from "./TreeLoader";
import { NavLink as RouterNavLink } from "react-router-dom";

function NavItems(props) {
  const { menus, user } = props;
  React.useEffect(() => {
    loadTree();
  }, []);
  const filteredMenus = menus?.filter((menu) => {
    if (user?.user?.accessLevel > 1) {
      return menu.admin || menu.admin === "both";
    } else {
      return !menu.admin || menu.admin === "both";
    }
  });

  return (
    <Nav className="mt-2">
      <ul
        className="nav nav-pills nav-sidebar flex-column"
        data-widget="treeview"
        role="menu"
        data-accordion="false"
      >
        {filteredMenus?.map((menu) =>
          menu.type === "single" ? (
            <li key={menu.id} className="nav-item">
              <RouterNavLink to={menu?.path} className="nav-link">
                <i className={menu.icon}></i>
                <p>{menu.name}</p>
              </RouterNavLink>
            </li>
          ) : (
            <li key={menu.id} className="nav-item ">
              <NavLink className="nav-link">
                <i className={menu.icon}></i>
                <p>
                  {menu.name}
                  <i className="right fas fa-angle-left"></i>
                </p>
              </NavLink>
              <ul className="nav nav-treeview">
                {menu?.inner.map((innermenu) => (
                  <li key={innermenu.id + innermenu.name} className="nav-item">
                    <RouterNavLink to={innermenu.path} className="nav-link">
                      <i className={innermenu.icon}></i>
                      <p>{innermenu.name}</p>
                    </RouterNavLink>
                  </li>
                ))}
              </ul>
            </li>
          )
        )}
      </ul>
    </Nav>
  );
}

export default NavItems;
