import React from "react";

function SearchResultTable({ users, viewProfile }) {
	return (
		<div style={{ padding: "20px" }}>
			<table className='table'>
				<thead>
					<tr>
						<th style={{ width: 10 }}>#</th>
						<th>Name</th>
						<th>Member ID</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody>
					{users.map((user, i) => (
						<tr key={user._id}>
							<td>{i + 1}</td>
							<td>{user.name}</td>
							<td>
								<div className=''>{user.memberId}</div>
							</td>
							<td>
								<span className=''>
									<button
										type='button'
										className='btn btn-outline-success'
										onClick={() => viewProfile(user._id)}>
										view profile
									</button>
								</span>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
}

export default SearchResultTable;
