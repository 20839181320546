import React, { useState } from "react";
import Moment from "react-moment";
import CustomModal from "../utilities/CustomModal";
import format from "format-number";

const NewMembersTable = ({ users, handleDelete, handleConfirm, isLoading }) => {
	const [showModal, setShowModal] = useState(false);
	const [currUser, setCurrUser] = useState();

	function handleShowModal(user) {
		setShowModal(true);
		setCurrUser(user);
	}

	function handleHideModal() {
		setShowModal(false);
	}

	return (
		<div style={{ padding: "20px" }}>
			<table className='table'>
				<thead>
					<tr>
						<th style={{ width: 10 }}>#</th>
						<th>Name</th>
						<th>Joined</th>
						<th style={{ width: 40 }}>Actions</th>
					</tr>
				</thead>
				<tbody>
					{users.length > 0 && !isLoading ? (
						users.map((user, i) => (
							<tr key={user._id}>
								<td>{i + 1}</td>
								<td>{user.name}</td>
								<td>
									<div className=''>
										<div className='' style={{ width: "10%" }} />
										<Moment fromNow>{user.createdAt}</Moment>
									</div>
								</td>
								<td>
									<span className=''>
										<div className='btn-group'>
											<button
												type='button'
												className='btn btn-outline-primary'
												onClick={() => handleShowModal(user)}>
												view
											</button>
											<button
												type='button'
												className='btn btn-outline-success'
												onClick={() => handleConfirm(user._id)}>
												confirm
											</button>
											<button
												type='button'
												className='btn btn-outline-danger'
												onClick={() => handleDelete(user._id)}>
												delete
											</button>
										</div>
									</span>
								</td>
							</tr>
						))
					) : (
						// Only display the message when users is empty
						<tr>
							<td colSpan='4'>
								<h3>No New Registration at this time</h3>
							</td>
						</tr>
					)}
				</tbody>
			</table>
			<CustomModal hide={handleHideModal} show={showModal} size='md'>
				<div className='m-2 p-2'>
					{/* Profile Image */}
					<div className='card card-primary card-outline'>
						<div className='card-body box-profile'>
							<div className='text-center'>
								<img
									className='profile-user-img img-fluid img-circle'
									src={currUser?.passport}
									alt='User profile'
									style={{
										height: "100px",
										width: "100px",
										objectFit: "cover",
									}}
								/>
							</div>
							<h3 className='profile-username text-center'>{currUser?.name}</h3>
							<p className='text-muted text-center'>
								{currUser?.employmentDetails?.rank} |{" "}
								{currUser?.employmentDetails?.department}
							</p>
							<ul className='list-group list-group-unbordered mb-3'>
								<li className='list-group-item'>
									<b>Phone:</b> <a className='float-right'>{currUser?.phone}</a>
								</li>
								<li className='list-group-item'>
									<b>Email:</b> <a className='float-right'>{currUser?.email}</a>
								</li>
							</ul>
						</div>
						{/* /.card-body */}
					</div>
					{/* /.card */}
					{/* About Me Box */}
					<div className='card card-primary'>
						<div className='card-header'>
							<h3 className='card-title'>Payment Details</h3>
						</div>
						{/* /.card-header */}
						<div className='card-body'>
							<strong>Bank Name</strong>
							<p className='text-muted'>{currUser?.paymentDetails?.bankName}</p>
							<hr />
							<strong>Amount</strong>
							<p className='text-muted'>
								{format({ prefix: "₦" })(currUser?.paymentDetails?.amount || 0)}
							</p>
							<hr />
							<strong>Teller Name</strong>
							<p className='text-muted'>
								{currUser?.paymentDetails?.tellerName}
							</p>
							<hr />
							<strong>Reference Number</strong>
							<p className='text-muted'>
								{currUser?.paymentDetails?.tellerRefNum}
							</p>
							<strong>Payment Date</strong>
							<p className='text-muted'>
								<Moment parse='YYYY-MM-DD HH:mm'>
									{currUser?.paymentDetails?.paymentDate}
								</Moment>
							</p>
						</div>
						{/* /.card-body */}
					</div>
					{/* /.card */}
				</div>
			</CustomModal>
		</div>
	);
};

export default NewMembersTable;
