import React, { useState, useEffect } from "react";
import NavItem from "./NavItem";

import { Link, useHistory } from "react-router-dom";
import UserPannel from "./UserPannel";
import { useSelector, useDispatch } from "react-redux";
import { findUserBySearch } from "../../../../store/actions/adminActions";
import { clearNotifications } from "../../../../store/actions/notificationsActions";
import Loader from "../../../utilities/Loader";
import Swal from "sweetalert2";
import CustomModal from "../../../utilities/CustomModal";
import SearchResultTable from "./SearchResultTable";

function SideNav(props) {
	const [search, setSearch] = useState("");
	const [isLoading, setIsloading] = useState(false);
	const [showModal, setShowModal] = useState(false);

	const history = useHistory();

	const user = useSelector((state) => state.auth.user);
	const result = useSelector((state) => state.admin.memberSearch);

	const notification = useSelector((state) => state.notification);
	const dispatch = useDispatch();

	useEffect(() => {
		const successMessage = notification?.success?.message;
		const errorMessage = notification?.errors?.message;

		if (successMessage && notification?.showModal) {
			setIsloading(false);
			setShowModal(true);
		} else if (errorMessage) {
			Swal.fire({
				icon: "error",
				text: errorMessage,
				showConfirmButton: false,
				timer: 2000,
			});
			setIsloading(false);
		}

		dispatch(clearNotifications());
	}, [notification, dispatch]);

	function handleHideModal() {
		setShowModal(false);
	}

	function viewProfile(id) {
		handleHideModal();
		history.push({
			pathname: `/dashboard/memberprofile/${id}`,
			state: {
				id,
			},
		});
	}

	const handleSearch = () => {
		setIsloading(true);
		const params = `search=${search}`;
		dispatch(findUserBySearch(params));
	};

	return (
		<aside className='main-sidebar sidebar-light-success elevation-4'>
			<Link to='/dashboard' className='brand-link'>
				<img
					src={"img/logo/unn-logo.png"}
					alt='unn Logo'
					className='brand-image  img-circle elevation-3'
				/>
				<span className='brand-text font-weight-dark'>LMCS</span>
			</Link>

			<div className='sidebar'>
				<UserPannel user={user} />
				{/* <!-- Sidebar Menu --> */}

				<div className='form-inline'>
					<div className='input-group'>
						<input
							className='form-control form-control-sidebar'
							type='search'
							placeholder='Search by name or Member ID'
							aria-label='Search'
							onChange={(e) => setSearch(e.target.value)}
						/>
						<div className='input-group-append'>
							<button className='btn btn-sidebar' onClick={handleSearch}>
								<i className='fas fa-search fa-fw'></i>
							</button>
						</div>
					</div>
				</div>
				<NavItem {...props} menus={props.data} />
			</div>
			<Loader isLoading={isLoading} />
			<CustomModal hide={handleHideModal} show={showModal} size='lg'>
				<SearchResultTable users={result} viewProfile={viewProfile} />
			</CustomModal>
		</aside>
	);
}

export default SideNav;
