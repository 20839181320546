import React, { useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { resetpassword, checkLink } from "../../../store/actions/authActions";
import Preloader from "../../utilities/Preloader";
import ExpiredLinkPage from "./ExpiredLinkPage";
import ResetPassword from "./ResetPassword";

function ChangePassword() {
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [run, setRun] = useState(true);

  const dispatch = useDispatch();

  const notification = useSelector((state) => state.notification);

  const queryString = window.location.search;
  const urlParam = new URLSearchParams(queryString);
  const token = urlParam.get("token");
  const id = urlParam.get("id");
  const email = urlParam.get("email");

  useEffect(() => {
    if (run) {
      dispatch(checkLink(token));
    }
    setRun(false);
  }, [dispatch, run, token]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      resetpassword({
        password: password,
        confirmpassword: confirmpassword,
        id: id,
        email: email,
      })
    );
  };

  const handleSetPassword = (pass) => {
    setPassword(pass);
  };

  const handleSetConfirmPassword = (pass) => {
    setConfirmPassword(pass);
  };
  const renderDiv = () => {
    if (notification?.success.message) {
      return (
        <ResetPassword
          setConfirmPassword={handleSetConfirmPassword}
          setPassword={handleSetPassword}
          handleSubmit={handleSubmit}
        />
      );
    }
    if (notification?.errors?.message === "link expired or invalid") {
      return <ExpiredLinkPage />;
    }
  };

  if (notification?.errors?.message || notification?.success?.message) {
    return renderDiv();
  } else {
    return <Preloader />;
  }
}

export default ChangePassword;
