import React from "react";
import Preloader from "../../utilities/Preloader";
import { useSelector } from "react-redux";
import format from "format-number";

function Paper({ state }) {
	const user = useSelector((state) => state.userProfile.profile.user);

	const salarystruc = user?.employmentDetails?.salaryStructure;

	return user && user ? (
		<div style={{ fontSize: "1.7rem" }} className='m-4 p-2'>
			<div style={{ textAlign: "center" }}>
				<h4>
					<strong>
						LION MULTI-PURPOSE COOPERATIVE SOCIETY NIGERIA LIMITED
					</strong>
				</h4>
				<b>
					Suite 116, Continuing Education Centre (CEC), UNGEL Guest House,
					University of Nigeria Main Campus, Nsukka.
				</b>
				<br />
				<br />
				<br />
				<h4>
					<b>DEBIT INSTRUCTIONS FOR SAVINGS AND SHARE CAPITAL</b>
				</h4>

				<br />
			</div>

			<div style={{ textAlign: "left" }}>
				The president, Lion Multi-Purpose Cooperative Society Nig. Ltd
				<br />
				Sir/Ma <br /> Please accept this as my instruction to debit my salary
				account with {user.employmentDetails.organisationName},
				{user.employmentDetails.organisationName === "University of Nigeria"
					? "Nsukka"
					: null}
				.
				<br />
				<br />
				<dl className='row'>
					<dt className='col-sm-6'>Name</dt>
					<dd className='col-sm-6'>{user?.name}</dd>
					<dt className='col-sm-6'>LMCS Membership ID</dt>
					<dd className='col-sm-6'>{user?.memberId}</dd>
					<dt className='col-sm-6'>Faculty</dt>
					<dd className='col-sm-6'>{user?.employmentDetails.faculty}</dd>
					<dt className='col-sm-6'>Department</dt>
					<dd className='col-sm-6'>{user?.employmentDetails.department}</dd>
					<dt className='col-sm-6'>Salary Structure</dt>
					<dd className='col-sm-6'>{salarystruc.toUpperCase()}</dd>
				</dl>
				<br />
				<b>1. Share Capital</b> <br />
				Please debit my salary account with the sum of{" "}
				<b>{format({ prefix: "₦" })(state.shareCapitalAmount || 0)}</b> every
				month for <b> {state.shareCapitalMonths || 0}</b> month(s) as part of my
				share capital. (Please note that share Capital contribution is
				compulsory & not less than N5,000/month, contributable for at least 20
				months. Minimum share capital after 20months must be up to N100,000 and
				the upper limit for share capital is N5,000,000).
				<br />
				<b>2. Ordinary Savings</b> <br />
				Please debit my salary account with the sum of{" "}
				<b>{format({ prefix: "₦" })(state.ordinarySavingsAmount || 0)}</b> every
				month as part of my ordinary Savings. (Please note that amount
				contributable as ordinary savings is compulsory & not less than
				N5,000/month with no upper limit.)
				<br />
				<b>3. Christmas Savings</b> <br />
				Please debit my salary account with the sum of{" "}
				<b>
					{format({ prefix: "₦" })(state.christmasSavingsAmount || 0)}{" "}
				</b>{" "}
				every month for
				<b>{state.christmasSavingsMonths || 0}</b> months as part of my ordinary
				Savings. (Please note that amount contributable as ordinary savings is
				compulsory & not less than N5,000/month with no upper limit.)
				<br />
				<b>4. Education Savings</b> <br />
				Please debit my salary account with NGN{" "}
				<b>{format({ prefix: "₦" })(state.educationSavingsAmount || 0)}</b>{" "}
				every month for {state.educationSavingsMonths || 0}.months as part of my
				annual withdrawable Education savings (please note that annual
				withdrawable Education savings is optional).
				<br />
				<b>5. Retirement Savings</b> <br />
				Please debit my salary account with NGN{" "}
				<b>{format({ prefix: "₦" })(state.retirementSavingsAmount || 0)}</b>.
				every month for {state.retirementSavingsMonths || 0}.months as part of
				my Retirement savings for 10years or any other number of years exceeding
				10years for tenured/withdrawable retirement savings (please note that
				tenured/withdrawable Retirement savings is optional).
				<b>
					<br />
					6. Land Development Savings
				</b>{" "}
				<br />
				Please debit my salary account with NGN{" "}
				<b>{format({ prefix: "₦" })(state.landDevSavingsAmount || 0)}</b>. every
				month for {state.landDevSavingsMonths || 0}.months as part of my Land
				Development savings. (please note that annual withdrawable Land
				Development savings is optional).
			</div>
		</div>
	) : (
		<Preloader />
	);
}

export default Paper;
