import React from "react";
import { Row, Button } from "react-bootstrap";
import ContentWrapper from "../utilities/ContentWrapper";
import { useDispatch, useSelector } from "react-redux";
import { getUserById } from "../../store/actions/adminActions";
import EditUserProfile from "./EditUserProfile";
import CustomModal from "../utilities/CustomModal";
import EditBankInfo from "./EditBankInfo";

function UserProfile(props) {
	const { id } = props.location.state;
	const { member: user } = useSelector((state) => state.admin);

	const dispatch = useDispatch();
	React.useEffect(() => {
		dispatch(getUserById(id));
	}, [dispatch, id]);

	const [showForm, setShowForm] = React.useState(false);
	const [showBankAccount, setShowBankAccount] = React.useState(false);

	const handleShowForm = () => {
		setShowForm(true);
	};
	const handleHideForm = () => {
		setShowForm(false);
	};

	return (
		<ContentWrapper title='profile overwiew'>
			<section className='content  no-print'>
				<Row>
					<div className='col-md-3'>
						<div className='card card-success card-outline'>
							<div className='card-body box-profile'>
								<div className='text-center'>
									<div>
										<img
											className='profile-user-img img-fluid img-circle'
											src={user?.passport}
											alt='User profile'
											style={{
												height: "100px",
												width: "100px",
												objectFit: "cover",
											}}
										/>
									</div>
								</div>

								<h3 className='profile-username text-center'>{user?.name} </h3>
								<p className='text-muted text-center'>{user?.memberId}</p>

								<ul className='list-group list-group-unbordered mb-3'>
									<li className='list-group-item'>
										<button
											onClick={() => {
												props.history.push({
													pathname: `/print-request`,
													state: { user: user, type: "initial-instrutions" },
												});
											}}
											type='button'
											className='btn btn-block btn-outline-info btn-sm'
											style={{
												whiteSpace: "nowrap",
												overflow: "hidden",
												textOverflow: "ellipsis",
											}}>
											<b>Debit Instructions</b>{" "}
										</button>
									</li>
									<li className='list-group-item'>
										<button
											onClick={() => {
												setShowBankAccount(true);
											}}
											type='button'
											className='btn btn-block btn-outline-info btn-sm'
											style={{
												whiteSpace: "nowrap",
												overflow: "hidden",
												textOverflow: "ellipsis",
											}}>
											<b>Bank Account</b>{" "}
										</button>
									</li>
								</ul>

								<Button
									className='btn btn-success btn-block'
									onClick={handleShowForm}>
									<b>Edit Profile</b>
								</Button>
							</div>
						</div>
					</div>
					<div className='col-md-9'>
						<div className='card'>
							<div className='card-header p-2'>
								<ul className='nav nav-pills'>
									<li className='nav-item'>
										<a
											className='nav-link active'
											href='#activity'
											data-toggle='tab'>
											Personal Details
										</a>
									</li>
									<li className='nav-item'>
										<a className='nav-link' href='#timeline' data-toggle='tab'>
											Employment Details
										</a>
									</li>
									<li className='nav-item'>
										<a className='nav-link' href='#nok' data-toggle='tab'>
											Next of Kin's Info
										</a>
									</li>
								</ul>
							</div>
							<div className='card-body'>
								<div className='tab-content'>
									{/* personal details tab */}

									{!showForm ? (
										<>
											<div className='active tab-pane' id='activity'>
												<div>
													<div>
														<dl className='row   '>
															<dt className='col-sm-4'>Full name</dt>
															<dd className='col-sm-8'>{user?.name}</dd>
															<dt className='col-sm-4'>Date of birth</dt>
															<dd className='col-sm-8'>{user?.birthDate}</dd>

															<dt className='col-sm-4'>Email</dt>
															<dd className='col-sm-8'>{user?.email}</dd>
															<dt className='col-sm-4'>Phone number</dt>
															<dd className='col-sm-8'>{user?.phone}</dd>
															<dt className='col-sm-4'>Home address</dt>
															<dd className='col-sm-8'>{user?.homeAddress}</dd>
														</dl>
													</div>
												</div>
											</div>

											{/* personaldetails end */}
											<div className='tab-pane' id='timeline'>
												<div className='card'>
													<div className='card-body'>
														<dl className='row'>
															<dt className='col-sm-6'>Name of organisation</dt>
															<dd className='col-sm-6'>
																{user?.employmentDetails?.organisationName}
															</dd>
															<dt className='col-sm-6'>campus Name</dt>
															<dd className='col-sm-6'>
																{user?.employmentDetails?.campusName}
															</dd>

															<dt className='col-sm-6'>Faculty</dt>
															<dd className='col-sm-6'>
																{user?.employmentDetails?.faculty}
															</dd>

															<dt className='col-sm-6'>Department</dt>
															<dd className='col-sm-6'>
																{user?.employmentDetails?.department}
															</dd>
															<dt className='col-sm-6'>Rank/Designation</dt>
															<dd className='col-sm-6'>
																{user?.employmentDetails?.gradeLevel}
															</dd>

															<dt className='col-sm-6'>Step</dt>
															<dd className='col-sm-6'>
																{user?.employmentDetails?.step}
															</dd>
															<dt className='col-sm-6'>Date of retirement</dt>
															<dd className='col-sm-6'>
																{user?.employmentDetails?.retirementDate}
															</dd>
															<dt className='col-sm-6'>IPPIS Number</dt>
															<dd className='col-sm-6'>
																{user?.employmentDetails?.ippisNum}
															</dd>
															<dt className='col-sm-6'>Salary Structure</dt>
															<dd className='col-sm-6'>
																{user?.employmentDetails?.salaryStructure}
															</dd>
															<dt className='col-sm-6'>Category</dt>
															<dd className='col-sm-6'>{user?.category}</dd>
															<dt className='col-sm-6'>
																Date of Assumption of duty{" "}
															</dt>
															<dd className='col-sm-6'>
																{user?.employmentDetails?.assumptionOfdutyDate}
															</dd>
														</dl>
													</div>
												</div>
											</div>

											<div className='tab-pane' id='nok'>
												<div className='card'>
													<div className='card-body'>
														<dl className='row'>
															<dt className='col-sm-6'>NOK 1</dt>
															<dd className='col-sm-6'>{user?.nok?.nok1}</dd>
															<dt className='col-sm-6'>NOK 1 Address</dt>
															<dd className='col-sm-6'>
																{user?.nok?.nokAddress1}
															</dd>

															<dt className='col-sm-6'>NOK 1 Email</dt>
															<dd className='col-sm-6'>
																{user?.nok?.nokEmail1}
															</dd>

															<dt className='col-sm-6'>NOK 1 Phone </dt>
															<dd className='col-sm-6'>
																{user?.nok?.nokPhone1}
															</dd>
															<dt className='col-sm-6'>NOK 1 Relationship </dt>
															<dd className='col-sm-6'>
																{user?.nok?.relationship1}
															</dd>
														</dl>
														<hr />
														<dl className='row'>
															<dt className='col-sm-6'>NOK 2</dt>
															<dd className='col-sm-6'>{user?.nok?.nok2}</dd>
															<dt className='col-sm-6'>NOK 2 Address</dt>
															<dd className='col-sm-6'>
																{user?.nok?.nokAddress2}
															</dd>

															<dt className='col-sm-6'>NOK 2 Email</dt>
															<dd className='col-sm-6'>
																{user?.nok?.nokEmail2}
															</dd>

															<dt className='col-sm-6'>NOK 2 Phone </dt>
															<dd className='col-sm-6'>
																{user?.nok?.nokPhone2}
															</dd>
															<dt className='col-sm-6'>NOK 2 Relationship </dt>
															<dd className='col-sm-6'>
																{user?.nok?.relationship2}
															</dd>
														</dl>
													</div>
												</div>
											</div>
										</>
									) : (
										<EditUserProfile
											handleHideForm={handleHideForm}
											data={user}
										/>
									)}
								</div>
							</div>
						</div>
					</div>
				</Row>
			</section>

			<CustomModal
				hide={() => setShowBankAccount(false)}
				show={showBankAccount}
				size='md'
				title='Bank Detail'>
				{/* <AccountDetail data={user?.bankDetails} /> */}

				<EditBankInfo
					data={user?.bankDetails}
					userId={user._id}
					hide={() => setShowBankAccount(false)}
				/>
			</CustomModal>
		</ContentWrapper>
	);
}

export default UserProfile;
