import React, { Component } from "react";
import { Form, Button, Col, Row, Container } from "react-bootstrap";

export class EmploymentDetails extends Component {
  state = {
    active: false,
  };

  handleToggle = (e) => {
    if (e.target.value === "Others")
      this.setState({ active: !this.state.active });
  };
  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  next = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };

  render() {
    const { active } = this.state;
    const { error, disableButton, data } = this.props;
    return (
      <Container className="form-container">
        <Form>
          <Row>
            <Form.Group xs={12} as={Col} controlId="formOrganizationName">
              <Form.Label>Name of organization</Form.Label>
              <Form.Control
                as="select"
                name="organisationName"
                defaultValue={data?.organisationName}
                onClick={this.handleToggle}
                onChange={this.props.handleChange}
                isInvalid={!!error?.organisationName}
              >
                <option> select organisation name </option>
                <option value="University of Nigeria">
                  {" "}
                  University of Nigeria
                </option>
                <option value="Others">Others</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {error?.organisationName}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row>
            {active ? (
              <Form.Group as={Col} controlId="exampleForm.ControlInput1">
                <Form.Label>Work place</Form.Label>
                <Form.Control
                  type="text"
                  name="organisationName"
                  defaultValue={data?.organisationName}
                  onChange={this.props.handleChange}
                  isInvalid={!!error?.campusName}
                  placeholder="Enter name of organisation"
                />
                <Form.Control.Feedback type="invalid">
                  {error?.organisationName}
                </Form.Control.Feedback>
              </Form.Group>
            ) : null}

            <Form.Group xs={12} as={Col} controlId="formcampusName">
              <Form.Label>Name of campus</Form.Label>
              <Form.Control
                as="select"
                name="campusName"
                defaultValue={data?.campusName}
                onChange={this.props.handleChange}
              >
                <option> select campus name</option>
                <option value="NSUKKA">Nsukka Campus </option>
                <option value="ENUGU">Enugu Campus</option>
                <option value="COLLEGE OF MEDICINE">College of Medicine</option>
                <option value="N/A">Not applicable</option>
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col} controlId="exampleForm.ControlInput1">
              <Form.Label>Faculty</Form.Label>
              <Form.Control
                type="text"
                name="faculty"
                defaultValue={data?.faculty}
                onChange={this.props.handleChange}
                placeholder="Enter n/a if not applicable"
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formdepartment">
              <Form.Label>Department</Form.Label>
              <Form.Control
                type="text"
                name="department"
                defaultValue={data?.department}
                onChange={this.props.handleChange}
                placeholder="Enter n/a if not applicable"
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} controlId="formrank">
              <Form.Label>Rank/Designation</Form.Label>
              <Form.Control
                required
                type="text"
                name="rank"
                defaultValue={data?.rank}
                onChange={this.props.handleChange}
                isInvalid={!!error?.rank}
              />
              <Form.Control.Feedback type="invalid">
                {error?.rank}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} controlId="formsalaryStructure">
              <Form.Label>Staff number</Form.Label>
              <Form.Control
                required
                type="text"
                name="staffNum"
                defaultValue={data?.staffNum}
                onChange={this.props.handleChange}
                isInvalid={!!error?.staffNum}
              />
              <Form.Control.Feedback type="invalid">
                {error?.staffNum}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} controlId="exampleForm.ControlInput1">
              <Form.Label>Grade Level</Form.Label>
              <Form.Control
                type="text"
                name="gradeLevel"
                defaultValue={data?.gradeLevel}
                onChange={this.props.handleChange}
                isInvalid={!!error?.gradeLevel}
              />
              <Form.Control.Feedback type="invalid">
                {error?.gradeLevel}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} controlId="formstep">
              <Form.Label>Step</Form.Label>
              <Form.Control
                required
                type="text"
                name="step"
                defaultValue={data?.step}
                onChange={this.props.handleChange}
                isInvalid={!!error?.step}
              />
              <Form.Control.Feedback type="invalid">
                {error?.step}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} controlId="formsalaryStructure">
              <Form.Label>Salary Structure</Form.Label>
              <Form.Control
                required
                type="text"
                name="salaryStructure"
                defaultValue={data?.salaryStructure}
                onChange={this.props.handleChange}
                placeholder="CONTISS, CONUASS....."
                isInvalid={!!error?.salaryStructure}
              />
              <Form.Control.Feedback type="invalid">
                {error?.salaryStructure}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} controlId="formippisNum">
              <Form.Label>IPPIS Number</Form.Label>
              <Form.Control
                required
                type="text"
                name="ippisNum"
                defaultValue={data?.ippisNum}
                onChange={this.props.handleChange}
                isInvalid={!!error?.ippisNum}
              />
              <Form.Control.Feedback type="invalid">
                {error?.ippisNum}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group xs={12} as={Col} controlId="formcampusName">
              <Form.Label>Category</Form.Label>
              <Form.Control
                as="select"
                name="category"
                defaultValue={data?.category}
                required
                onChange={this.props.handleChange}
                isInvalid={!!error?.category}
              >
                <option>select category</option>
                <option value="Senior">Senior Category </option>
                <option value="Junior">Junior Category</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {error?.category}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group xs={12} as={Col} controlId="formsalaryStructure">
              <Form.Label>Date of assumption of duty</Form.Label>
              <Form.Control
                required
                type="date"
                name="assumptionDate"
                defaultValue={data?.assumptionDate}
                onChange={this.props.handleChange}
                isInvalid={!!error?.assumptionDate}
              />
              <Form.Control.Feedback type="invalid">
                {error?.assumptionDate}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Form.Group xs={12} as={Col} controlId="formstep">
            <Form.Label>Date of retirement</Form.Label>
            <Form.Control
              required
              type="date"
              name="retirementDate"
              defaultValue={data?.retirementDate}
              onChange={this.props.handleChange}
              isInvalid={!!error?.retirementDate}
            />
            <Form.Control.Feedback type="invalid">
              {error?.retirementDate}
            </Form.Control.Feedback>
          </Form.Group>

          <Button
            className="previous action-button-previous"
            onClick={(e) => this.back(e)}
          >
            Previous
          </Button>
          <Button
            disabled={disableButton}
            className="action-button"
            onClick={this.next}
          >
            Next
          </Button>
        </Form>
      </Container>
    );
  }
}

export default EmploymentDetails;
