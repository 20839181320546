import React from "react";
import { Form } from "react-bootstrap";

function CartSummary(props) {
  const { total, error, grandtotal, markup } = props;

  return (
    <div className="col-md-4 summary">
      <div>
        <h5>
          <b>Summary</b>
        </h5>
      </div>
      <hr />
      <form>
        <Form.Group controlId="formTitle">
          <Form.Label>REPAYMENT OPTION</Form.Label>
          <Form.Control
            as="select"
            name="paymentDuration"
            isInvalid={!!error?.paymentDuration}
            onChange={props.handleChange}
          >
            {" "}
            <option className="text-muted">SELECT PAYMENT DURATION</option>
            <option className="text-muted" value="1 Month">
              1 Month
            </option>
            <option className="text-muted" value="2 Months">
              2 Months
            </option>
            <option className="text-muted" value="3 Months">
              3 Months
            </option>
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            {error?.paymentDuration}
          </Form.Control.Feedback>
        </Form.Group>
      </form>
      <div
        className="row"
        style={{
          borderTop: "1px solid rgba(0,0,0,.1)",
          padding: "2vh 0",
        }}
      >
        <div className="col">TOTAL PRICE</div>
        <div className="col text-right"> {`\u{020A6}` + total}</div>
      </div>{" "}
      <div
        className="row"
        style={{
          borderTop: "1px solid rgba(0,0,0,.1)",
          padding: "2vh 0",
        }}
      >
        <div className="col">MARK-UP (5% of total)</div>
        <div className="col text-right"> {`\u{020A6}` + markup}</div>
      </div>{" "}
      <div
        className="row"
        style={{
          borderTop: "1px solid rgba(0,0,0,.1)",
          padding: "2vh 0",
        }}
      >
        <div className="col">GRAND TOTAL</div>
        <div className="col text-right">{`\u{020A6}` + grandtotal}</div>
      </div>{" "}
      <button className="check-out-btn" onClick={(e) => props.handleSubmit(e)}>
        CHECKOUT
      </button>
    </div>
  );
}

export default CartSummary;
