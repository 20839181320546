import React, { useState } from "react";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout"; // install this library
// Import the styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

import "./pdfviewer.css";

function PreviewPDF({ file, preview }) {
	function changeHttpToHttps(url) {
		if (url.startsWith("http://")) {
			url = url.replace("http://", "https://");
		}
		return url;
	}

	let fileUrl = React.useRef(file);

	React.useEffect(() => {
		if (file.startsWith("http://")) {
			fileUrl.current = file.replace("http://", "https://");
		} else {
			fileUrl.current = file;
		}
	});

	console.log(fileUrl.current);

	const defaultLayoutPluginInstance = defaultLayoutPlugin();
	return (
		<div
			className={`pdf-container ${
				preview ? "preview-container" : "main-container"
			}`}>
			<Worker workerUrl='https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js'>
				<Viewer
					fileUrl={fileUrl.current}
					plugins={[defaultLayoutPluginInstance]}
				/>
			</Worker>
		</div>
	);
}

export default PreviewPDF;
