import React, { useState } from "react";
import ContentWrapper from "../../utilities/ContentWrapper";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import { getProducts } from "../../../store/actions/adminActions";
import Preloader from "../../utilities/Preloader";

import "./product.css";

function CommodityItem(props) {
	const [variation, setVariation] = useState("");
	const [count, setCount] = useState(1);
	const [items, setItems] = useState([]);
	const [itemPrice, setItemPrice] = useState();
	const products = useSelector((state) => state.admin?.products);

	const dispatch = useDispatch();
	React.useEffect(() => {
		dispatch(getProducts());
	}, [dispatch]);
	const thisItem = products.filter((product) => {
		return product.productId === props.location.state.productId;
	})[0];

	React.useEffect(() => {
		let prevItem = JSON.parse(localStorage.getItem("cart")) || [];
		setItems(prevItem);
	}, []);
	React.useEffect(() => {
		setItemPrice(thisItem?.prices[0]);
	}, [thisItem?.prices]);
	const history = useHistory();

	const variationSelect = (e) => {
		setVariation(e.target.id);
		getPrice(e.target.id);
	};

	const getPrice = (variation) => {
		const { variations, prices } = thisItem;
		const index = variations.findIndex((qty) => qty === variation);

		setItemPrice(prices[index]);
	};
	const handleIncrement = () => {
		setCount(count + 1);
	};

	let handleDecrement = () => {
		setCount(count - 1);
	};
	if (count <= 1) {
		handleDecrement = () => setCount(1);
	}

	const handleAddItem = () => {
		let item = {};
		item.name = thisItem?.productName;
		item.variation = variation;
		item.quantity = count;
		item.totalPrice = itemPrice * count;

		let index = items.findIndex(
			(it) => it.variation === item.variation && it.variation === item.variation
		);
		if (index > -1) {
			items.splice(index, 1);
		}
		items.push(item);
		localStorage.setItem("cart", JSON.stringify(items));
		toast.success("item added to cart");
	};

	return (
		<ContentWrapper>
			{products && products.length > 0 ? (
				<div className='card card-solid'>
					<div className='card-body'>
						<div className='row'>
							<div className='col-12  col-md-4 col-sm-6'>
								<div className='col-12'>
									<img
										src={
											thisItem?.productImage
												? thisItem?.productImage
												: "../../img/product-image-placeholder.jpg"
										}
										className='product-image'
										alt='Product'
									/>
								</div>
							</div>
							<div className='col-12 col-md-8 col-sm-6'>
								<h3 className='my-3'>{thisItem?.productName}</h3>
								<p className='text-muted'>{thisItem.description}</p>

								<hr />
								<h4>Available Variations</h4>
								<div
									className='btn-group btn-group-toggle'
									data-toggle='buttons'>
									{thisItem?.variations.map((qty) => {
										return (
											<label
												key={qty}
												className='btn btn-outline-success  text-center '
												onChange={variationSelect}
												style={{ margin: "2px" }}>
												<input
													type='radio'
													name='variation_option'
													id={qty}
													autoComplete='off'
												/>
												{qty}
											</label>
										);
									})}
								</div>

								<div className='row'>
									<span className='input-group-btn col-lg-2 col-md-4 col-sm-4'>
										<button
											className='btn text-danger'
											onClick={handleDecrement}>
											<i className='far fa-minus-square fa-2x'></i>
										</button>
									</span>
									<input
										type='text'
										value={count}
										min='1'
										className='form-control input-number  col-sm-2'
										readOnly
									/>
									<span className='input-group-btn col-lg-2 col-md-4 col-sm-4'>
										<button
											className='btn text-success'
											onClick={handleIncrement}>
											<i className='far fa-plus-square fa-2x'></i>
										</button>
									</span>
								</div>

								<div className='bg-success py-2 px-3 mt-4'>
									<h2 className='mb-0'>{`\u{020A6}` + itemPrice}</h2>
								</div>

								<div className='mt-4'>
									<div
										className='btn btn-default btn-lg btn-flat'
										onClick={history.goBack}>
										<i className='fa fa-arrow-left fa-lg mr-2'></i>
										back to products catalog
									</div>
									<div
										className='btn btn-primary btn-lg btn-flat'
										onClick={handleAddItem}>
										<i className='fas fa-cart-plus fa-lg mr-2'></i>
										Add to Cart
									</div>
								</div>
							</div>
						</div>
					</div>
					<ToastContainer position='bottom-center' />
				</div>
			) : (
				<Preloader />
			)}
		</ContentWrapper>
	);
}

export default CommodityItem;
