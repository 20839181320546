import React from "react";
import InfoModal from "./teamInfo/InfoModal";
export const Team = (props) => {
  const [showModal, setShowModal] = React.useState(false);
  const [jobTitle, setJobTitle] = React.useState("");

  const handleShowModal = (e) => {
    setShowModal(true);
    setJobTitle(e.target.value);
  };

  const handleHideModal = () => {
    setShowModal(false);
  };
  return (
    <div id="team" className="text-center">
      <div className="container ">
        <div className="row justify-content-center">
          <div className="col-md-12 section-title">
            <h2>Meet the Team</h2>
            <p>
              Every successful organization is built on so many factors but the
              most significant factor is human resource. Our success thus far is
              anchored on enthusiastic, creative, energetic and productive team
              made up of professionals from various fields of endeavour. Amongst
              our team are professionals and Chartered Accountants with industry
              experience, a seasoned legal practitioner and experienced
              lecturers from the academia. We have over time struck a balance in
              opinions on the best options that are more suitable to our idea of
              proposing an innovative way of enhancing cooperative society
              services.
              <br />
              Therefore, we are glad to oblige you the profiles of our pioneer
              Management Committee:
            </p>
          </div>
          <div className="row">
            {props.data
              ? props.data.map((d, i) => (
                  <div
                    key={`${d.name}-${i}`}
                    className="col-md-4 col-sm-12 team d-flex justify-content-center"
                  >
                    <div className="thumbnail">
                      {" "}
                      <img src={d.img} alt="..." className="team-img" />
                      <div className="caption">
                        <h4 className="text-capitalize">{d.name}</h4>
                        <p>{d.title}</p>
                        <p>{d.job}</p>
                      </div>
                      <button
                        type="button"
                        className="btn btn-block btn-outline-info btn-xs"
                        value={d.job}
                        onClick={(e) => {
                          handleShowModal(e);
                        }}
                      >
                        Info
                      </button>
                      <InfoModal
                        handleShowModal={handleHideModal}
                        showModal={showModal}
                        jobtitle={jobTitle}
                      />
                    </div>
                  </div>
                ))
              : "loading"}
          </div>
        </div>
      </div>
    </div>
  );
};
