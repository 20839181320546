import React from "react";
import { Form, Col, Row } from "react-bootstrap";
import ContentWrapper from "../utilities/ContentWrapper";
import AddVariation from "./AddVariation";
import ImagePreview from "../utilities/ImagePreview";
import { clearNotifications } from "../../store/actions/notificationsActions";
import { addProduct } from "../../store/actions/adminActions";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

function AddProduct() {
  const [productName, setProductName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [inputValue, setInputValue] = React.useState({});
  const [variations, setVariation] = React.useState([]);
  const [productURL, setProductURL] = React.useState("");
  const [productImage, setProductImage] = React.useState("");
  const [error, setError] = React.useState({});
  const [price, setPrice] = React.useState([]);
  const [smShow, setSmShow] = React.useState(false);

  const dispatch = useDispatch();
  const notification = useSelector((state) => state.notification);
  React.useEffect(() => {
    if (notification.success?.message) {
      toast.success("submission successful");

      setProductName("");
      setDescription("");
      setVariation([]);
      setPrice([]);
      setProductURL("");
      setProductImage("");

      dispatch(clearNotifications());
    }
    if (notification.errors?.message) {
      // const { message } = notification.errors.message;
      toast.error("failed");
    }
    dispatch(clearNotifications());
  }, [dispatch, notification.errors.message, notification.success?.message]);
  const showModal = () => {
    setSmShow(true);
  };
  const handleCloseModal = () => {
    setInputValue({});
    setSmShow(false);
  };
  const findError = () => {
    const newError = {};
    if (!productName || productName === "") {
      newError.productName = "cannot be blank";
    }

    return newError;
  };
  const handleChange = (e) => {
    let value = e.target.value;
    setInputValue({ ...inputValue, [e.target.name]: value });
  };
  const handleVariation = (e) => {
    setVariation((prevArr) => [...prevArr, inputValue.variationName]);
    setPrice((prevArr) => [...prevArr, Number(inputValue.price)]);
    handleCloseModal();
  };

  const addImage = (e) => {
    const file = e?.target?.files[0];
    setProductImage(file);
    setProductURL(URL.createObjectURL(file));
  };
  const deleteVariation = (e) => {
    let index = e.target.id;
    price.splice(index, 1);
    variations.splice(index, 1);

    setVariation([...variations]);
    setPrice([...price]);
  };

  const submitProduct = (e) => {
    e.preventDefault();
    const newErrors = findError();
    if (Object.keys(newErrors).length > 0) {
      setError(newErrors);
    } else {
      const formData = new FormData();
      formData.append("productName", productName);
      formData.append("variation", JSON.stringify(variations));
      formData.append("price", JSON.stringify(price));
      formData.append("productImage", productImage);
      formData.append("description", description);
      dispatch(addProduct(formData));
    }
  };

  return (
    <ContentWrapper>
      <div className="card" id="cart">
        <div className="row">
          <div className="col-md-12 cart">
            <div className="title">
              <div className="col">
                <h4>
                  <b>ADD PRODUCT</b>
                </h4>
              </div>
              <button
                className="check-out-btn col-lg-3"
                onClick={() => setSmShow(true)}
              >
                ADD VARIATION
              </button>
            </div>
            <div className="row">
              <Form className="col-md-6">
                <div className="row">
                  <Form.Group
                    className="mb-3"
                    as={Col}
                    controlId="formdepartment"
                  >
                    <Form.Label>PRODUCT NAME</Form.Label>
                    <Form.Control
                      type="text"
                      name="productName"
                      value={productName}
                      onChange={(e) => setProductName(e.target.value)}
                      isInvalid={!!error.productName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {error.productName}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <Row>
                  <Form.Group
                    className="mb-3"
                    as={Col}
                    controlId="formdepartment"
                  >
                    <Form.Label>PRODUCT DESCRIPTION</Form.Label>
                    <Form.Control
                      as="textarea"
                      name="description"
                      placeholder="optional"
                      onChange={(e) => setDescription(e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row>
                  {" "}
                  {productURL.length > 0 ? (
                    <ImagePreview url={productURL} />
                  ) : null}
                  <Form.Group
                    xs={12}
                    md={8}
                    as={Col}
                    controlId="formFile"
                    className="mb-3"
                  >
                    <Form.Label>Product Image</Form.Label>
                    <Form.Control type="file" onChange={addImage} />
                  </Form.Group>
                </Row>
                <button className="check-out-btn " onClick={submitProduct}>
                  SAVE PRODUCT
                </button>
              </Form>
              <div className="preview col-md-6 summary">
                <div>
                  <h5>
                    <b>Product Summary</b>
                  </h5>
                </div>
                <div
                  className="row"
                  style={{
                    borderTop: "1px solid rgba(0,0,0,.1)",
                    padding: "2vh 0",
                  }}
                >
                  <div className="col">PRODUCT NAME</div>
                  <div className="col text-left"> {productName}</div>
                </div>{" "}
                <div
                  className="row"
                  style={{
                    borderTop: "1px solid rgba(0,0,0,.1)",
                    padding: "2vh 0",
                  }}
                >
                  <div className="col">PRODUCT DESCRIPTION</div>
                  <div className="col text-left"> {description}</div>
                </div>{" "}
                <div>
                  <h5>
                    <b>Variations and Prices</b>
                  </h5>
                </div>
                {variations.map((variation, i) => {
                  return (
                    <div
                      key={i + variation}
                      className="row"
                      style={{
                        borderTop: "1px solid rgba(0,0,0,.1)",
                        padding: "2vh 0",
                      }}
                    >
                      <div className="col">{variation}</div>

                      <div className="col text-left">
                        {" "}
                        {`\u{020A6}` + price[i]}
                      </div>
                      <span
                        className="close text-danger"
                        id={i}
                        onClick={deleteVariation}
                      >
                        X
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddVariation
        showModal={showModal}
        handleChange={handleChange}
        handleVariation={handleVariation}
        smShow={smShow}
        handleCloseModal={handleCloseModal}
        inputValue={inputValue}
      />
      <ToastContainer position="bottom-center" />
    </ContentWrapper>
  );
}

export default AddProduct;
