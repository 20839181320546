import React from "react";
import ContentWrapper from "../utilities/ContentWrapper";
import { useSelector, useDispatch } from "react-redux";
import { getProducts, removeProducts } from "../../store/actions/adminActions";
import { clearNotifications } from "../../store/actions/notificationsActions";
import { ToastContainer, toast } from "react-toastify";

function ViewProducts(props) {
  const dispatch = useDispatch();
  const notification = useSelector((state) => state.notification);
  React.useEffect(() => {
    if (notification.success?.message) {
      toast.success("product removed");

      dispatch(clearNotifications());
      dispatch(getProducts());
    }
    if (notification.errors?.message) {
      // const { message } = notification.errors.message;
      toast.error("failed");
    }
    dispatch(clearNotifications());
  }, [dispatch, notification.errors.message, notification.success?.message]);

  React.useEffect(() => {
    dispatch(getProducts());
  }, [dispatch]);

  const products = useSelector((state) => state.admin?.products);

  return (
    <ContentWrapper>
      <div className="card" id="cart">
        <div className="row">
          <div className="col-md-12 cart">
            <div className="title">
              <div className="col">
                <h4>
                  <b>ALL PRODUCTS</b>
                </h4>
              </div>
            </div>
          </div>
        </div>
        {products.map((product) => {
          return (
            <div
              className="row border-top border-bottom"
              key={product.productId}
            >
              <div className="row main align-items-center">
                <div className="col">
                  <div className="row text-muted">{product.productName}</div>
                </div>
                <div className="col">
                  {" "}
                  <span
                    className="close text-success"
                    id={product.productId}
                    onClick={() => {
                      props.history.push({
                        pathname: `/dashboard/products/${product.productId}`,
                        state: { product },
                      });
                    }}
                  >
                    edit product
                  </span>
                </div>
                <div className="col">
                  <span
                    className="close text-danger"
                    id={product._id}
                    onClick={(e) => {
                      dispatch(removeProducts(e.target.id));
                    }}
                  >
                    delete product
                  </span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <ToastContainer position="bottom-center" />
    </ContentWrapper>
  );
}

export default ViewProducts;
