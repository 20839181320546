import React, { useState } from "react";
import HistoryItemHeader from "./HistoryItemHeader";
import HistoryItem from "./HistoryItem";
import moment from "moment";

export default function SavingLedger(props) {
	const { userSavingData } = props;

	const [savingData, setSavingData] = useState([]);

	const [filterName, setFiltername] = useState("filter by savings type");

	React.useEffect(() => {
		setSavingData(
			userSavingData.sort((a, b) => {
				return moment(b.dt).diff(a.dt);
			})
		);
	}, [userSavingData]);

	const handleFilter = (e) => {
		setFiltername(e.target.name);
		const filteredData = userSavingData.filter((data) => {
			return data.savingCode.trim() === e.target.value.trim();
		});
		setSavingData(filteredData);

		if (e.target.value === "0") setSavingData(userSavingData);
	};

	return (
		<div className='scrolling-wrapper mt-4' style={{ overflowX: "auto" }}>
			<div className='dropdown'>
				<button
					className='btn btn-outline-success dropdown-toggle'
					type='button'
					id='btnDropdownDemo'
					data-toggle='dropdown'
					aria-haspopup='true'
					aria-expanded='false'>
					{" "}
					<i className='fas fa-piggy-bank'></i> {filterName}
				</button>

				<div className='dropdown-menu' aria-labelledby='btnDropdownDemo'>
					<button
						className='dropdown-item btn btn-secondary'
						value='0'
						name='All Savings '
						onClick={handleFilter}>
						All Savings
					</button>
					<button
						className='dropdown-item btn btn-secondary'
						value='03010101'
						name='Share Capital'
						onClick={handleFilter}>
						Share Capital
					</button>
					<button
						className='dropdown-item btn btn-secondary'
						value='02020305'
						name='Ordinary Savings'
						onClick={handleFilter}>
						Ordinary Savings
					</button>
					<button
						className='dropdown-item btn btn-secondary'
						value='02010401'
						name='Education Savings'
						onClick={handleFilter}>
						Education Savings
					</button>
					<button
						className='dropdown-item btn btn-secondary'
						value='02010402'
						name='Education Savings'
						onClick={handleFilter}>
						Christmas Savings
					</button>
					<button
						className='dropdown-item btn btn-secondary'
						value='02020301'
						name='Christmas Capital'
						onClick={handleFilter}>
						Retirement Savings
					</button>
					<button
						className='dropdown-item btn btn-secondary'
						value='02010405'
						name='Land Developement Savings'
						onClick={handleFilter}>
						Land Developement Savings
					</button>
				</div>
			</div>

			<HistoryItemHeader />
			{savingData.map((data, i) => {
				return <HistoryItem key={i + data.referenceNo} data={data} />;
			})}
		</div>
	);
}
