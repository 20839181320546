import React from "react";
import { ReactComponent as EmptyCartLogo } from "./empty_cart.svg";
import "./cart.css";

function EmptyCart() {
  return (
    <div className="card" id="cart">
      <div class="empty_message">
        <h1> Wow, So Empty...</h1>
        <p>Add some Item then come back</p>
      </div>
      <div className="svg">
        <EmptyCartLogo />
      </div>
    </div>
  );
}

export default EmptyCart;
