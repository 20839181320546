import React from "react";
import ContentWrapper from "../../utilities/ContentWrapper";
import Product from "./Product";
import { useSelector, useDispatch } from "react-redux";
import { getProducts } from "../../../store/actions/adminActions";
import Preloader from "../../utilities/Preloader";
import "./product.css";

function CommodityRequest(props) {
	const dispatch = useDispatch();
	React.useEffect(() => {
		dispatch(getProducts());
	}, [dispatch]);

	const products = useSelector((state) => state.admin?.products);

	return (
		<ContentWrapper>
			<div
				className='card-header'
				style={{
					marginBottom: "10px",
				}}>
				<h2 className='card-title'>Products</h2>
			</div>
			{products && products.length > 0 ? (
				<div className='row'>
					{products.map((product) => {
						return (
							<Product
								key={product.productId}
								productName={product.productName}
								status={product.productStatus}
								price={product.prices}
								imgUrl={product.productImage}
								productId={product.productId}
								{...props}
							/>
						);
					})}
				</div>
			) : (
				<Preloader />
			)}
		</ContentWrapper>
	);
}

export default CommodityRequest;
