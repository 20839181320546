import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import ContentWrapper from "../../utilities/ContentWrapper";
import SavingsForm from "../../forms/SavingsForm";
import useFindError from "./hooks/useFindError";

import InstructionPreview from "./PreviewInstruction";
function SavingsInstruction(props) {
	const dispatch = useDispatch();
	const { user } = useSelector((state) => state.userProfile.profile);

	const initialSavingsRequest = useMemo(
		() => user?.initialSavingsRequest || {},
		[user]
	);
	const [formData, setFormData] = useState({
		shareCapitalAmount: initialSavingsRequest.shareCapitalAmount || "",
		shareCapitalMonths: initialSavingsRequest.shareCapitalMonths || "",
		ordinarySavingsAmount: initialSavingsRequest.ordinarySavingsAmount || "",
		ordinarySavingsMonths: initialSavingsRequest.ordinarySavingsMonths || "",
		christmasSavingsAmount: initialSavingsRequest.christmasSavingsAmount || "",
		christmasSavingsMonths: initialSavingsRequest.christmasSavingsMonths || "",
		educationSavingsAmount: initialSavingsRequest.educationSavingsAmount || "",
		educationSavingsMonths: initialSavingsRequest.educationSavingsMonths || "",
		retirementSavingsAmount:
			initialSavingsRequest.retirementSavingsAmount || "",
		retirementSavingsMonths:
			initialSavingsRequest.retirementSavingsMonths || "",
		landDevSavingsAmount: initialSavingsRequest.landDevSavingsAmount || "",
		landDevSavingsMonths: initialSavingsRequest.landDevSavingsMonths || "",
		error: {},
	});

	useEffect(() => {
		setFormData({
			shareCapitalAmount: initialSavingsRequest.shareCapitalAmount || "",
			shareCapitalMonths: initialSavingsRequest.shareCapitalMonths || "",
			ordinarySavingsAmount: initialSavingsRequest.ordinarySavingsAmount || "",
			ordinarySavingsMonths: initialSavingsRequest.ordinarySavingsMonths || "",
			christmasSavingsAmount:
				initialSavingsRequest.christmasSavingsAmount || "",
			christmasSavingsMonths:
				initialSavingsRequest.christmasSavingsMonths || "",
			educationSavingsAmount:
				initialSavingsRequest.educationSavingsAmount || "",
			educationSavingsMonths:
				initialSavingsRequest.educationSavingsMonths || "",
			retirementSavingsAmount:
				initialSavingsRequest.retirementSavingsAmount || "",
			retirementSavingsMonths:
				initialSavingsRequest.retirementSavingsMonths || "",
			landDevSavingsAmount: initialSavingsRequest.landDevSavingsAmount || "",
			landDevSavingsMonths: initialSavingsRequest.landDevSavingsMonths || "",
			error: {},
		});
	}, [initialSavingsRequest]);

	const [instructionType, setInstructionType] = useState("initial");
	const [preview, setPreview] = useState(false);

	const errors = useFindError(formData, instructionType, initialSavingsRequest);

	const handleChange = (event) => {
		setFormData({
			...formData,
			[event.target.name]: event.target.value,
		});
	};

	const handleSubmit = (event) => {
		event.preventDefault();

		if (Object.keys(errors).length > 0) {
			setFormData({ ...formData, error: errors });
		} else {
			setPreview(true);
		}
	};

	return (
		<ContentWrapper>
			{!preview ? (
				<SavingsForm
					handleChange={handleChange}
					error={formData.error}
					handleSubmit={handleSubmit}
					formData={formData}
					instructionType={instructionType}
					setInstructionType={setInstructionType}
					initialSavingsRequest={initialSavingsRequest}
				/>
			) : (
				<InstructionPreview
					state={formData}
					instructionType={instructionType}
					setPreview={setPreview}
				/>
			)}
		</ContentWrapper>
	);
}

export default SavingsInstruction;
