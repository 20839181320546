import React from "react";

function SimpleHeader() {
  return (
    <nav
      id="menu"
      className="navbar navbar-expand-lg nav-light bg-light navbar-main navbar-top "
    >
      <div className="container ">
        <a className="navbar-brand page-scroll" href="#page-top">
          <span>
            <img src="img/logo/unn-logo.png" alt=".." />
            <span style={{ marginLeft: "10px" }}>LMCS</span>
          </span>
        </a>
        <button
          type="button"
          className="navbar-toggler collapsed"
          data-toggle="collapse"
          data-target="#example-navbar-collapse-1"
        >
          {" "}
          <span className="navbar-toggler-icon">
            <i className="fas fa-bars" />
          </span>{" "}
        </button>
        <div
          className="collapse navbar-collapse "
          id="example-navbar-collapse-1"
        ></div>
      </div>
    </nav>
  );
}

export default SimpleHeader;
