import React, { useState } from "react";
import { Tab, Nav, Col, Row, Container } from "react-bootstrap";
import PendingRequests from "./PendingRequest";
import ApprovedRequests from "./ApprovedRequest";
import ContentWrapper from "../../utilities/ContentWrapper";

function ManageCommodityRequests() {
    const [activeTab, setActiveTab] = useState("pending");

    return (
        <ContentWrapper>
            <Container fluid>
                <h3 className="mb-4">Manage Commodity Requests</h3>
                <Tab.Container activeKey={activeTab} onSelect={(tab) => setActiveTab(tab)}>
                    <Row>
                        {/* Aligning Nav tabs horizontally */}
                        <Nav variant="pills" className="mb-3 justify-content-center">
                            <Nav.Item>
                                <Nav.Link eventKey="pending">Pending Requests</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="approved">Approved Requests</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Row>
                    <Row>
                        <Col>
                            <Tab.Content>
                                <Tab.Pane eventKey="pending">
                                    <PendingRequests />
                                </Tab.Pane>
                                <Tab.Pane eventKey="approved">
                                    <ApprovedRequests />
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </Container>
        </ContentWrapper>
    );
}

export default ManageCommodityRequests;
