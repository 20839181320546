import React from "react";
import KanbanBoardContainer from "../utilities/KanbanBoardContainer";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { fetchMembers } from "../../store/actions/adminActions";
import { useSelector, useDispatch } from "react-redux";

import { Button } from "react-bootstrap";

function SummaryReport() {
	const { members } = useSelector((state) => state.admin);
	const dispatch = useDispatch();
	let data = [];

	let flattenObj = (obj) => {
		let result = {};
		for (const i in obj) {
			if (typeof obj[i] === "object" && !Array.isArray(obj[i])) {
				const temp = flattenObj(obj[i]);
				for (const j in temp) {
					result[j] = temp[j];
				}
			} else {
				result[i] = obj[i];
			}
		}
		return result;
	};

	members.forEach((member, index) => {
		member.serial = index + 1;
		delete member.paymentDetails;

		data.push(flattenObj(member));
	});

	React.useEffect(() => {
		dispatch(fetchMembers());
	}, [dispatch]);

	const columns = [
		{
			dataField: "serial",
			text: "#",
			headerStyle: {
				backgroundColor: "#c8e6c9",
				width: "50px",
			},
		},
		{
			dataField: "name",
			text: "Name",

			sort: true,
			headerStyle: {
				backgroundColor: "#c8e6c9",
				width: "200px",
			},
		},
		{
			dataField: "memberId",
			text: "Membership Number",
			headerStyle: {
				backgroundColor: "#c8e6c9",
				width: "200px",
			},
		},
		{
			dataField: "accountName",
			text: "Account Holder Name",
			headerStyle: {
				backgroundColor: "#c8e6c9",
				width: "200px",
			},
		},
		{
			dataField: "accountNumber",
			text: "Account Number",
			headerStyle: {
				backgroundColor: "#c8e6c9",
				width: "200px",
			},
		},
		{
			dataField: "bankName",
			text: "Bank Name",
			headerStyle: {
				backgroundColor: "#c8e6c9",
				width: "200px",
			},
		},
	];

	const MyExportCSV = (props) => {
		const handleClick = () => {
			props.onExport();
		};
		return (
			<div style={{ textAlign: "right" }}>
				<Button variant='primary' size='lg' onClick={handleClick}>
					Export To CSV
				</Button>
			</div>
		);
	};
	return (
		<KanbanBoardContainer>
			<div className='card'>
				<div className='card-header'>
					<h3 className='card-title'>Members Bank Report</h3>
				</div>
				<section className='content pb-3'>
					<div className='card-body'>
						<ToolkitProvider
							stripped
							hover
							keyField='_id'
							columns={columns}
							data={data}
							exportCSV>
							{(props) => (
								<div>
									<MyExportCSV {...props.csvProps} />
									<hr />
									<BootstrapTable {...props.baseProps} />
								</div>
							)}
						</ToolkitProvider>
					</div>
				</section>
			</div>
		</KanbanBoardContainer>
	);
}

export default SummaryReport;
