import Preloader from "../utilities/Preloader";
export const Features = (props) => {
  return (
    <div id="features" className=" text-center">
      <div className="section-title">
        <h2>Features</h2>
      </div>
      <div className="row justify-content-md-center">
        {props.data ? (
          props.data.map((d, i) => (
            <div key={`${d.title}-${i}`} className="col-sm-12 col-md-4">
              {" "}
              <i className={d.icon}></i>
              <h3>{d.title}</h3>
              <p>{d.text}</p>
            </div>
          ))
        ) : (
          <Preloader />
        )}
      </div>
    </div>
  );
};
