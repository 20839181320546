import React, { useState } from "react";
import { Table, Button, Spinner, Collapse } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { fetchCommodityReqv2, updateCommodityReqStatus } from "../../../store/actions/adminActions";
import MyPagination from "../../utilities/Pagination";

const PendingRequests = () => {
    const dispatch = useDispatch();
    const [currPage, setCurrPage] = useState(1);
    const [openItems, setOpenItems] = useState({});

    const { pendingCommodityRequests: pendingRequests, isLoading, pendingCommodityRequestsPageNum: numberOfPages } = useSelector((state) => state.admin);

    React.useEffect(() => {
        dispatch(fetchCommodityReqv2("pending", 1));
    }, [dispatch]);

    const confirmAction = (requestId, actionType) => {
        Swal.fire({
            title: `Are you sure you want to ${actionType} this request?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: `Yes, ${actionType} it!`,
        }).then((result) => {
            if (result.isConfirmed) {
                if (actionType === "approve") {
                    dispatch(updateCommodityReqStatus(requestId, "approved"));
                } else if (actionType === "reject") {
                    dispatch(updateCommodityReqStatus(requestId, "rejected"));
                }
                Swal.fire(
                    `${actionType.charAt(0).toUpperCase() + actionType.slice(1)}d!`,
                    `The request has been ${actionType}d.`,
                    'success'
                );
            }
        });
    };

    const handleApprove = (requestId) => {
        confirmAction(requestId, "approve");
    };

    const handleReject = (requestId) => {
        confirmAction(requestId, "reject");
    };

    const onPageChange = (pageNum) => {
        setCurrPage(pageNum);
        dispatch(fetchCommodityReqv2("pending", pageNum));
    };

    const formatCurrency = (amount) => {
        return new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(amount);
    };

    const toggleItems = (requestId) => {
        setOpenItems(prev => ({ ...prev, [requestId]: !prev[requestId] }));
    };

    if (isLoading) {
        return (
            <div className="text-center">
                <Spinner animation="border" role="status" />
            </div>
        );
    }

    return (
        <div className="container mt-4">
            <h2 className="mb-4">Pending Requests</h2>
            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>MemberId</th>
                        <th>Total</th>
                        <th>Repayment Plan</th>
                        <th>Markup</th>
                        <th>Items</th>
                        <th>Created At</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {pendingRequests?.map((request) => (
                        <React.Fragment key={request._id}>
                            <tr>
                                <td>{request.user.name}</td>
                                <td>{request.userId}</td>
                                <td>{formatCurrency(request.grandtotal)}</td>
                                <td>{request.repaymentPlan}</td>
                                <td>{formatCurrency(request.markup)}</td>
                                <td>
                                    <Button
                                        variant="link"
                                        onClick={() => toggleItems(request._id)}
                                        aria-controls={`item-${request._id}`}
                                        aria-expanded={openItems[request._id]}
                                    >
                                        {request.items.length} item(s) {openItems[request._id] ? '▲' : '▼'}
                                    </Button>
                                </td>
                                <td>{new Date(request.createdAt).toLocaleDateString()}</td>
                                <td>
                                    <Button variant="success" className="me-2 mb-2" onClick={() => handleApprove(request._id)}>
                                        Approve
                                    </Button>
                                    <Button variant="danger" onClick={() => handleReject(request._id)}>
                                        Reject
                                    </Button>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="6" className="p-0">
                                    <Collapse in={openItems[request._id]}>
                                        <div id={`item-${request._id}`}>
                                            <Table size="sm" className="m-0">
                                                <thead>
                                                    <tr>
                                                        <th>Item</th>
                                                        <th>Quantity</th>
                                                        <th>Price</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {request.items.map((item, index) => (
                                                        <tr key={index}>
                                                            <td>{item.name} ({item.variation})</td>
                                                            <td>{item.quantity}</td>
                                                            <td>{formatCurrency(item.totalPrice)}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Collapse>
                                </td>
                            </tr>
                        </React.Fragment>
                    ))}
                </tbody>
            </Table>
            <div className="d-flex justify-content-center mt-4 mb-4">
                <MyPagination
                    currentPage={currPage}
                    numberOfPages={numberOfPages}
                    pageClicked={onPageChange}
                />
            </div>
        </div>
    );
};

export default PendingRequests;
