import React from "react";
import { useHistory } from "react-router-dom";
import format from "format-number";

export default function LoanSummary(props) {
	const { value, interestRate, goBack } = props;

	const history = useHistory();

	const interest = (value.proRata * value.amount) / 100;
	const handlingCharge = value.amount * 0.0025;

	return (
		<div className='col-md-8 summary'>
			<div>
				<h5>
					<b>Loan Repayment Plan Schedule</b>
				</h5>
			</div>
			<hr />
			<div
				className='row'
				style={{
					borderTop: "1px solid rgba(0,0,0,.1)",
					padding: "2vh 0",
				}}>
				<div className='col'>AMOUNT REQUESTED</div>
				<div className='col text-right'>
					{" "}
					{`\u{020A6}` +
						format({ prefix: "", suffix: ".00" })(value.amount)}{" "}
				</div>
			</div>{" "}
			<div
				className='row'
				style={{
					borderTop: "1px solid rgba(0,0,0,.1)",
					padding: "2vh 0",
				}}>
				<div className='col'>LOAN TYPE</div>
				<div className='col text-right'> {value?.loanType}</div>
			</div>{" "}
			<div
				className='row'
				style={{
					borderTop: "1px solid rgba(0,0,0,.1)",
					padding: "2vh 0",
				}}>
				<div className='col'>LOAN PERIOD</div>
				<div className='col text-right'>
					{" "}
					{value?.duration}
					{value.duration > 1 ? "months" : "month"}
				</div>
			</div>{" "}
			<div
				className='row'
				style={{
					borderTop: "1px solid rgba(0,0,0,.1)",
					padding: "2vh 0",
				}}>
				<div className='col'>PERIOD INTEREST RATE</div>
				<div className='col text-right'> {interestRate + `%`}</div>
			</div>{" "}
			<div
				className='row'
				style={{
					borderTop: "1px solid rgba(0,0,0,.1)",
					padding: "2vh 0",
				}}>
				<div className='col'>PRO-RATED INTEREST</div>
				<div className='col text-right'> {value.proRata + "%"}</div>
			</div>{" "}
			<div
				className='row'
				style={{
					borderTop: "1px solid rgba(0,0,0,.1)",
					padding: "2vh 0",
				}}>
				<div className='col'>INTEREST AMOUNT DEDUCTED AT SOURCE</div>
				<div className='col text-right'>
					{" "}
					{`\u{020A6}` +
						format({ prefix: "" })((value.proRata * value.amount) / 100)}
				</div>
			</div>{" "}
			<div
				className='row'
				style={{
					borderTop: "1px solid rgba(0,0,0,.1)",
					padding: "2vh 0",
				}}>
				<div className='col'>HANDLING CHARGE DEDUCTED AT SOURCE(0.25%)</div>
				<div className='col text-right'>
					{" "}
					{`\u{020A6}` + format({ prefix: "" })(value.amount * 0.0025)}
				</div>
			</div>{" "}
			<div
				className='row'
				style={{
					borderTop: "1px solid rgba(0,0,0,.1)",
					padding: "2vh 0",
				}}>
				<div className='col'>AMOUNT DUE TO BORROWER</div>
				<div className='col text-right'>
					{" "}
					{format({ prefix: "₦" })(value.amount - (interest + handlingCharge))}
				</div>
			</div>{" "}
			<div
				className='row'
				style={{
					borderTop: "1px solid rgba(0,0,0,.1)",
					padding: "2vh 0",
				}}>
				<div className='col'>AMOUNT PAYABLE PER MONTH</div>
				<div className='col text-right'>
					{" "}
					{`\u{020A6}` + format({ prefix: "" })(value.payable)}
				</div>
			</div>{" "}
			<button className='check-out-btn bg-red' onClick={(e) => goBack()}>
				BACK TO FORM
			</button>
			<button
				className='check-out-btn'
				onClick={() =>
					history.push({
						pathname: "/dashboard/loan/preview",
						state: value,
					})
				}>
				VIEW LOAN CONTRACT
			</button>
		</div>
	);
}
