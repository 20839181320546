import React from "react";

function PresidentInfo(props) {
  return (
    <>
      <div className="card card-widget widget-user-2">
        <div className="widget-user-header bg-warning">
          <div className="widget-user-image">
            <img
              className="img-circle elevation-2"
              src="img/team/president.jpg"
              alt="User Avatar"
            />
          </div>

          <h3 className="widget-user-username">STEPHEN E. NDILI</h3>
          <h5 className="widget-user-desc">
            M.Sc, B.SC, HND, FCA, CPFA, MILRM
          </h5>
          <h5 className="widget-user-desc">President</h5>
        </div>
        <div className="card-footer p-0">
          <div className="card-header">
            <h3 className="card-title">
              <i className="fas fa-bullhorn"></i>
              Professional/Academic Qualifications:
            </h3>
          </div>
          Ndili Stephen Ebialim is a Fellow of the prestigious Institute of
          Chartered Accountants of Nigeria. He graduated from Institute of
          Management and Technology (IMT), Enugu with HND in Accountancy (Upper
          Credit). He equally possesses a B.Sc. in Accounting and Fraud
          Detection from Achievers University, Owo, Ondo State (First class). He
          acquired an M.sc in Accountancy from University of Nigeria, Enugu
          Campus with a Cumulative Grade Point Average (CGPA) of 4.52. He is a
          Chartered Public Finance Accountant (UK) and a Full Member of
          Chartered Institute of Loan and Risk Management of Nigeria (MILRM). He
          is currently a Doctorate student in the Department of Accountancy,
          University of Nigeria, Enugu campus.
          <div className="card-header">
            <h3 className="card-title">
              <i className="fas fa-bullhorn"></i>
              Work Experience:
            </h3>
          </div>
          His working career spans across virtually all sectors of the economy.
          He had a brief stint (2004 to 2005) with Survex Delivery Service (A
          courier Company). He moved to Ringo Telecoms Nigeria Limited in 2005
          as Account Officer and rose to the Position of Account Manager in
          2006. In late 2006, he took up another challenge with a manufacturing
          Company (Panar Limited) as Accounts Manager cum System Administrator;
          where he got involved with Indian Accounting software Developers
          (Tally) and trained on accounting software Design, Administration and
          Management. In March 2009, He moved to the Public Sector (Federal
          University of Agriculture, Abeokuta, Ogun State), where he had the
          opportunity of working in all Units/Directorate of the Bursary
          Department and was at a time tasked with the Management of the
          University customized accounting software. In August 2015, he moved to
          the Bursary Department of University of Nigeria, Nsukka, where he
          currently serves as Deputy Bursar/Deputy Director and Head of Division
          - Budget and Expenditure Control Management (BECM). He possess well
          over Twelve (12) years work experience in University Bursary
          administration. Mr. Stephen Ndili has worked with over eight
          off-the-shelve and customized accounting software. He has also served
          as Managing Director/Chief Executive Officer Bright Minds Associate,
          an ICAN tuition centre founded in 2011.
          <div className="card-header">
            <h3 className="card-title">
              <i className="fas fa-bullhorn"></i>
              Professional/Community Service:
            </h3>
          </div>
          Pioneer Chairman, Institute of Chartered Accountants of Nigeria,
          Nsukka and District Society (August 2017 to August 2019).
          <br />
          Assistant General Secretary, ICAN Eastern Zonal Districts (2017 –
          2019)
          <br />
          Conference Accountant, Eastern Zonal Districts (2018 – 2020) Member,
          ICAN District Societies Committee (2017 – 2019) <br />
          Member, Accounting Technicians Scheme West Africa (ATSWA) Examination
          Committee (ICAN 2017/2018 Presidential Year) <br />
          Member, ICAN Job Sourcing Committee (2018/2019 Presidential Year){" "}
          <br />
          Board Member, ICAN Information Technology & Consulting Faculty (2020 –
          Date) <br />
          Financial Secretary, ICAN Enugu and District Society Multi-purpose
          Cooperative Society Limited (2021 – Date).
          <br />
          Member and Chairman of various Committees in University of Nigeria,
          Nsukka. He has attended several local and International
          Conferences/Seminars/Trainings. He is married and blessed with
          children.
        </div>
      </div>
    </>
  );
}

export default PresidentInfo;
