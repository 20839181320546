import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
export const Navigation = (props) => {
  return (
    <nav
      id="menu"
      className="navbar navbar-expand-lg nav-light bg-light navbar-main navbar-top "
    >
      <div className="container ">
        <a className="navbar-brand page-scroll" href="#page-top">
          <span>
            <img src="img/logo/unn-logo.png" alt=".." />
            <span style={{ marginLeft: "10px" }}>LMCS</span>
          </span>
        </a>
        <button
          type="button"
          className="navbar-toggler collapsed"
          data-toggle="collapse"
          data-target="#example-navbar-collapse-1"
        >
          {" "}
          <span className="navbar-toggler-icon">
            <i className="fas fa-bars" />
          </span>{" "}
        </button>
        <div
          className="collapse navbar-collapse "
          id="example-navbar-collapse-1"
        >
          <ul className="navbar-nav  me-auto ">
            <li className="nav-item">
              <a href="#features" className=" nav-link page-scroll">
                Features
              </a>
            </li>
            <li className="nav-item">
              <a href="#about" className="nav-link page-scroll">
                About
              </a>
            </li>
            <li className="nav-item">
              <a href="#services" className=" nav-link page-scroll">
                Services
              </a>
            </li>
            <li className="nav-item">
              <a href="#portfolio" className=" nav-link page-scroll">
                Gallery
              </a>
            </li>
            <li className="nav-item">
              <a href="#mission" className=" nav-link page-scroll">
                Mission
              </a>
            </li>
            <li className="nav-item">
              <a href="#vision" className=" nav-link page-scroll">
                Vision
              </a>
            </li>
            <li className="nav-item">
              <a href="#faqs" className="nav-link page-scroll">
                FAQs
              </a>
            </li>
            <li className="nav-item">
              <a href="#team" className="nav-link page-scroll">
                Team
              </a>
            </li>
            <li className="nav-item">
              <a href="#contact" className="nav-link page-scroll">
                Contact
              </a>
            </li>
          </ul>
          <span className="nav-item">
            <a href="https://dashboard.lmcsnigltd.org.ng" className="nav-link btn btn-info rounded mr-2">
              LOGIN
            </a>
          </span>
          <span className="nav-item">
            <Link to="/signup" className="nav-link btn btn-success rounded ">
              JOIN
            </Link>
          </span>
        </div>
      </div>
    </nav>
  );
};
