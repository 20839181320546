import React from "react";
import KanbanBoardContainer from "../utilities/KanbanBoardContainer";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchCommodityReq,
  fetchMembers,
  deleteCommodityReq,
} from "../../store/actions/adminActions";
import { clearNotifications } from "../../store/actions/notificationsActions";
import "./table.css";
import { Table } from "react-bootstrap";
import { CSVLink } from "react-csv";
import { ToastContainer, toast } from "react-toastify";

function ViewCommodityRequest() {
  const dispatch = useDispatch();

  const notification = useSelector((state) => state.notification);
  React.useEffect(() => {
    if (notification.success?.message) {
      toast.success("request removed");

      dispatch(clearNotifications());
      dispatch(fetchMembers());
    }
    if (notification.errors?.message) {
      // const { message } = notification.errors.message;
      toast.error("failed");
    }
    dispatch(clearNotifications());
  }, [dispatch, notification.errors.message, notification.success?.message]);
  React.useEffect(() => {
    dispatch(fetchCommodityReq());
    dispatch(fetchMembers());
  }, [dispatch]);

  const requests = useSelector((state) => state.admin?.commodityReq);
  const { members } = useSelector((state) => state.admin);

  const alldata = [];
  let data = [];

  let flattenObj = (obj) => {
    let result = {};
    for (const i in obj) {
      if (typeof obj[i] === "object" && !Array.isArray(obj[i])) {
        const temp = flattenObj(obj[i]);
        for (const j in temp) {
          result[j] = temp[j];
        }
      } else {
        result[i] = obj[i];
      }
    }
    return result;
  };

  requests.forEach((request, index) => {
    request.serial = index + 1;
    let member = members.find((mem) => {
      return mem.memberId === request.userId;
    });
    let person = {
      requestId: request._id,
      ...request,
      ...member,
    };

    alldata.push(flattenObj(person));
  });

  alldata.forEach((req) => {
    let newObj = {};
    newObj["FULL NAME"] = req.name;
    newObj["NAME OF ORGANIZATION"] = req.organisationName;
    newObj["CAMPUS"] = req.campusName;
    newObj["GRADE LEVEL"] = req.gradeLevel;
    newObj["FACULTY"] = req.faculty;
    newObj["LMCS ID"] = req.memberId;
    newObj["STAFF NUMBER"] = req.staffNum;
    newObj["IPPIS NUMBER"] = req.ippisNum;
    newObj["SIGNATURE"] = req.signature;

    newObj["SUB TOTAL"] = req.total;
    newObj["GRAND TOTAL"] = req.grandtotal;
    newObj["REPAYMENT PLAN"] = req.repaymentPlan;

    req.items.forEach((item, i) => {
      newObj[`ITEM NAME- ${i + 1}`] = item.name;
      newObj[`VARIATION- ${i + 1}`] = item.variation;
      newObj[`NUMBER - ${i + 1}`] = item.quantity;
      newObj[`TOTAL PRICE - ${i + 1}`] = item.totalPrice;
    });
    data.push(newObj);
  });

  const handleDelete = (id) => {
    dispatch(deleteCommodityReq(id));
  };

  return (
    <KanbanBoardContainer>
      <div className="card">
        <div className="row">
          <div className="col cart">
            <div className="title">
              <div className="col">
                <h4>
                  <b>COMMODITY REQUESTS</b>
                </h4>
              </div>
            </div>
          </div>
        </div>
        <section className="content pb-3">
          <div className="card-body">
            <div style={{ margin: "5px" }}>
              <CSVLink data={data}>
                {" "}
                <button className="btn btn-primary" variant="primary" size="lg">
                  {" "}
                  Export To CSV
                </button>{" "}
              </CSVLink>
            </div>

            <Table id="commodity-table" className="strip bordered hover">
              <thead>
                <tr>
                  <th>#</th>
                  <th className="w-10">FULL NAME</th>
                  <th className="w-10">NAME OF ORGANIZATION</th>
                  <th className="w-10">CAMPUS</th>
                  <th className="w-10">GRADE LEVEL</th>
                  <th className="w-10">FACULTY</th>
                  <th className="w-10">LMCS ID</th>
                  <th className="w-10">STAFF NUMBER</th>
                  <th className="w-10">IPPIS NUMBER</th>
                  <th className="w-10">SIGNATURE </th>
                  <th className="w-10">ITEM NAME</th>
                  <th className="w-10">VARIATION</th>
                  <th className="w-10">QUANTITY</th>
                  <th className="w-10">TOTAL PRICE</th>
                  <th className="w-10">SUB TOTAL </th>
                  <th className="w-10">GRAND TOTAL</th>
                  <th className="w-10">REPAYMENT PLAN</th>
                </tr>
              </thead>

              {alldata.map((req) => {
                return (
                  <tbody key={req.requestId}>
                    <tr>
                      <td>{req.serial}</td>
                      <td>{req.name}</td>
                      <td>{req.organisationName}</td>
                      <td>{req.campusName}</td>
                      <td>{req.gradeLevel}</td>
                      <td>{req.faculty}</td>
                      <td>{req.userId}</td>
                      <td>{req.staffNum}</td>

                      <td>{req.ippisNum}</td>
                      <td>
                        <span
                          style={{
                            width: "100px",
                            height: "100px",
                            overflow: "hidden",
                          }}
                        >
                          <img
                            style={{
                              width: "100px",
                              height: "100px",
                              objectFit: "cover",
                            }}
                            src={req.signature}
                            alt="..."
                          />
                        </span>
                      </td>
                      <td>
                        {req.items.map((item) => {
                          return (
                            <tr>
                              <td id="inner">{item.name}</td>
                            </tr>
                          );
                        })}
                      </td>
                      <td>
                        {req.items.map((item) => {
                          return (
                            <tr>
                              <td id="inner">{item.variation}</td>
                            </tr>
                          );
                        })}
                      </td>
                      <td>
                        {req.items.map((item) => {
                          return (
                            <tr>
                              <td id="inner">{item.quantity}</td>
                            </tr>
                          );
                        })}
                      </td>
                      <td>
                        {req.items.map((item, i) => {
                          return (
                            <tr key={i}>
                              <td id="inner">
                                {`\u{020A6}` + item.totalPrice}
                              </td>
                            </tr>
                          );
                        })}
                      </td>
                      <td>{`\u{020A6}` + req.total} </td>
                      <td>{`\u{020A6}` + req.grandtotal}</td>
                      <td>{req.repaymentPlan}</td>
                      <td>
                        <span>
                          <button
                            className="btn btn-danger"
                            onClick={() => handleDelete(req.requestId)}
                          >
                            delete
                          </button>
                        </span>
                      </td>
                    </tr>
                  </tbody>
                );
              })}
            </Table>
          </div>
        </section>
      </div>
      <ToastContainer position="bottom-center" />
    </KanbanBoardContainer>
  );
}

export default ViewCommodityRequest;
