import React from 'react';
import { Modal, Button, } from 'react-bootstrap';
import moment from 'moment';
import format from 'format-number';

const LoanContractModal = ({ show, onHide, loanDetails: state }) => {

    const today = moment();

    return (
        <Modal show={show} onHide={onHide} size="xl">
            <Modal.Header closeButton className="bg-primary text-white">
                <Modal.Title>Loan Contract</Modal.Title>
            </Modal.Header>
            <Modal.Body className="loan-contract">
                <div className="invoice p-3 mb-3 col-12">
                    <div id="loan-contract">
                        <div style={{ textAlign: "center" }}>
                            <h4>
                                <strong>
                                    LION MULTI-PURPOSE COOPERATIVE SOCIETY NIGERIA LIMITED
                                </strong>
                            </h4>
                            <br />

                            <h6>
                                <span className="border border-success p-2 bg-info">
                                    LOAN CONTRACT FORM
                                </span>
                            </h6>

                            <br />
                        </div>

                        <span style={{ textAlign: "left" }}>
                            <p>
                                <b>
                                    THIS LOAN CONTRACT MADE THIS {today.format("Do")} DAY OF{" "}
                                    {today.format("MMMM")}
                                    {"    "}
                                    {today.format("YYYY")}
                                </b>
                            </p>
                            <p>
                                <b>BETWEEN</b>
                            </p>
                            <p>
                                {state?.title}
                                {"   "} {state?.name} of {"   "}
                                {state?.homeAddress}
                            </p>
                            <p>
                                <b>Membership ID</b>: {state?.memberId}
                            </p>
                            <p>
                                <b>Staff Number:</b> {state?.employmentDetails?.staffNum}
                            </p>
                            <p>
                                {" "}
                                <b>IPPIS Number:</b> {state?.employmentDetails?.ippisNum}
                            </p>
                            <p>
                                <b>Faculty:</b> {state?.employmentDetails?.faculty}
                            </p>
                            <p>
                                <b>Department:</b> {state?.employmentDetails?.department}{" "}
                            </p>
                            <br />
                            <p>
                                <b>AND</b>
                            </p>
                            <p>
                                <b>LION MULTI-PURPOSE COOPERATIVE SOCIETY NIGERIA LIMITED</b> Suite
                                116, Continuing Education Centre (CEC) UNGEL Guest House, University
                                of Nigeria, Nsukka, Enugu State, Nigeria
                            </p>
                            <p>
                                <b>WHEREAS</b>
                            </p>

                            <p>
                                1. {state?.title}
                                {"   "} {state?.name} of {"   "}
                                {state?.homeAddress} has approached the Co-operative for
                                credit facility of N {`\u{020A6}` + state?.amount}
                            </p>
                            <p>
                                2. The Cooperative has accepted to accommodate the Borrower to the sum
                                stated above subject to the provision of guarantors acceptable to the
                                cooperative.
                            </p>
                            <p>
                                3. The Guarantor(s) has offered to stand as surety to the Borrower for
                                the above sum in addition to the Cooperative charges, interests,
                                commissions and other miscellaneous charges.
                            </p>
                            <p>
                                4. In consideration of the cooperative giving time credit and/or
                                cooperative facilities to the Borrower,
                            </p>
                            <p>5. </p>
                        </span>

                        <table className="table table-sm">
                            <thead>
                                <tr>
                                    <th>Type of loan</th>
                                    <th>Loan Duration (Months)</th>
                                    <th>Interest rate%</th>
                                    <th>Pro-rated Interest%</th>
                                    <th>Interest Amount Deducted(₦) </th>
                                    <th>Handling Charge(₦) </th>
                                    <th>Amount payable monthly (₦)</th>
                                    <th>Amount due to borrower (₦)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <span className="badge bg-success">{state?.loanType}</span>
                                    </td>
                                    <td>
                                        <span className="badge bg-success">{state?.duration}months</span>
                                    </td>
                                    <td>
                                        <span className="badge bg-danger">
                                            {state?.interestRate + "%"}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="badge bg-danger">{state?.proRata + "%"}</span>
                                    </td>
                                    <td>
                                        <span className="badge bg-danger">
                                            {format({ prefix: "₦" })(
                                                (Number(state?.proRata) * state?.amount) / 100
                                            )}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="badge bg-danger">
                                            {format({ prefix: "₦" })(state?.amount * 0.0025)}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="badge bg-danger">
                                            {format({ prefix: "₦" })(Number(state?.payable))}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="badge bg-success">
                                            {format({ prefix: "₦" })(
                                                state?.amount -
                                                (state?.amount * 0.0025 +
                                                    (state?.proRata * state?.amount) / 100)
                                            )}
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <br />
                        <span style={{ textAlign: "left" }}>
                            <p> 6. State the current values of your:</p>
                            <p>
                                Current Monthly Gross Salary(Compulsory) :(₦){state?.gross_Salary}
                            </p>
                            <p>Current Monthly Net Salary(Compulsory) :(₦){state?.netSalary}</p>
                            <p>
                                Share Capital(Compulsory) :{" "}
                                {format({ prefix: "₦" })(Number(state?.shareCapital))}
                            </p>
                            <p>
                                Ordinary Savings(Compulsory) :
                                {format({ prefix: "₦" })(Number(state?.ordinarySavings))}
                            </p>
                            <p>Retirement Savings :(₦){state?.retirementSavings}</p>
                            <p>Christmas Savings :(₦){state?.christmasSavings}</p>
                            <p>Education Savings :(₦){state?.educationSavings}</p>
                            <p>Land Development Savings :(₦){state?.landDevelopmentSavings}</p>
                            <p>
                                7. Are you indebted to Lion Multi-Purpose Cooperative Society Limited?
                                {"     "}
                                {state?.isIndebted === "true" ? "Yes" : "No"}
                            </p>
                            <p>
                                8. If Yes, state your current loan balance (₦){state?.loanBalance}
                            </p>
                            <br />
                            <p>
                                <b>NOW THIS CONTRACT/DEED WITNESSED AS FOLLOWS:</b>
                            </p>
                            <p>
                                1. The Guarantor(s) hereby covenants and guarantees to Lion
                                Multi-Purpose Cooperative Society Ltd. the payment and undertakes on,
                                demand in writing made to the Guarantor to pay to the Cooperative any
                                sums of money which may now be or from time to time become due or
                                owing to the Cooperative.
                            </p>
                            <p>
                                2. This Deed of Guarantee shall be a continuing collateral for the
                                whole sum now due or owing to the Cooperative by the Borrower with
                                respect to the loans and Cooperative facilities collected.{" "}
                            </p>
                            <p>
                                3. The Cooperative shall be at liberty or have the legal right to set
                                off and deduct from the Guarantor’s account/salary the requisite sum
                                accruing due and owed to the Cooperative and may consolidate the
                                Guarantor’s accounts for this purpose.
                            </p>
                            <p>
                                4. In the event of the Guarantor’s demise; the sum interests charges,
                                commissions and other monies herein guaranteed shall be charged on the
                                Guarantor’s estate.
                            </p>
                            <p>
                                5. The Cooperative shall within three months on the failure of the
                                Borrower performing his contractual obligations under this loan
                                agreement, give written notices of such breaches to the Guarantor.
                            </p>
                            <p>
                                6. The Guarantor shall as if an indemnifier, be jointly and severally
                                liable to the Cooperative to all the sums herein guaranteed.
                            </p>
                            <p>
                                7. Any notice or demand herein required shall be deemed to be
                                sufficiently given if sent by electronic mail, post, courier or any
                                other reasonable means to the last known address of the Guarantor.
                            </p>
                            <p>
                                8. The Borrower irrevocably appoints the Cooperative and any person
                                nominated in writing by the President of the Cooperative including
                                every Receiver appointed on behalf of the Borrower to fulfill any
                                purpose of the security given and generally to use the name of the
                                Borrower in the exercise of all or any of the powers hereby conferred
                                on the Cooperative or any Receiver appointed by it.
                            </p>
                            <p>
                                <b>NB:</b>
                            </p>
                            <blockquote id="nb">
                                <p>
                                    i. Charges on documents which the Cooperative may incur for
                                    completing or perfecting the security provided for loans by the
                                    borrower, shall be borne by the Borrower. All other costs, charges
                                    and expenses incurred or to be incurred by the Cooperative in
                                    respect of any security under mortgage including all cost charges
                                    and expenses which may be incurred in obtaining pay mortgage
                                    charges, the Borrower agrees that all such cost, charges and
                                    expenses may be added to the moneys secured by this mortgage and
                                    bear interest accordingly.
                                </p>
                                <p>
                                    ii. Loan processing shall attract a handling charge of 0.5%
                                    deductible before disbursement of loan
                                </p>
                                <p>
                                    iii. Interest amount is also deductible from total loan before
                                    disbursement of net loan to the borrower
                                </p>
                                <p>
                                    iv. Also note that two guarantors are required to complete this loan
                                    application form, one of which must be a University of Nigeria,
                                    Nsukka Staff on grade level 12 and above The other MUST be a member
                                    of Lion Multi-Purpose Cooperative Society Limited or 2 members of
                                    Lion Multi-purpose Cooperative society Limited working with
                                    University of Nigeria Nsukka or a member of Lion Multi-purpose
                                    Cooperative society Limited working with other organisation and the
                                    other a member of Lion Multi-purpose Cooperative society Limited
                                    working with Universit of Nigeria, Nsukka.
                                </p>
                            </blockquote>
                            <p>
                                <b>IN WITNESS </b>whereof the Borrower has hereunto set his/her
                                registered signature with Lion Multi-Purpose Cooperative Society
                                Limited as a common seal the day and year first above
                                written/selected.
                            </p>
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />

                            <span style={{ width: "100px", height: "100px" }}>
                                <img
                                    src={state?.signature}
                                    alt={"..."}
                                    style={{ width: "100px", height: "60px", marginTop: "70px" }}
                                />
                            </span>
                            <p>
                                <b>SIGNED AND DELIVERED</b>{" "}
                            </p>
                            <p>By the within named BORROWER</p>
                        </span>
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>Close</Button>
                <Button variant="primary" onClick={() => window.print()}>Print Contract</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default LoanContractModal;