import React from "react";
import { Form, Col, Row } from "react-bootstrap";
import PreviewPDF from "../byLaws/PreviewPDF";
import { useDispatch } from "react-redux";
import { addFinacialReport } from "../../../store/actions/adminActions";

function AddFinancialReport({ loading, setLoading }) {
	const [inputValue, setInputValue] = React.useState({
		monthYear: "",
		description: "",
		file: null,
		finDocFile: "",
	});

	const dispatch = useDispatch();

	const handleChange = (e) => {
		if (e?.target?.files) {
			const file = e?.target?.files[0];
			let reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onloadend = (e) => {
				setInputValue({
					...inputValue,
					file: e.target.result,
					finDocFile: file,
				});
				return;
			};
		}
		setInputValue({
			...inputValue,
			[e.target.name]: e.target.value,
		});
	};

	function handleSubmit(e) {
		e.preventDefault();

		const year = inputValue.monthYear.split("-")[0];
		setLoading(true);

		const formData = new FormData();
		formData.append("year", year);
		formData.append("description", inputValue.description);
		formData.append("finDocFile", inputValue.finDocFile);

		dispatch(addFinacialReport(formData));
	}
	return (
		<div className='card' id='cart'>
			<Form className='col-md-12' onSubmit={handleSubmit}>
				<div className='row'>
					<Form.Group className='mb-3' as={Col} controlId='formdepartment'>
						<Form.Label>Select Year</Form.Label>
						<Form.Control
							type='month'
							name='monthYear'
							value={inputValue.monthYear}
							onChange={handleChange}
							required
						/>
					</Form.Group>
				</div>
				<Row>
					<Form.Group className='mb-3' as={Col} controlId='formdepartment'>
						<Form.Label>DESCRIPTION</Form.Label>
						<Form.Control
							as='textarea'
							name='description'
							placeholder='short description'
							onChange={handleChange}
						/>
					</Form.Group>
				</Row>
				<Row>
					<Form.Group
						xs={12}
						md={8}
						as={Col}
						controlId='formFile'
						className='mb-3'>
						<Form.Label>Select File *PDF only</Form.Label>
						<Form.Control
							type='file'
							accept='application/pdf'
							onChange={handleChange}
						/>
					</Form.Group>
				</Row>

				<Row>
					{inputValue.file && <PreviewPDF file={inputValue.file} preview />}
				</Row>
				<button type='submit' className='check-out-btn '>
					UPLOAD REPORT{" "}
					{loading && (
						<div className='ml-2 p-2 spinner-grow' role='status'>
							<span className='sr-only'>Loading...</span>
						</div>
					)}
				</button>
			</Form>
		</div>
	);
}

export default AddFinancialReport;
