import React, { useState, useEffect } from "react";
import { Table, Button, Spinner, Form, InputGroup, Collapse } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { fetchCommodityReqv2 } from "../../../store/actions/adminActions";
import MyPagination from "../../utilities/Pagination";

const ApprovedRequests = () => {
    const dispatch = useDispatch();
    const [currPage, setCurrPage] = useState(1);
    const [openItems, setOpenItems] = useState({});
    const [searchTerm, setSearchTerm] = useState("");

    const { approvedCommodityRequests: approvedRequests, isLoading, approvedCommodityRequestsPageNum: numberOfPages } = useSelector((state) => state.admin);


    useEffect(() => {
        dispatch(fetchCommodityReqv2("approved", 1));
    }, [dispatch]);

    const onPageChange = (pageNum) => {
        setCurrPage(pageNum);
        dispatch(fetchCommodityReqv2("approved", pageNum));
    };

    const handleSearch = (e) => {
        e.preventDefault();
        dispatch(fetchCommodityReqv2("approved", 1, searchTerm));
    };

    const formatCurrency = (amount) => {
        return new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(amount);
    };

    const toggleItems = (requestId) => {
        setOpenItems(prev => ({ ...prev, [requestId]: !prev[requestId] }));
    };

    if (isLoading) {
        return (
            <div className="text-center">
                <Spinner animation="border" role="status" />
            </div>
        );
    }

    return (
        <div className="container mt-4">
            <h2 className="mb-4">Approved Requests</h2>

            <Form onSubmit={handleSearch} className="mb-4">
                <InputGroup>
                    <Form.Control
                        type="text"
                        placeholder="Search by member ID"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <Button variant="outline-secondary" type="submit">Search</Button>
                </InputGroup>
            </Form>

            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>MemberId</th>
                        <th>Total</th>
                        <th>Repayment Plan</th>
                        <th>Markup</th>
                        <th>Items</th>
                        <th>Created At</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {approvedRequests?.map((request) => (
                        <React.Fragment key={request._id}>
                            <tr>
                                <td>{request.user.name}</td>
                                <td>{request.userId}</td>
                                <td>{formatCurrency(request.grandtotal)}</td>
                                <td>{request.repaymentPlan}</td>
                                <td>{formatCurrency(request.markup)}</td>
                                <td>
                                    <Button
                                        variant="link"
                                        onClick={() => toggleItems(request._id)}
                                        aria-controls={`item-${request._id}`}
                                        aria-expanded={openItems[request._id]}
                                    >
                                        {request.items.length} item(s) {openItems[request._id] ? '▲' : '▼'}
                                    </Button>
                                </td>
                                <td>{new Date(request.createdAt).toLocaleDateString()}</td>
                                <td>
                                    <Link to={{
                                        pathname: `/dashboard/commodity-invoice/${request._id}`,
                                        state: { invoiceDetails: request }
                                    }}>
                                        <Button variant="primary">View Invoice</Button>
                                    </Link>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="8" className="p-0">
                                    <Collapse in={openItems[request._id]}>
                                        <div id={`item-${request._id}`}>
                                            <Table size="sm" className="m-0">
                                                <thead>
                                                    <tr>
                                                        <th>Item</th>
                                                        <th>Quantity</th>
                                                        <th>Price</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {request.items.map((item, index) => (
                                                        <tr key={index}>
                                                            <td>{item.name} ({item.variation})</td>
                                                            <td>{item.quantity}</td>
                                                            <td>{formatCurrency(item.price)}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Collapse>
                                </td>
                            </tr>
                        </React.Fragment>
                    ))}
                </tbody>
            </Table>
            <div className="d-flex justify-content-center mt-4 mb-4">
                <MyPagination
                    currentPage={currPage}
                    numberOfPages={numberOfPages}
                    pageClicked={onPageChange}
                />
            </div>
        </div>
    );
};

export default ApprovedRequests;