import React from "react";
import ContentWrapper from "../../utilities/ContentWrapper";
import CartItem from "./CartItem";
import "./cart.css";
import CartSummary from "./CartSummary";
import { ToastContainer, toast } from "react-toastify";
import { submitCommodity } from "../../../store/actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { clearNotifications } from "../../../store/actions/notificationsActions";
import EmptyCart from "./EmptyCart";

function CartPage() {
	const [, updateState] = React.useState();
	const [items, setitems] = React.useState([]);
	const [total, setTotal] = React.useState(0);
	const [paymentDuration, setPaymentDuration] = React.useState("");
	const [error, setError] = React.useState({});

	const forceUpdate = React.useCallback(() => updateState({}), []);

	const dispatch = useDispatch();
	const notification = useSelector((state) => state.notification);

	React.useEffect(() => {
		let selectedItems = JSON.parse(localStorage.getItem("cart")) || [];
		setitems(selectedItems);
	}, []);

	React.useEffect(() => {
		let value = 0;
		items.forEach((item) => {
			return (value = item.totalPrice + value);
		});

		setTotal(value);
	}, [items]);

	React.useEffect(() => {
		if (notification.success?.message) {
			toast.success("submission successful");
			localStorage.removeItem("cart");
			dispatch(clearNotifications());
		}
		if (notification.errors?.message) {
			// const { message } = notification.errors.message;
			toast.error("failed");
		}
		dispatch(clearNotifications());
	}, [dispatch, notification.errors.message, notification.success?.message]);

	const handleRemove = (e) => {
		let index = items.findIndex((item) => item.name === e.target.id);
		if (index > -1) {
			items.splice(index, 1);
		}

		localStorage.setItem("cart", JSON.stringify(items));
		toast.info("item removed successfully", { theme: "dark" });
		forceUpdate();
	};
	const handleChange = (e) => {
		setPaymentDuration(e.target.value);
	};
	const findError = () => {
		const newErrors = {};
		if (!paymentDuration || paymentDuration === "")
			newErrors.paymentDuration = "please select repayment option";

		return newErrors;
	};
	const markup = (5 / 100) * total;
	const grandtotal = markup + total;
	const handleSubmit = (e) => {
		e.preventDefault();
		const newError = findError();
		if (Object.keys(newError).length > 0) {
			setError(newError);
		} else {
			const cart = {};
			cart.total = total;
			cart.markup = markup;
			cart.grandtotal = grandtotal;
			cart.repaymentPlan = paymentDuration;
			cart.items = items;
			dispatch(submitCommodity(cart));
		}
	};

	return (
		<ContentWrapper>
			{items.length > 0 ? (
				<div className='card' id='cart'>
					<div className='row'>
						<div className='col-md-8 cart'>
							<div className='title'>
								<div className='row'>
									<div className='col'>
										<h4>
											<b>Shopping Cart</b>
										</h4>
									</div>
									<div className='col align-self-center text-right text-muted'>
										{items.length < 2
											? `${items?.length}  item`
											: `${items?.length}  items`}
									</div>
								</div>
							</div>
							{items?.map((item) => {
								return (
									<CartItem
										key={item.name}
										productName={item.name}
										variation={item.variation}
										quantity={item.quantity}
										totalPrice={item.totalPrice}
										handleRemove={handleRemove}
										error={error}
									/>
								);
							})}
						</div>
						<CartSummary
							items={items}
							total={total}
							handleChange={handleChange}
							handleSubmit={handleSubmit}
							error={error}
							markup={markup}
							grandtotal={grandtotal}
						/>
					</div>
				</div>
			) : (
				<EmptyCart />
			)}
			<ToastContainer position='bottom-center' />
		</ContentWrapper>
	);
}

export default CartPage;
