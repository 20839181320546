import React from "react";
import { Link } from "react-router-dom";

function ExpiredLinkPage() {
  return (
    <div class="">
      <section class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
              <img src="img/logo/unn-logo.png" alt="" />
            </div>
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li class="breadcrumb-item active"></li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section class="content">
        <div class="error-page">
          <h2 class="headline text-warning"> LMCS</h2>

          <div class="error-content">
            <p>
              the link is invalid or expired
              <Link to="/forgotpassword"> get another link</Link>
            </p>

            <form class="search-form">
              <div class="input-group">
                <input
                  type="text"
                  name="search"
                  class="form-control"
                  placeholder="Search"
                />

                <div class="input-group-append">
                  <button type="submit" name="submit" class="btn btn-warning">
                    <i class="fas fa-search"></i>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ExpiredLinkPage;
