import React from "react";

function FinancialSecretaryInfo() {
  return (
    <>
      <div className="card card-widget widget-user-2">
        <div className="widget-user-header bg-warning">
          <div className="widget-user-image">
            <img
              className="img-circle elevation-2"
              src="img/team/financial_secretary.jpg"
              alt="User Avatar"
            />
          </div>

          <h3 className="widget-user-username">CHARLES O. ONUGHA</h3>
          <h5 className="widget-user-desc">BSc, MSc, CNA</h5>
          <h5 className="widget-user-desc">Financial Secretary</h5>
        </div>
        <div className="card-footer p-0">
          Charles Otuosorochi Onugha is a hybrid financial industry professional
          with career paths cutting across the private and public sectors.
          <br />
          He is a thoroughbred banker and consummate accountant. His seven years
          banking experiences in four banks, covered areas in sales/marketing,
          foreign operations, foreign funds transfer and bureau de change
          operations. <br />
          In 2013, he joined the services of the University of Nigeria, Nsukka
          as an Accountant in Special Programmes Accounts and Supplier Services
          Unit. He was later moved to the Payroll-Management Information Systems
          unit where he assisted in managing the University's payroll and other
          employee-related payments databases for over six years. He currently
          heads the electronic payments unit of Bursary department and oversees
          non-salary related employee payments as well as other non-employee
          payments.
          <br />
          His working experiences affords him with hands-on on an array of
          software applications. Trade Innovation plus (TI+), FLEXCUBE, BANKS
          (Basis), Admon Financials, SAGE, Structured Pay Manager (SPM) and many
          others. He holds a Certificate of Proficiency with SAP University
          Alliances. Charles Otuosorochi Onugha holds a National Diploma in
          Accountancy from the Federal Polytechnic, Nekede, Owerri at
          Distinction level.
          <br /> He graduated with a B.Sc in Accounting from the great
          University of Benin with Second Class Upper in 2005. He was admitted
          to study MSc (Finance) at the University of Lagos. He holds an M.Sc in
          Maritime Business and International Logistics of University of
          Nigeria, Nsukka, graduating with a 4.52 CGPA on a 5.0 scale.
          <br />
          He is currently an M.sc student in the Department of Accounting at the
          University of Nigeria, Enugu Campus. Charles Onugha is a Certified
          National Accountant with broad interests in Taxation and Logistics
        </div>
      </div>
    </>
  );
}

export default FinancialSecretaryInfo;
