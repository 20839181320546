import { useEffect, useState } from "react";

function useFindError(formData, instructionType, initialSavingsRequest) {
	const [errors, setErrors] = useState({});

	useEffect(() => {
		const newErrors = {};

		const {
			shareCapitalAmount,
			shareCapitalMonths,
			ordinarySavingsAmount,
			christmasSavingsAmount,
			educationSavingsAmount,
			retirementSavingsAmount,
		} = formData;

		// Validation specific to the instruction type
		if (instructionType === "initial") {
			if (!shareCapitalAmount || shareCapitalAmount === "") {
				newErrors.shareCapitalAmount = "cannot be blank!";
			} else if (shareCapitalAmount < 5000) {
				newErrors.shareCapitalAmount =
					"share capital cannot be less than 5000 per month";
			}

			if (!shareCapitalMonths || shareCapitalMonths === "") {
				newErrors.shareCapitalMonths = "cannot be blank!";
			} else if (shareCapitalAmount * shareCapitalMonths < 100000) {
				newErrors.shareCapitalMonths = `please input a valid number of months, the acceptable number of months for ${shareCapitalAmount} is at least ${
					100000 / shareCapitalAmount
				}`;
			}
			if (!ordinarySavingsAmount || ordinarySavingsAmount === "") {
				newErrors.ordinarySavingsAmount = "cannot be blank!";
			} else if (ordinarySavingsAmount < 5000) {
				newErrors.ordinarySavingsAmount =
					"ordinary savings cannot be less than 5000 per month";
			}
		} else if (instructionType === "increase") {
			if (
				shareCapitalAmount !== "" &&
				shareCapitalAmount <= initialSavingsRequest.shareCapitalAmount
			) {
				newErrors.shareCapitalAmount = `increase amount cannot be less than the amount in initial instruction (N ${initialSavingsRequest.shareCapitalAmount}  ) `;
			}

			if (
				ordinarySavingsAmount !== "" &&
				ordinarySavingsAmount <= initialSavingsRequest.ordinarySavingsAmount
			) {
				newErrors.ordinarySavingsAmount = `increase amount cannot be less than the amount in initial instruction (N ${initialSavingsRequest.ordinarySavingsAmount}  ) `;
			}

			if (
				christmasSavingsAmount !== "" &&
				christmasSavingsAmount <= initialSavingsRequest.christmasSavingsAmount
			) {
				newErrors.christmasSavingsAmount = `increase amount cannot be less than the amount in initial instruction (N ${initialSavingsRequest.christmasSavingsAmount}  ) `;
			}

			if (
				educationSavingsAmount !== "" &&
				educationSavingsAmount <= initialSavingsRequest.educationSavingsAmount
			) {
				newErrors.educationSavingsAmount = `increase amount cannot be less than the amount in initial instruction (N ${initialSavingsRequest.educationSavingsAmount}  ) `;
			}
			if (
				retirementSavingsAmount !== "" &&
				retirementSavingsAmount <= initialSavingsRequest.retirementSavingsAmount
			) {
				newErrors.retirementSavingsAmount = `increase amount cannot be less than the amount in initial instruction (N ${initialSavingsRequest.retirementSavingsAmount}  ) `;
			}
		} else if (instructionType === "decrease") {
			if (
				shareCapitalAmount !== "" &&
				shareCapitalAmount >= initialSavingsRequest.shareCapitalAmount
			) {
				newErrors.shareCapitalAmount = `decrease amount cannot be greater than the amount in initial instruction (N ${initialSavingsRequest.shareCapitalAmount}  ) `;
			} else if (shareCapitalAmount < 5000) {
				newErrors.shareCapitalAmount =
					"share capital cannot be less than 5000 per month";
			}

			if (
				ordinarySavingsAmount !== "" &&
				ordinarySavingsAmount >= initialSavingsRequest.ordinarySavingsAmount
			) {
				newErrors.ordinarySavingsAmount = `decrease amount cannot be greater than the amount in initial instruction (N ${initialSavingsRequest.ordinarySavingsAmount}  ) `;
			} else if (ordinarySavingsAmount < 5000) {
				newErrors.ordinarySavingsAmount =
					"ordinary savings cannot be less than 5000 per month";
			}

			if (
				christmasSavingsAmount !== "" &&
				christmasSavingsAmount >= initialSavingsRequest.christmasSavingsAmount
			) {
				newErrors.christmasSavingsAmount = `decrease amount cannot be greater than the amount in initial instruction (N ${initialSavingsRequest.christmasSavingsAmount}  ) `;
			}

			if (
				educationSavingsAmount !== "" &&
				educationSavingsAmount >= initialSavingsRequest.educationSavingsAmount
			) {
				newErrors.educationSavingsAmount = `decrease amount cannot be greater than the amount in initial instruction (N ${initialSavingsRequest.educationSavingsAmount}  ) `;
			}
			if (
				retirementSavingsAmount !== "" &&
				retirementSavingsAmount >= initialSavingsRequest.retirementSavingsAmount
			) {
				newErrors.retirementSavingsAmount = `decrease amount cannot be greater than the amount in initial instruction (N ${initialSavingsRequest.retirementSavingsAmount}  ) `;
			}
		}

		setErrors(newErrors);
	}, [formData, instructionType, initialSavingsRequest]);

	return errors;
}

export default useFindError;
