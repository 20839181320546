import React from "react";
import ContentHeader from "../views/dashboard/contents/ContentHeader";

import { fetchMembers } from "../../store/actions/adminActions";
import { Row } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import SmallBox2 from "../utilities/SmallBox2";

function AdminDashboard(props) {
  const dispatch = useDispatch();
  const { members } = useSelector((state) => state.admin);

  const confirmed = members?.filter((member) => {
    return member?.confirmed === true;
  });
  const initial = members?.filter((member) => {
    const { initialSavingsRequest } = member;
    return initialSavingsRequest;
  });
  const increase = members?.filter((member) => {
    const { increaseSavingsRequest } = member;
    return increaseSavingsRequest;
  });
  const decrease = members?.filter((member) => {
    const { decreaseSavingsRequest } = member;
    return decreaseSavingsRequest;
  });

  const { user } = props;
  React.useEffect(() => {
    dispatch(fetchMembers());
  }, [dispatch]);
  return (
    <div className=" content-wrapper ">
      <ContentHeader title={user?.name} />
      <div className="content">
        <div className="container-fluid">
          <Row>
            <SmallBox2
              icon={"fas fa-users"}
              title={"Registered"}
              number={members.length}
              color={"bg-success"}
              path={"dashboard/viewmembers"}
            />
            <SmallBox2
              icon={"fas fa-user-check"}
              title={"Confirmed"}
              number={confirmed.length}
              color={"bg-warning"}
              path={"dashboard/viewmembers"}
            />
            <SmallBox2
              icon={"fab fa-wpforms"}
              title={"Initial Instructions"}
              number={initial.length}
              color={"bg-info"}
              path={"dashboard/instructions/initial"}
            />
            <SmallBox2
              icon={"fab fa-wpforms"}
              title={"Increase Instructions"}
              number={increase.length}
              color={"bg-info"}
              path={"dashboard/instructions/increment"}
            />
            <SmallBox2
              icon={"fab fa-wpforms"}
              title={"Decrease Instructions"}
              number={decrease.length}
              color={"bg-info"}
              path={"dashboard/instructions/decrement"}
            />
          </Row>
        </div>
      </div>
    </div>
  );
}

export default AdminDashboard;
