import axios from "axios";
import { baseUrl, getConfig } from "../config";

export const generateReferralLink = () => async (dispatch, getState) => {
	try {
		const res = await axios.get(
			`${baseUrl}/user/API/referal-link`,
			getConfig(getState)
		);

		dispatch({ type: "GET_REF_LINK", payload: res.data });
	} catch (err) {
		dispatch({
			type: "GET_ERROR_MSG",
			payload: err?.response?.data,
		});
	}
};

export const editprofile = (formdata) => async (dispatch, getState) => {
	try {
		const res = await axios.post(
			`${baseUrl}/user/API/editprofile`,
			formdata,
			getConfig(getState)
		);

		dispatch({ type: "GET_SUCCESS_MSG", payload: res.data });
	} catch (err) {
		dispatch({
			type: "GET_ERROR_MSG",
			payload: err?.response?.data,
		});
	}
};
export const initialSavingsRequest =
	(formdata) => async (dispatch, getState) => {
		try {
			const res = await axios.post(
				`${baseUrl}/user/API/initialsaving`,
				{ formdata },
				getConfig(getState)
			);

			dispatch({ type: "GET_SUCCESS_MSG", payload: res.data });
		} catch (err) {
			dispatch({
				type: "GET_ERROR_MSG",
				payload: err?.response?.data,
			});
		}
	};

export const increaseSavingsRequest =
	(formdata) => async (dispatch, getState) => {
		try {
			const res = await axios.post(
				`${baseUrl}/user/API/increasesaving`,
				{ formdata },
				getConfig(getState)
			);

			dispatch({ type: "GET_SUCCESS_MSG", payload: res.data });
		} catch (err) {
			dispatch({
				type: "GET_ERROR_MSG",
				payload: err?.response?.data,
			});
		}
	};

export const decreaseSavingsRequest =
	(formdata) => async (dispatch, getState) => {
		try {
			const res = await axios.post(
				`${baseUrl}/user/API/decreasesaving`,
				{ formdata },
				getConfig(getState)
			);

			dispatch({ type: "GET_SUCCESS_MSG", payload: res.data });
		} catch (err) {
			dispatch({
				type: "GET_ERROR_MSG",
				payload: err?.response?.data,
			});
		}
	};

export const getBalances = (id) => async (dispatch, getState) => {
	try {
		const res = await axios.get(
			`${baseUrl}/user/get-balances?id=${id}`,
			getConfig(getState)
		);

		dispatch({ type: "GET_BALANCES", payload: res.data });
	} catch (err) {
		dispatch({
			type: "GET_SAVINGS_FAIL",
			payload: err?.response?.data,
		});
	}
};

export const savingsTransactionHistory = () => async (dispatch, getState) => {
	try {
		const res = await axios.get(
			"https://www.finappsoftwarewebapi.com/api/savingTransactions",
			getConfig(getState)
		);

		dispatch({ type: "GET_SAVINGS", payload: res.data });
	} catch (err) {
		dispatch({
			type: "GET_SAVINGS_FAIL",
			payload: err?.response?.data,
		});
	}
};

export const fetchBalanceSummary = () => async (dispatch) => {
	try {
		const res = await axios.get(
			"https://www.finappsoftwarewebapi.com/API/coopmembersummarybalance"
		);

		dispatch({ type: "SUMMARY_BALANCE", payload: res.data });
	} catch (err) {
		dispatch({
			type: "GET_SAVINGS_FAIL",
			payload: err?.response?.data,
		});
	}
};

export const loansTransactionHistory = () => async (dispatch, getState) => {
	try {
		const res = await axios.get(
			"https://www.finappsoftwarewebapi.com/api/loanTransactions",

			getConfig(getState)
		);

		dispatch({ type: "GET_LOANS", payload: res.data });
	} catch (err) {
		dispatch({
			type: "GET_LOANS_FAIL",
			payload: err?.response?.data,
		});
	}
};
export const loanRequest = (data) => async (dispatch, getState) => {
	try {
		const res = await axios.post(
			`${baseUrl}/user/API/loanrequest`,
			data,
			getConfig(getState)
		);
		dispatch({ type: "GET_SUCCESS_MSG", payload: res.data });
	} catch (err) {
		dispatch({
			type: "GET_ERROR_MSG",
			payload: err?.response?.data,
		});
	}
};

export const fetchMyLoans = () => async (dispatch, getState) => {
	try {
		const res = await axios.get(
			`${baseUrl}/user/API/myloans`,
			getConfig(getState)
		);

		dispatch({ type: "FETCH_MY_LOAN", payload: res.data });
	} catch (err) {
		dispatch({
			type: "GET_ERROR_MSG",
			payload: err?.response?.data,
		});
	}
};

export const submitGuarantor = (data) => async (dispatch, getState) => {
	try {
		const res = await axios.post(`${baseUrl}/user/API/submitguarantor`, data);
		dispatch({ type: "GET_SUCCESS_MSG", payload: res.data });
	} catch (err) {
		dispatch({
			type: "GET_ERROR_MSG",
			payload: err?.response?.data,
		});
	}
};
export const submitCommodity = (cart) => async (dispatch, getState) => {
	try {
		const res = await axios.post(
			`${baseUrl}/user/API/submitcommodity`,
			cart,
			getConfig(getState)
		);

		dispatch({ type: "GET_SUCCESS_MSG", payload: res.data });
	} catch (err) {
		dispatch({
			type: "GET_ERROR_MSG",
			payload: err?.response?.data,
		});
	}
};

export const fetchLoanee = (id) => async (dispatch, getState) => {
	try {
		dispatch({ type: "START_LOADING" });
		const res = await axios.get(`${baseUrl}/user/API/fetchloanee/${id}`);

		dispatch({ type: "FETCH_LOANEE", payload: res.data });
		dispatch({ type: "STOP_LOADING" });
	} catch (err) {
		dispatch({ type: "GET_ERROR_MSG", payload: err.response?.data });
	}
};
export const storeformData = (formdata) => {
	return (dispatch) => {
		dispatch({ type: "FETCH_FORM_DATA", payload: formdata });
	};
};
