import React from "react";
import { useState, useEffect } from "react";
import { Navigation } from "./navigation";
import { Header } from "./header";
import { Features } from "./features";
import { About } from "./about";
import { Services } from "./services";
import { Gallery } from "./gallery";
import { Testimonials } from "./testimonials";
import { Team } from "./Team";
import { Contact } from "./contact";
import JsonData from "../../data/data.json";
import SmoothScroll from "smooth-scroll";
import WelcomeNote from "../utilities/WelcomeNote";
import MissionStatement from "./MissionStatement";
import VisionStatement from "./VisionStatement";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

function Landing() {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  const [showModal, setShowModal] = React.useState(false);

  const handleShowModal = () => {
    setShowModal(!showModal);
  };

  const handleSetCookies = (e) => {
    if (e.target.checked) {
      localStorage.setItem("note", true);
    }
  };
  React.useEffect(() => {
    const pref = localStorage.getItem("note");
    if (!pref) {
      const timer = setTimeout(() => {
        setShowModal(true);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, []);

  return (
    <>
      <Navigation />
      <Header data={landingPageData.Header} />
      <Features data={landingPageData.Features} />
      <About data={landingPageData.About} />
      <VisionStatement />
      <MissionStatement />
      <Services data={landingPageData.Services} />
      <Gallery />
      <Testimonials data={landingPageData.Testimonials} />
      <Team data={landingPageData.Team} />
      <Contact data={landingPageData.Contact} />
      <WelcomeNote
        handleSetCookies={handleSetCookies}
        handleShowModal={handleShowModal}
        showModal={showModal}
      />
    </>
  );
}

export default Landing;
