export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-6">
            {" "}
            <img src="img/intro-pic.jpg" className="img-fluid" alt="" />{" "}
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="about-text">
              <h2>About Us</h2>
              <div className="about-footer">
                <p>
                  Lion Multi-Purpose Co-operative Society Limited was
                  incorporated on the 14th day of July, 2021 in Enugu State
                  under the Nigeria Co-operative Societies Act No 98 of 2004. It
                  is a voluntary association of individuals/legal persons united
                  by common bond, who have come together to pursue their
                  socio-economic goals for their own benefits with high regards
                  to non-members.{" "}
                </p>
                <div className="list-style">
                  <div className=" col-sm-12 col-xs-12">
                    <ul>
                      <li>
                        To encourage thrift among members so that each member
                        may cultivate the habit of making savings at least once
                        monthly, with a view to building up funds for their
                        individual future use;{" "}
                      </li>
                    </ul>
                  </div>
                  <div className=" col-sm-12">
                    <ul>
                      <li>
                        To enable members to obtain loans from the society at a
                        minimal interest rate with convenient terms of
                        repayment.{" "}
                      </li>
                    </ul>
                  </div>
                  <div className=" col-sm-12">
                    <ul>
                      <li> To promote mutual co-operation among members. </li>
                    </ul>
                  </div>
                  <div className=" col-sm-12">
                    <ul>
                      <li>
                        To stock consumer and producer goods for distribution to
                        members and non-members; (with priority to members) at
                        fair and reasonable prices.{" "}
                      </li>
                    </ul>
                  </div>
                  <div className=" col-sm-12 ">
                    <ul>
                      <li>
                        To operate warehouses and cold room facilities for the
                        storage of these consumer and producer goods and let out
                        these facilities to interested parties;{" "}
                      </li>
                    </ul>
                  </div>
                  <div className=" col-sm-12 ">
                    <ul>
                      <li>
                        To acquire acres of land for sale, lease or Estate
                        development to meet the housing needs of members or for
                        residential, commercial or industrial purposes as a form
                        of investment for the economic benefit of members and
                        other interested parties;{" "}
                      </li>
                    </ul>
                  </div>
                  <div className=" col-sm-12 ">
                    <ul>
                      <li>
                        {" "}
                        To undertake and invest in new ventures beneficial to
                        its members and the Society as approved by the
                        Management Committee. These include Projects Financing;
                        Feasibility Studies, business plan etc.{" "}
                      </li>
                    </ul>
                  </div>
                  <div className=" col-sm-12 ">
                    <ul>
                      <li>
                         To undertake service businesses that will cater for
                        the primary needs of members and other patrons managed
                        in the overall interest of members and the Society.{" "}
                      </li>
                    </ul>
                  </div>
                  <div className=" col-sm-12 ">
                    <ul>
                      <li>
                        To undertake such financial management schemes like
                        Special Withdraw-able Deposit and Community Banking
                        Schemes at co-operative and competitive rates for
                        members and other interested parties{" "}
                      </li>
                    </ul>
                  </div>
                  <div className=" col-sm-12 ">
                    <ul>
                      <li>
                        To encourage fixed deposits from members out of which a
                        fund may be established for giving short-term loans to
                        members, to acquire stock of shares and other rewarding
                        ventures{" "}
                      </li>
                    </ul>
                  </div>
                  <div className=" col-sm-12 ">
                    <ul>
                      <li>
                        To raise funds through share holdings by members in the
                        Society to undertake such other activities as are
                        necessary for the attainment of these objects{" "}
                      </li>
                    </ul>
                  </div>
                  <div className=" col-sm-12 ">
                    <ul>
                      <li>
                         To invest in Agricultural products. This will include
                        crop and animal farming, buying and selling of
                        Agricultural products and any other agricultural
                        activity.{" "}
                      </li>
                    </ul>
                  </div>
                  <div className=" col-sm-12 ">
                    <ul>
                      <li>
                         To engage in other economic or social activity as may
                        be approved by the Management Committee or the general
                        meeting of members.{" "}
                      </li>
                    </ul>
                  </div>
                  <div className=" col-sm-12 ">
                    <ul>
                      <li>
                         To seek loans for residential, commercial or
                        industrial housing development from banks, the
                        co-operative circle and other financial institutions;{" "}
                      </li>
                    </ul>
                  </div>
                  <div className=" col-sm-12 ">
                    <ul>
                      <li>
                         To secure wholesale mortgage, either for on-lending to
                        members or for housing development and let or sell such
                        dwellings on mortgage basis;{" "}
                      </li>
                    </ul>
                  </div>
                  <div className=" col-sm-12">
                    <ul>
                      <li>
                         To do anything within the law and enter into any
                        transaction which in the Society’s opinion is necessary
                        to ensure the proper performance of its functions or the
                        attainment of the above listed objects.{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
