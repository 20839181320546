import React from "react";
import "./Loader.css";

const Loader = ({ isLoading }) => {
	return (
		isLoading && (
			<div className='loader-container'>
				<div className='backdrop'></div>
				<div className='loader'>
					<img
						className='animation__shake'
						src='img/loader.gif'
						alt='unn logo'
						height='60'
						width='60'
					/>
				</div>
			</div>
		)
	);
};

export default Loader;
