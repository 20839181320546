export const pioneers = [
	"LMCS/9/2021/000001",
	"LMCS/9/2021/000002",
	"LMCS/9/2021/000003",
	"LMCS/9/2021/000003",
	"LMCS/9/2021/000004",
	"LMCS/9/2021/000005",
	"LMCS/9/2021/000006",
	"LMCS/9/2021/000007",
	"LMCS/9/2021/000008",
	"LMCS/9/2021/000009",
	"LMCS/9/2021/000010",
	"LMCS/9/2021/000011",
	"LMCS/9/2021/000012",
	"LMCS/9/2021/000013",
	"LMCS/9/2021/000014",
	"LMCS/9/2021/000015",
	"LMCS/9/2021/000016",
	"LMCS/9/2021/000017",
	"LMCS/9/2021/000018",
	"LMCS/9/2021/000019",
	"LMCS/9/2021/000020",
	"LMCS/9/2021/000021",
	"LMCS/9/2021/000022",
	"LMCS/9/2021/000023",
	"LMCS/9/2021/000024",
	"LMCS/9/2021/000025",
	"LMCS/9/2021/000026",
	"LMCS/9/2021/000027",
	"LMCS/9/2021/000028",
	"LMCS/9/2021/000029",
	"LMCS/9/2021/000030",
	"LMCS/9/2021/000030",
	"LMCS/9/2021/000031",
	"LMCS/9/2021/000032",
	"LMCS/9/2021/000033",
	"LMCS/9/2021/000034",
	"LMCS/9/2021/000035",
	"LMCS/9/2021/000036",
	"LMCS/10/2021/000037",
	"LMCS/10/2021/000038",
	"LMCS/10/2021/000039",
	"LMCS/10/2021/000046",
	"LMCS/10/2021/000047",
	"LMCS/10/2021/000045",
	"LMCS/10/2021/000051",
	"LMCS/10/2021/000049",
	"LMCS/10/2021/000053",
	"LMCS/10/2021/000052",
	"LMCS/10/2021/000064",
	"LMCS/10/2021/000068",
	"LMCS/11/2021/000079",
	"LMCS/11/2021/000083",
	"LMCS/11/2021/000085",
	"LMCS/12/2021/000088",
	"LMCS/2/2022/000095",
	"LMCS/11/2021/000071",
	"LMCS/3/2022/000099",
];

export default pioneers;
