import React from "react";
export const Testimonials = (props) => {
  return (
    <div id="faqs">
      <div className="fluid-container">
        <div className="section-title text-center">
          <h2>Frequently Asked Questions</h2>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-sm-12 col-md-6">
                  <div className="testimonial" id="accordion">
                    <div className="card card-success card-outline">
                      <a
                        className="d-block w-100"
                        data-toggle="collapse"
                        href={`#collapse${i}`}
                      >
                        <div className="card-header">
                          <h4 className="card-title w-100">
                            {`${i + 1}.    ${d.name}`}
                          </h4>
                        </div>
                      </a>
                      <div
                        id={`collapse${i}`}
                        className="collapse"
                        data-parent="#accordion"
                      >
                        <div className="card-body">{d.text}</div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
