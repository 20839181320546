import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import ContentWrapper from "../utilities/ContentWrapper";
import ViewReferralTable from "./ViewReferralTable";
import { useDispatch, useSelector } from "react-redux";
import { fetchReferrals } from "../../store/actions/adminActions";
import { Button } from "react-bootstrap";

function ReferralTable() {
	const [showDetail, setShowDetail] = React.useState(false);
	const { referrals } = useSelector((state) => state.admin);
	const dispatch = useDispatch();
	const data = [];
	referrals?.referrals?.sort((a, b) => {
		return b.referedUsers?.length - a.referedUsers?.length;
	});

	referrals?.referrals?.forEach((ref, index) => {
		ref.serial = index + 1;
		ref.name = ref.userId?.name;
		ref.referrals = ref.referedUsers?.length;
		data.push(ref);
	});

	React.useEffect(() => {
		dispatch(fetchReferrals());
	}, [dispatch]);

	const columns = [
		{
			dataField: "serial",
			text: "#",
			headerStyle: {
				backgroundColor: "#c8e6c9",
				width: "20px",
			},
		},
		{
			dataField: "name",
			text: "Name",

			sort: true,
			headerStyle: {
				backgroundColor: "#c8e6c9",
				width: "70px",
			},
		},
		{
			dataField: "referrals",
			text: "Number of Referrals",

			sort: true,
			headerStyle: {
				backgroundColor: "#c8e6c9",
				width: "70px",
			},
		},
	];

	const MyExportCSV = (props) => {
		const handleClick = () => {
			props.onExport();
		};
		return (
			<div style={{ textAlign: "right" }}>
				<Button variant='primary' size='lg' onClick={handleClick}>
					Export To CSV
				</Button>
			</div>
		);
	};
	return (
		<ContentWrapper>
			<div className='card'>
				<div className='card-header'>
					<h3 className='card-title'>REFERRAL TABLES</h3>
				</div>
				<div className='col-md-3' style={{ margin: "10px" }}>
					<Button
						variant='success'
						size='lg'
						onClick={() => setShowDetail(!showDetail)}>
						{!showDetail ? "Detailed View" : "Less Details"}
					</Button>
				</div>
				{!showDetail ? (
					<section className='content pb-3'>
						<div className='card-body'>
							<ToolkitProvider
								stripped
								hover
								keyField='_id'
								columns={columns}
								data={data}
								exportCSV>
								{(props) => (
									<div>
										<MyExportCSV {...props.csvProps} />
										<hr />
										<BootstrapTable {...props.baseProps} />
									</div>
								)}
							</ToolkitProvider>
						</div>
					</section>
				) : (
					<ViewReferralTable />
				)}
			</div>
		</ContentWrapper>
	);
}

export default ReferralTable;
